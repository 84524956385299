import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const BootstrapTooltip = styled(({ className, placement, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={placement} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const CardTooltipStyled = styled(({ className, placement, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={placement} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: 10,
  },
}));

export function CardTooltip({ children, title, placement }: TooltipProps) {
  return (
    <div>
      <CardTooltipStyled title={title} placement={placement}>
        {children}
      </CardTooltipStyled>
    </div>
  );
}

export default function MuiTooltip({ children, title, placement, className, ...props }: TooltipProps) {
  return (
    <div className={className + " priority-link"}>
      <BootstrapTooltip title={title} placement={placement} {...props}>
        {children}
      </BootstrapTooltip>
    </div>
  );
}

export function MuiTooltipWithCloseOnHover(props) {
  const [isShowing, setIsShowing] = React.useState(false);

  return (
    <MuiTooltip
      {...props}
      onMouseOver={() => setIsShowing(true)}
      onMouseLeave={() => setIsShowing(false)}
      open={isShowing}
    />
  );
}
