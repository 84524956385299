import React from "react";

import DriversTable from "components/organization/driver/OrganizationDriversTable";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import { ErrorBoundary } from "react-error-boundary";

const OrganizationDriversPage = () => {
  return (
    <React.Fragment>
      <Head title={"Organization Drivers"} />
      <Content>
        <ErrorBoundary FallbackComponent={() => <>An error ocurred..</>}>
          <DriversTable />
        </ErrorBoundary>
      </Content>
    </React.Fragment>
  );
};

export default OrganizationDriversPage;
