import CancelButton from "components/button/CancelButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Modal, ModalBody } from "reactstrap";
import { BlackBodyText100, BlackOverlineText300 } from "../../text/Text";
import DatePicker from "../../date-picker/DatePicker";
import ReactDatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { useDateSchedulePeriodSelector } from "../../../hooks/date-selector.hooks";
import PrimaryButton from "../../button/PrimaryButton";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import { useTeslaExporterAsync } from "../../../hooks/query/organization-query.hooks";
import { DateSchedulePeriodKey } from "../../../types/date.types";
import { CSVLink } from "react-csv";

type Props = {
  readonly isModalOpen: boolean;
  readonly setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function OrganizationTeslaExporterModal({
                                          isModalOpen,
                                          setIsModalOpen
                                        }: Props) {
  const { isLoading, getTeslaChargingListAsyncCallback } = useTeslaExporterAsync();
  const { handleSetDateSchedulePeriod, dateSchedulePeriodSelector } = useDateSchedulePeriodSelector();
  const startDatePickerRef = useRef<ReactDatePicker>(null);
  const endDatePickerRef = useRef<ReactDatePicker>(null);
  const csvLinkRef = useRef<HTMLAnchorElement>(null);
  const [csvBlobUrl, setCsvBlobUrl] = useState<string | null>(null);
  const [filename, setFilename] = useState("export.csv");

  const { startsAt, endsAt } = dateSchedulePeriodSelector;
  const [maxEndDate, setMaxEndDate] = useState<DateTime>(DateTime.now().plus({ days: 45 }));


  useEffect(() => {
    return () => {
      if (csvBlobUrl) {
        URL.revokeObjectURL(csvBlobUrl);
      }
    };
  }, [csvBlobUrl]);

  useEffect(() => {
    if (startsAt) {
      const newMaxEndDate = DateTime.fromJSDate(startsAt).plus({ days: 45 });
      const now = DateTime.now();
      setMaxEndDate(newMaxEndDate > now ? now : newMaxEndDate);
    }
  }, [startsAt]);

  const adjustEndDate = (startsAt?: Date) => {
    if (!startsAt) {
      return;
    }

    const startDate = DateTime.fromJSDate(startsAt);
    const newEndDate = startDate.plus({ days: 10 });
    const now = DateTime.now();
    handleSetDateSchedulePeriod("endsAt", newEndDate > now ? now.toJSDate() : newEndDate.toJSDate());
  };

  const filterEndDate = (time) => {
    const selectedDate = new Date(time);
    return selectedDate.getTime() <= maxEndDate.toJSDate().getTime();
  };

  const handleDateChange = (date: Date, key: DateSchedulePeriodKey) => {
    let newDate: Date | undefined = date;

    const isStartDateControl = key === "startsAt";

    if (isStartDateControl) {
      adjustEndDate(newDate);
    }

    handleSetDateSchedulePeriod(key, newDate);
  };


  const handleDownloadClicked = async () => {
    if (!startsAt || !endsAt) {
      return;
    }

    try {

      const response = await getTeslaChargingListAsyncCallback(startsAt.toISOString(), endsAt.toISOString());

      if (!response) {
        return;
      }
      const blob = new Blob([response], { type: "text/csv" });
      const blobUrl = URL.createObjectURL(blob);
      setCsvBlobUrl(blobUrl);
      setFilename(`export-tesla-charging-${DateTime.now().toFormat('yyyyMMddHHmmss')}.csv`);


      if (csvLinkRef.current) {
        csvLinkRef.current.click();
      }
    } catch (error) {
      console.error("Download error:", error);
    }
  };
  return (
    <>
      <a
        href={csvBlobUrl ?? ""}
        download={filename}
        ref={csvLinkRef}
        className="hidden"
      >
      </a>

      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        className="wide-xs modal-dialog-centered overflow-hidden"
      >
        <ModalBody className="w-md-100 h-md-75 h-lg-50 h-max-100 p-3 max-w-90 overflow-hidden position-relative">
          <div>
            <Col md="12" className="mt-2">
              <BlackBodyText100 className="mb-1">Export sessions from Tesla FleetAPI</BlackBodyText100>
              <BlackOverlineText300 className="mb-2">
                Set the date range for receiving charging sessions. Depending on the selected interval, the loading time can be up to 5 minutes (Maximum time can be 45 days).
              </BlackOverlineText300>

              <FormGroup>
                <Row className="align-items-center">
                  <DatePicker
                    ref={startDatePickerRef}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeSelect
                    timeIntervals={15}
                    withPortal={true}
                    placeholder="Select start date"
                    selectedDate={startsAt ?? new Date()}
                    onDateChange={(date) => handleDateChange(date, "startsAt")}
                  />
                  <ArrowRightIcon className="mx-1 my-2 my-md-0" />
                  <DatePicker
                    ref={endDatePickerRef}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeSelect
                    timeIntervals={15}
                    withPortal={true}
                    placeholder="Select end date (Optional)"
                    selectedDate={endsAt}
                    onDateChange={(date) => handleDateChange(date, "endsAt")}
                    maxDate={maxEndDate.toJSDate()}
                    filterTime={filterEndDate}
                  />
                </Row>
              </FormGroup>
            </Col>

            <Row className="w-100 justify-end align-center mt-4">
              <CancelButton onClick={() => setIsModalOpen(false)} />
              <PrimaryButton
                type={"button"}
                className={"ml-2 w-min-100px"}
                onClick={handleDownloadClicked}
                text={"Download"}
                isDisabled={isLoading}
                isLoading={isLoading}
              />
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </>

  );
}

export default OrganizationTeslaExporterModal;
