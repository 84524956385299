import TickIcon from "assets/icons/TickIcon";
import Row from "components/custom/Row";
import { Body2Text } from "components/text/Text";

type Props = {
  readonly isSelected?: boolean;
  readonly text: string;
  readonly onClick: (e: any) => void;
  readonly isSeparatedFromTop?: boolean;
  readonly isSeparatedFromBottom?: boolean;
};

function FilterSelectorCardItem({ isSelected, text, onClick, isSeparatedFromTop, isSeparatedFromBottom }: Props) {
  return (
    <>
      {isSeparatedFromTop && <div className="horizontal-border-line my-1 w-100" />}

      <Row onClick={onClick} className="align-items-center filter-selector-option justify-between w-100 ">
        <Body2Text className={`${isSelected ? "text-purple-200" : "text-black-100"}`}>{text}</Body2Text>
        {isSelected && <TickIcon fill="#680031" />}
      </Row>

      {isSeparatedFromBottom && <div className="horizontal-border-line my-1 w-100" />}
    </>
  );
}

export default FilterSelectorCardItem;
