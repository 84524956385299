import ButtonWithIcon from "./ButtonWithIcon";

type Props = {
  readonly onClick?: () => void;
  readonly text?: string;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

function PrimaryButton(props: Props) {
  return <ButtonWithIcon {...props} />;
}

export default PrimaryButton;
