
export default function XIcon(props) {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" {...props}>
      <path
        d="M14.7176 0.772833C14.6156 0.670653 14.4945 0.589588 14.3611 0.534278C14.2278 0.478967 14.0849 0.450497 13.9405 0.450497C13.7962 0.450497 13.6532 0.478967 13.5199 0.534278C13.3865 0.589588 13.2654 0.670653 13.1634 0.772833L7.77363 6.15163L2.3838 0.76181C2.28176 0.659765 2.16061 0.578819 2.02728 0.523592C1.89396 0.468366 1.75106 0.439941 1.60674 0.439941C1.46243 0.439941 1.31953 0.468366 1.1862 0.523592C1.05287 0.578819 0.931726 0.659765 0.829681 0.76181C0.727636 0.863855 0.64669 0.985 0.591463 1.11833C0.536237 1.25166 0.507812 1.39456 0.507812 1.53887C0.507813 1.68318 0.536237 1.82608 0.591463 1.95941C0.64669 2.09274 0.727636 2.21389 0.829681 2.31593L6.21951 7.70576L0.829681 13.0956C0.727636 13.1976 0.64669 13.3188 0.591463 13.4521C0.536237 13.5854 0.507812 13.7283 0.507812 13.8726C0.507812 14.017 0.536237 14.1599 0.591463 14.2932C0.64669 14.4265 0.727636 14.5477 0.829681 14.6497C0.931726 14.7517 1.05287 14.8327 1.1862 14.8879C1.31953 14.9431 1.46243 14.9716 1.60674 14.9716C1.75106 14.9716 1.89396 14.9431 2.02728 14.8879C2.16061 14.8327 2.28176 14.7517 2.3838 14.6497L7.77363 9.25988L13.1634 14.6497C13.2655 14.7517 13.3866 14.8327 13.52 14.8879C13.6533 14.9431 13.7962 14.9716 13.9405 14.9716C14.0848 14.9716 14.2277 14.9431 14.3611 14.8879C14.4944 14.8327 14.6155 14.7517 14.7176 14.6497C14.8196 14.5477 14.9006 14.4265 14.9558 14.2932C15.011 14.1599 15.0394 14.017 15.0394 13.8726C15.0394 13.7283 15.011 13.5854 14.9558 13.4521C14.9006 13.3188 14.8196 13.1976 14.7176 13.0956L9.32775 7.70576L14.7176 2.31593C15.1364 1.89709 15.1364 1.19167 14.7176 0.772833Z"
        fill={props.fill??'black'}
      />
    </svg>
  );
}
