import ExportIcon from "assets/icons/ExportIcon";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

import TextButton from "./TextButton";

type ListItem = {
  [key: string]: any;
};

type Props = {
  data: ListItem[];
  fileName: string;
};

function CsvLinkButton({ data, fileName }: Props) {
  const { t } = useTranslation("common");
  return (
    <CSVLink data={data ?? []} filename={`${new Date().getTime()}-${fileName}.csv`}>
      <TextButton className="hover-background-white p-1" icon={<ExportIcon />} text={t("general.export") ?? ""} />
    </CSVLink>
  );
}

export default CsvLinkButton;
