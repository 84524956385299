import DetailsCardBlock from "components/card/DetailsCard";
import DetailsCard, { DetailsCardBlockProps } from "components/card/DetailsCard";
import React from "react";

type Props = DetailsCardBlockProps;

function OrganizationDetailsCardBlock(props: Props) {
  return <DetailsCardBlock {...props} />;
}

export default OrganizationDetailsCardBlock;
