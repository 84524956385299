import { DateTime } from "luxon";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getVehicleDriverScheduleAsync } from "services/vehicle-driver-schedule.services";
import { VehicleDriverSchedule } from "types/vehicle-driver-schedule.types";
import { PaginationMeta } from "../../types/api.types";

export const useVehicleDriverSchedulesAsync = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [schedules, setSchedules] = useState<VehicleDriverSchedule[]>([]);

  const { vehicleId, driverId, organizationId } = useParams<{
    vehicleId?: string,
    driverId?: string,
    organizationId?: string
  }>();

  const getVehicleDriverSchedulesAsyncCallback = useCallback(
    async () => {
      if (!organizationId) {
        return;
      }
      try {
        const query = qs.stringify({
          filters: {
            userId: {
              $eq: driverId
            },
            vehicleId: {
              $eq: vehicleId
            },
            endsAt: {
              $gt: DateTime.now().plus({ seconds: 10 }).toJSDate()
            }
          },
          pagination: {
            size: 20,
            page: 0
          }
        }, {
          skipNulls: true,
          addQueryPrefix: true
        });
        setIsLoading(true);
        const res = await getVehicleDriverScheduleAsync(organizationId, query);

        setSchedules(res.data ?? []);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [driverId, organizationId, vehicleId]
  );


  useEffect(() => {
    getVehicleDriverSchedulesAsyncCallback();
  }, [getVehicleDriverSchedulesAsyncCallback]);


  return { isLoading, schedules, refetch: getVehicleDriverSchedulesAsyncCallback };
};


export const useOldVehicleDriverSchedulesWithPaginationAsync = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [schedules, setSchedules] = useState<VehicleDriverSchedule[]>([]);

  const [meta, setMeta] = useState<PaginationMeta | undefined>();

  const { vehicleId, driverId, organizationId } = useParams<{
    vehicleId?: string,
    driverId?: string,
    organizationId?: string
  }>();

  const getVehicleDriverSchedulesAsyncCallback = useCallback(
    async () => {
      if (!organizationId) {
        return;
      }
      try {
        const query = qs.stringify({
          filters: {
            userId: {
              $eq: driverId
            },
            vehicleId: {
              $eq: vehicleId
            },
            endsAt: {
              $lt: DateTime.now().minus({ seconds: 10 }).toJSDate()
            }
          },
          pagination: {
            size: 3,
            page: currentPage
          }
        }, {
          skipNulls: true,
          addQueryPrefix: true
        });
        setIsLoading(true);
        const res = await getVehicleDriverScheduleAsync(organizationId, query);

        setMeta((res.meta));
        setSchedules(res.data ?? []);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [organizationId, driverId, vehicleId, currentPage]
  );


  useEffect(() => {
    getVehicleDriverSchedulesAsyncCallback();
  }, [getVehicleDriverSchedulesAsyncCallback]);


  return { isLoading, schedules, refetch: getVehicleDriverSchedulesAsyncCallback, meta, currentPage, setCurrentPage };
};