import React from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";
import { useTranslation } from "react-i18next";

import Icon from "../../../../components/icon/Icon";
import { ExternalLinkItem, LinkList } from "../../../../components/links/Links";
import data from "./NotificationData";

const NotificationItem = (props) => {
  const { icon, iconStyle, text, time, id } = props;
  return (
    <div className="nk-notification-item" key={crypto.randomUUID()} id={id}>
      <div className="nk-notification-icon">
        <Icon name={icon} className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]} />
      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">{text}</div>
        <div className="nk-notification-time">{time}</div>
      </div>
    </div>
  );
};

const Notification = () => {
  const { t } = useTranslation("common");

  return (
    <ul className="link-list" style={{ padding: 0 }}>
      <ExternalLinkItem tag="a" link="https://vkozfz5086v.typeform.com/to/xQSdY7q3?" /* icon="comments" */>
        {/*         <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '1px' }}>{t('user_menu.send_feedback')}</span>
          <Icon name="external-alt"></Icon>
        </div> */}
      </ExternalLinkItem>
    </ul>

  );
};

export default Notification;
