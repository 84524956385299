import React from "react";

import Head from "layout/head/Head";
import Content from "layout/content/Content";
import OrganizationDriverDetailCards from "components/organization/driver/OrganizationDriverDetailCards";
import OrganizationDriverChargingListTable from "components/organization/driver/OrganizationDriverChargingListTable";
import DriverPageTab from "../../../components/driver/DriverPageTab";
import DriverDetailsAssignmentsContent from "../../../components/driver/DriverDetailsAssignmentsContent";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const OrganizationDriverDetailsPage = () => {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Head title={"Drivers"} />

      <Content>
        <DriverPageTab />

        <Switch>


          <Route exact path={`${path}/assignments`}
                 render={() =>
                   <DriverDetailsAssignmentsContent />
                 }
          />

          <Route path={`${path}`}
                 render={() => <>   <OrganizationDriverDetailCards />
                   <OrganizationDriverChargingListTable /> </>}
          />
        </Switch>
      </Content>
    </React.Fragment>
  );
};

export default OrganizationDriverDetailsPage;
