import React from "react";

import Head from "layout/head/Head";
import Content from "layout/content/Content";
import OrganizationVehicleDetailsCards from "components/organization/vehicle/OrganizationVehicleDetailsCards";
import OrganizationVehicleChargingListTable from "components/organization/vehicle/OrganizationVehicleChargingListTable";
import VehiclePageTab from "../../../components/vehicle/VehiclesPageTab";
import VehicleDetailsAssignmentsContent from "../../../components/vehicle/VehicleDetailsAssignmentsContent";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const OrganizationVehicleDetailsPage = () => {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Head title={"Vehicle Details"} />

      <Content>
        <VehiclePageTab />

        <Switch>


          <Route exact path={`${path}/assignments`}
                 render={() =>
                   <VehicleDetailsAssignmentsContent
                   />
                 }
          />

          <Route path={`${path}`}
                 render={() => <><OrganizationVehicleDetailsCards />
                   <OrganizationVehicleChargingListTable /></>
                 }
          />
        </Switch>
      </Content>
    </React.Fragment>
  );
};

export default OrganizationVehicleDetailsPage;
