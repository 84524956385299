import { ClickAwayListener, Popper, PopperPlacementType } from "@mui/material";
import BlackCircleBadge from "components/badge/BlackCircleBadge";
import Card from "components/card/Card";
import Row from "components/custom/Row";
import { BlackBody2Text200 } from "components/text/Text";

import SelectorChevronIconBottom from "assets/icons/SelectorChevronIconBottom";
import React, { useCallback } from "react";
import FilterMenuCard, { FilterMenuCardProps } from "./FilterMenuCard";

type Props = FilterMenuCardProps & {
  readonly label?: string;
  readonly variationCount?: number;
  readonly overrideItem?: React.JSX.Element;
  readonly placement?: PopperPlacementType;
  readonly containerClassName?: string;
};

function FilterEditorCard({ variationCount, label, overrideItem, placement, containerClassName, ...props }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleActionButtonsPressed = useCallback((actionCallback: () => void) => {
    setAnchorEl(null);
    actionCallback();
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Card padding="p-0" className={`align-items-center filter-editor-card ${containerClassName ?? "m-1 md-0 "}`}>
          {overrideItem ? (
            <div className="w-100" onClick={handleClick}>
              {overrideItem}
            </div>
          ) : (
            <Row onClick={handleClick} className="clickable filter-editor-input  align-items-center h-42px pl-3 pr-1">
              {label && <BlackBody2Text200 className="mr-1">{label}</BlackBody2Text200>}
              {!!(variationCount && variationCount > 0) && <BlackCircleBadge text={variationCount} />}
              <SelectorChevronIconBottom />
            </Row>
          )}

          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement={placement ?? "bottom-start"}
            style={{ zIndex: 99999 }}
          >
            <FilterMenuCard
              {...props}
              onApplyPressed={() => handleActionButtonsPressed(props.onApplyPressed)}
              onClearPressed={() => handleActionButtonsPressed(props.onClearPressed)}
            />
          </Popper>
        </Card>
      </div>
    </ClickAwayListener>
  );
}

export default FilterEditorCard;
