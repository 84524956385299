import CheckboxCheckedIcon from "assets/icons/CheckboxCheckedIcon";

type Props = {
  readonly isChecked?: boolean;
};

function Checkbox({ isChecked }: Props) {
  if (isChecked) {
    return <CheckboxCheckedIcon />;
  }

  return <div className="checkbox-unchecked" />;
}

export default Checkbox;
