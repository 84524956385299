import React from "react";




import { useHistory, useParams } from "react-router";




import { getFormattedPhoneNumber } from "../../utils/format.utils";
import ArrowUpRightIcon from "../../assets/icons/ArrowUpRightIcon";
import { getCategorizedSchedules } from "../../utils/vehicle-driver-schedule.utils";
import CurrentDriverAssignmentIcon from "../../assets/icons/CurrentDriverAssignmentIcon";
import LoadingSkeleton from "../loading/LoadingSkeleton";
import RowBetweenBlock from "../block/RowBetweenBlock";
import { VehicleDriverSchedulesDataAccessorHook } from "../../types/vehicle.types";
import { BlackBodyText100, BlackLinkText100, LinkTextPrimary } from "../text/Text";
import { getDriverFullName, getDriverPaymentResponsibilityLabelByPayerType } from "../../utils/driver.utils";

type Props = {
  vehicleDriverSchedulesHook: VehicleDriverSchedulesDataAccessorHook
}

function VehicleDetailsDriverAssignment({ vehicleDriverSchedulesHook }: Props) {
  const { isLoading: isSchedulesLoading, schedules } = vehicleDriverSchedulesHook;

  const {organizationId} = useParams<{organizationId:string}>()


  const history = useHistory();

  const { activeSchedules} = getCategorizedSchedules(schedules)
  const isDriverAssigned = activeSchedules && activeSchedules.length > 0;


  if (isSchedulesLoading) {
    return <LoadingSkeleton count={4} />;
  }

  if (!isDriverAssigned) {
    return (
      <div className={"h-250px center"}>
        <BlackBodyText100 className="my-4">No assignment yet.</BlackBodyText100>
      </div>
    );
  }


  const firstSchedule = activeSchedules[0];
  const driver = firstSchedule.user;
  const driverEmail = driver.email;
  const phoneNumber = getFormattedPhoneNumber(driver?.phoneNumber);
  return (
    <div>
      <CurrentDriverAssignmentIcon />
      <RowBetweenBlock
        className={"mt-2"}
        leftText={"Driver"}
        rightOverwrite={<BlackLinkText100
          onClick={() => history.push(`/organizations/${organizationId}/drivers/${driver.id}`)}>
          {getDriverFullName(driver)}</BlackLinkText100>}
      />
      <RowBetweenBlock
        leftText={"Phone number"}
        rightText={phoneNumber}
      />
      <RowBetweenBlock
        leftText={"Email"}
        rightOverwrite={<LinkTextPrimary
          onClick={() => window.open("mailto:" + driverEmail)}
          className="d-flex align-items-center clickable"
        >
          <ArrowUpRightIcon />
          {driverEmail}
        </LinkTextPrimary>}
      />

      <RowBetweenBlock
        leftText={"Payment resp."}
        rightText={getDriverPaymentResponsibilityLabelByPayerType(driver?.payer)}
      />

    </div>
  );


}

export default VehicleDetailsDriverAssignment;