import XIcon from "assets/icons/XIcon";
import { BlackBody2Text200 } from "components/text/Text";
import TextButton from "./TextButton";

type Props = {
  onClick: () => void;
};

function ClearFiltersButton(props: Props) {
  return (
    <TextButton
      onClick={props.onClick}
      className="hover-background-white align-items-center p-1 h-42px ml-2"
      icon={<XIcon fill="#6f7981" width={12} height={12} style={{ paddingBottom: 2 }} />}
      textOverride={<BlackBody2Text200>Clear filters</BlackBody2Text200>}
    />
  );
}

export default ClearFiltersButton;
