import Row from "../custom/Row";
import BaseButton from "./BaseButton";

export default function ButtonWithIcon(props) {
  const { leftIcon, rightIcon, text } = props;


  return (
    <BaseButton
      {...props}
      textOverride={
        <Row className="center">
          {leftIcon && leftIcon}
          <span className="font-weight-normal text-white fs-17px title">{text}</span>
          {rightIcon && rightIcon}
        </Row>
      }
    />
  );
}
