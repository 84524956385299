import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { PaginatedApiResponse, PaginationMeta } from "types/api.types";

export function useListByUrlQueryAsync<T>(fetcherAsync: (query?: string) => Promise<PaginatedApiResponse<T>>) {
    const [list, setList] = useState<Array<T>>([])
    const [meta, setMeta] = useState<PaginationMeta | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(true);

    const { search } = useLocation();




    const getListAsyncCallback = useCallback(
        async (queryString: string) => {
            try {

                setIsLoading(true);
                const res = await fetcherAsync(queryString)
                setList(res.data)
                setMeta(res.meta)

            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        },
        [fetcherAsync],
    )


    useEffect(() => {
        getListAsyncCallback(search);
    }, [getListAsyncCallback, search]);


    return { list, meta, isLoading }
}
