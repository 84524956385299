import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { BlackH5Text0 } from "../text/Text";
import CancelButton from "components/button/CancelButton";
import BlackButton from "components/button/BlackButton";

export default function AlertConfirmDialog({
  isLoading,
  isDialogOpen,
  toggleIsDialogOpen,
  onDialogConfirmed,
  description,
  descriptionOverride,
  title,
  overrideButtons,
  icon,
}: {
  isLoading?: boolean;
  isDialogOpen: boolean;
  toggleIsDialogOpen?: () => void;
  onDialogConfirmed?: () => void;
  description?: string;
  descriptionOverride?: JSX.Element;
  title: string;
  overrideButtons?: JSX.Element;
  icon?: JSX.Element;
}) {
  return (
    <Dialog
      open={isDialogOpen}
      onClose={toggleIsDialogOpen}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: " p-1 rounded-xxl " }}
    >
      {icon && <DialogContent className="center">{icon}</DialogContent>}
      <DialogTitle id="responsive-dialog-title">
        <BlackH5Text0>{title}</BlackH5Text0>
      </DialogTitle>
      <DialogContent>{descriptionOverride ?? <DialogContentText>{description}</DialogContentText>}</DialogContent>
      <DialogActions className="justify-content-end">
        {overrideButtons ?? (
          <>
            <CancelButton onClick={toggleIsDialogOpen} />
            {onDialogConfirmed && <BlackButton isLoading={isLoading} text="Yes" onClick={onDialogConfirmed} />}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
