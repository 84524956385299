import { FormGroup } from "reactstrap";
import { AudienceType } from "types/invoice.types";

type Props = {
  readonly onChange: (type: AudienceType) => void;
  readonly audienceType: AudienceType;
  readonly selectedAudience: AudienceType;
  readonly label: string;
};

function AddManuelInvoiceFormAudienceTypeSelector({ onChange, selectedAudience, audienceType, label }: Props) {
  return (
    <FormGroup
      name="audienceType"
      className="custom-control custom-radio form-control  d-flex align-items-center  mt-2"
      htmlFor={AudienceType}
    >
      <input
        type="radio"
        className="custom-control-input h-100"
        name="audienceType"
        id={audienceType}
        onChange={() => onChange(audienceType)}
        value={audienceType}
        checked={selectedAudience === audienceType}
      />
      <label className="custom-control-label w-100 " htmlFor={audienceType}>
        {label}
      </label>
    </FormGroup>
  );
}

export default AddManuelInvoiceFormAudienceTypeSelector;
