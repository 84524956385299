import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/admin/organization/dashboard/users'
 */
export async function getDashboardData() {

    const endpoint = generateApiEndpoint("board/dashboard");
    const response = await restApiClient.get(endpoint);

    return response.data;
}

