import React from "react";

type Props = {};

function AlertSuccessIcon({}: Props) {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.7071 97.3391C75.4024 97.3391 97.0432 75.6983 97.0432 49.003C97.0432 22.3078 75.4024 0.666992 48.7071 0.666992C22.0119 0.666992 0.371094 22.3078 0.371094 49.003C0.371094 75.6983 22.0119 97.3391 48.7071 97.3391ZM35.63 63.4156C35.6009 63.3866 35.5742 63.3562 35.5496 63.3246L22.9764 50.7513C22.0325 49.8075 22.0325 48.2773 22.9764 47.3335L29.8121 40.4977C30.7559 39.5539 32.2862 39.5539 33.23 40.4977L45.2053 52.4731L67.3757 30.3028C68.3195 29.3589 69.8497 29.3589 70.7936 30.3028L77.6293 37.1385C78.5731 38.0823 78.5731 39.6126 77.6293 40.5564L46.909 71.2767C45.9651 72.2205 44.4349 72.2205 43.4911 71.2767L35.63 63.4156Z"
        fill="#23D206"
      />
    </svg>
  );
}

export default AlertSuccessIcon;
