import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/organization'
 */
export async function getProfileInfo() {
  const endpoint = generateApiEndpoint("organization");
  const response = await restApiClient.get(endpoint);

  return response.data;
}

/**
 * POST: '/api/organization'
 */
export async function updateProfileInfo(data) {
  const endpoint = generateApiEndpoint("organization");
  const response = await restApiClient.post(endpoint, data);

  return response.data;
}
