import BlackButton from "components/button/BlackButton";
import CsvLinkButton from "components/button/CsvLinkButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import PageHeader from "components/header/PageHeader";

import LoadingSkeleton from "components/loading/LoadingSkeleton";
import {
  BlackBodyText100,
  BlackBodyText200,
  BlackH5Text100,
  BlackH6Text0,
  BlackSubtitleText0
} from "components/text/Text";


import Content from "layout/content/Content";
import Head from "layout/head/Head";
import { ChargingInvoiceLineItem, PlatformInvoiceLineItem } from "types/invoice.types";
import { getLocalFormattedDateAsDateShort } from "utils/date.utils";
import { getFormattedNumberForUsdByCent } from "utils/format.utils";
import ChargingInvoiceDetailsTable from "./tables/ChargingInvoiceDetailsTable";
import PlatformInvoiceDetailsTable from "./tables/PlatformInvoiceDetailsTable";
import { useOrganizationInvoiceAsync } from "../../hooks/query/organization-query.hooks";
import { useInvoiceDetailsByType } from "../../hooks/invoice.hooks";
import PaidStatusBadge from "../../components/badge/PaidStatusBadge";
import ClipboardWithCopyIcon from "../../components/clipboard/ClipboardWithCopyIcon";

type Props = {};

function OrganizationInvoiceDetailsPage({}: Props) {
  const { invoice, isLoading } = useOrganizationInvoiceAsync();
  const {
    exportData,
    handleForwardToStripe,
    isPaid,
    percentFixed,
    description,
    title,
    invoicePaymentState,
    percent,
    isChargingInvoice
  } = useInvoiceDetailsByType(invoice);

  return (
    <>
      <Head title={"Invoice Details"} />
      <Content>
        <PageHeader
          titleOverwrite={isLoading ? <LoadingSkeleton count={1} className={"w-48px"} /> :
            <Row className={'align-items-center'}>
              <BlackH5Text100 className={'mr-3'}>{title}</BlackH5Text100>
              <ClipboardWithCopyIcon TextComponent={BlackSubtitleText0} text={invoice?.id} />
            </Row>}
          rightElement={
            isLoading ? (
              <div className="w-200px">
                <LoadingSkeleton count={1} />
              </div>
            ) : (
              <Row className="align-items-end">
                <BlackBodyText100 className="mr-2">Total</BlackBodyText100>
                <BlackH5Text100>{getFormattedNumberForUsdByCent(invoice?.netAmount)}</BlackH5Text100>
              </Row>
            )
          }
        />

        {isLoading ? (
          <LoadingSkeleton count={4} />
        ) : (
          <>
            {" "}
            <Row className="justify-between">
              <Row className="align-items-center">
                <BlackBodyText100 className="mr-2">
                  {getLocalFormattedDateAsDateShort(invoice?.createdAt)}
                </BlackBodyText100>
                <PaidStatusBadge status={invoicePaymentState} />
              </Row>
              <BlackButton
                className="mt-3 mt-lg-0"
                text={"See invoice"}
                onClick={handleForwardToStripe}
              />
            </Row>

            <Row className="justify-between my-2 mt-4 align-items-center">
              <Col>
                <BlackH6Text0>Statement</BlackH6Text0>
                <BlackBodyText200>{description}</BlackBodyText200>
              </Col>

              {exportData && <CsvLinkButton data={exportData} fileName="invoice-statement" />}
            </Row>
          </>
        )}

        {isChargingInvoice ? (
          <ChargingInvoiceDetailsTable
            isLoading={isLoading}
            lineItems={(invoice?.lineItems as ChargingInvoiceLineItem[]) ?? []}
          />
        ) : (
          <PlatformInvoiceDetailsTable
            isLoading={isLoading}
            lineItems={(invoice?.lineItems as PlatformInvoiceLineItem[]) ?? []}
          />
        )}
      </Content>
    </>
  );
}

export default OrganizationInvoiceDetailsPage;
