
import OldVehicleDriverAssignmentsModalContent from "./OldVehicleDriverAssignmentsModalContent";
import { useOldVehicleDriverSchedulesWithPaginationAsync } from "../../hooks/query/vehicle-driver-schedule-query.hooks";
import { useState } from "react";
import LoadingSkeleton from "../loading/LoadingSkeleton";
import { BlackLinkText200 } from "../text/Text";
import BaseModal from "../modal/BaseModal";


type Props = {
  isForDriver?:boolean
}
function OldVehicleDriverAssignmentsModal({isForDriver}:Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const oldSchedulesHook = useOldVehicleDriverSchedulesWithPaginationAsync();
  const { isLoading, schedules } = oldSchedulesHook;

  const areThereAnyOldSchedule = (schedules && schedules?.length > 0);


  return (
    <div className={"mt-4"}>
      {
        areThereAnyOldSchedule &&
        <BaseModal
          className="wide-sm"
          isCloseIconVisible
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        >
          <OldVehicleDriverAssignmentsModalContent isForDriver={isForDriver} oldSchedulesHook={oldSchedulesHook} />
        </BaseModal>
      }


      {isLoading ?
        <LoadingSkeleton count={1} height={20} />
        :
        areThereAnyOldSchedule &&
        <BlackLinkText200
          onClick={() => setIsModalOpen(true)}>
          View previous assignments
        </BlackLinkText200>
      }
    </div>

  );
}

export default OldVehicleDriverAssignmentsModal;