import React from "react";
import LinkButton from "./LinkButton";
import { TextButtonProps } from "./TextButton";

type Props = TextButtonProps;

function OrangeLinkButton(props: Props) {
  return <LinkButton {...props} textClassName="text-orange-200" />;
}

export default OrangeLinkButton;
