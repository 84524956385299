import { Authenticator, } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from "aws-amplify";
import AlertSnackProvider from "components/alert/AlertSnackProvider";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from "react-router-dom";
import { IntercomProvider } from 'react-use-intercom';
import { PersistGate } from 'redux-persist/integration/react';
import App from "./App";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import './i18n';
import { persistor, store } from './redux/store';

const Error404Modern = lazy(() => import("./pages/error/404-modern"));

Amplify.configure({
  Auth: {
    // (required)- Amazon Cognito Region
    region: 'us-east-1',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: "us-east-1_XOOyOBlh8" /* process.env.REACT_APP_USER_POOL_ID */,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: "53seu12b8m3rhnq2kiigd6cbpq" /* process.env.REACT_APP_USER_POOL_WEBCLIENT_ID */,

  }
});

const INTERCOM_APP_ID = 'xkpt6gd5';

ReactDOM.render(
  <React.Fragment>
    <Suspense fallback={<div />}>
      <Router basename={`/`}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Authenticator.Provider>
              <IntercomProvider appId={INTERCOM_APP_ID}>
                <AlertSnackProvider/>
                <Route render={({ location }) => (location.state && location.state.is404 ? <Error404Modern /> : <App />)} />
              </IntercomProvider>
            </Authenticator.Provider>
          </PersistGate>
        </Provider>
      </Router>
    </Suspense>
  </React.Fragment>,
  document.getElementById("root")
);

