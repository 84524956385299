import React from "react";

import Row from "../../custom/Row";

import { styled, Switch } from "@mui/material";
import { BodyText } from "components/text/Text";

const IOSSwitchStyled = styled((props: { checked: boolean; onChange: () => void }) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#23D206",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function IosSwitch({
  isReadOnly,
  isChecked,
  onSwitchStatusChanged,
}: {
  isReadOnly?: boolean;
  isChecked: boolean;
  onSwitchStatusChanged: () => void;
}) {
  return (
    <div className="form-box ">
      <Row className="justify-between">
        <BodyText className={`mr-1 ${isChecked ? "text-green-200" : "text-danger"}`}>
          {isChecked ? "Active" : "Inactive"}
        </BodyText>

        {!isReadOnly && <IOSSwitchStyled checked={isChecked} onChange={onSwitchStatusChanged} />}
      </Row>
    </div>
  );
}
