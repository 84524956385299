import React from "react";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router";

import Content from "layout/content/Content";
import Head from "layout/head/Head";

import Col from "components/custom/Col";
import Row from "components/custom/Row";

import OrganizationChargingListTable from "components/organization/OrganizationChargingListTable";
import OrganizationDetailsFeeSettingsCard from "components/organization/OrganizationDetailsFeeSettingsCard";
import OrganizationDetailsLimitCard from "components/organization/OrganizationDetailsLimitCard";
import OrganizationDetailsPersonalInformationCard from "components/organization/OrganizationDetailsPersonalInformationCard";
import { useOrganizationAsync } from "hooks/query/organization-query.hooks";

const OrganizationDetailsPage = () => {
  const history = useHistory();

  const { isLoading, organization, refetch: refetchOrganizationDetails } = useOrganizationAsync();

  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <Head title={"Organization Details"} />
      <Content>
        <Row>
          <Col lg="6" className="pr-lg-2 mt-4 mt-lg-0">
            <OrganizationDetailsPersonalInformationCard
              refetchOrganizationDetails={refetchOrganizationDetails}
              organizationDetails={organization}
              isLoading={isLoading}
            />
          </Col>
          <Col lg="6" className="pl-lg-2 mt-4 mt-lg-0">
            <OrganizationDetailsLimitCard
              refetchOrganizationDetails={refetchOrganizationDetails}
              organizationDetails={organization}
              isLoading={isLoading}
            />

            <div className="mt-4">
              <OrganizationDetailsFeeSettingsCard
                refetchOrganizationDetails={refetchOrganizationDetails}
                organizationDetails={organization}
                isLoading={isLoading}
              />
            </div>
          </Col>
        </Row>

        <OrganizationChargingListTable />
      </Content>
    </React.Fragment>
  );
};

export default OrganizationDetailsPage;
