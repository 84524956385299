import React from "react";
import { Pagination } from "@mui/material";


import { useHistory, useParams } from "react-router";
import { OldVehicleDriverSchedulesDataAccessorHook } from "../../types/vehicle-driver-schedule.types";
import { BlackBody2Text100, BlackH5Text100, BlackLinkText100 } from "../text/Text";
import LoadingSkeleton from "../loading/LoadingSkeleton";
import { getVehicleDriverSchedulePeriodInfo } from "../../utils/vehicle-driver-schedule.utils";
import RowBetweenBlock from "../block/RowBetweenBlock";
import Card from "../card/Card";
import { getDriverFullName } from "../../utils/driver.utils";
import VehicleDriverSchedulePeriodBadge from "../badge/VehicleDriverSchedulePeriodBadge";
import Row from "../custom/Row";


type Props = {
  oldSchedulesHook: OldVehicleDriverSchedulesDataAccessorHook
  isForDriver?: boolean
}

function OldVehicleDriverAssignmentsModalContent({ oldSchedulesHook, isForDriver }: Props) {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { schedules, meta, setCurrentPage, currentPage, isLoading } = oldSchedulesHook;
  const totalPages = meta?.pagination.totalPages;


  const history = useHistory();

  return (
    <div className={"p-4"}>
      <BlackH5Text100 className={"mb-1"}>Previous assignments</BlackH5Text100>
      <BlackBody2Text100 className={"mb-2"}>On this page, you can review previous assignments. You will also find
        comprehensive details on {isForDriver ? "vehicles" : "drivers"} and their hours. Once assignments are completed,
        current assignments will be
        updated and displayed here.
      </BlackBody2Text100>

      {isLoading ? (<LoadingSkeleton count={3} height={110} className={"my-4 "} />) :
        (schedules?.map(schedule => {
              const {
                timeDifference,
                color,
                formattedStartDateDisplayText,
                formattedEndDateDisplayText
              } = getVehicleDriverSchedulePeriodInfo(schedule.startsAt, schedule.endsAt);


              return (
                <Card className={"bg-secondary mt-2"}>
                  <RowBetweenBlock
                    leftOverwrite={
                      isForDriver ?
                        <BlackLinkText100
                          onClick={() => history.push(`/organizations/${organizationId}/vehicles/${schedule.vehicleId}`)}>
                          {schedule.vehicle.name}
                        </BlackLinkText100> : <BlackLinkText100
                          onClick={() => history.push(`/organizations/${organizationId}/drivers/${schedule.userId}`)}>
                          {getDriverFullName(schedule.user)}
                        </BlackLinkText100>
                    }
                    rightOverwrite={
                      <VehicleDriverSchedulePeriodBadge color={color} timeDifference={timeDifference} />
                    }
                  />


                  <RowBetweenBlock
                    className="mt-2"
                    leftText={"Assignment dates"}
                    rightOverwrite={
                      <Row className="align-items-center">
                        {formattedStartDateDisplayText} - {formattedEndDateDisplayText}
                      </Row>
                    }
                  />


                </Card>
              );

            }
          )
        )}
      <div className={"w-100 p-2 center mt-4"}>
        <Pagination
          onChange={(_, value) => setCurrentPage(value - 1)} defaultValue={currentPage + 1}
          count={totalPages ?? 1} color="primary" />
      </div>

    </div>

  );
}

export default OldVehicleDriverAssignmentsModalContent;