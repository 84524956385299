import React from "react";
import { SvgProps } from "types/svg.types";

const DashboardTablePlaceholderIcon = ({ fill, width, height, onClick }: SvgProps) => {
  return (
    <svg
      className="h-100px"
      width="126"
      height="106"
      viewBox="0 0 126 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2091_402)">
        <ellipse cx="63.1882" cy="86.3852" rx="56.0124" ry="4.33245" fill="#D9D9D9" />
      </g>
      <rect x="17.2852" y="1.18945" width="93.4572" height="60.6543" fill="#CBD5E1" />
      <path d="M124.355 35.8491L110.738 1.18945V45.1329L124.355 69.2708V35.8491Z" fill="#A4B1C5" />
      <path d="M17.2825 0.570801L1.5 36.4683L17.2825 47.9183V0.570801Z" fill="#A4B1C5" />
      <path
        d="M1.91 36.7445H124.09V92.0945C124.09 94.1324 122.438 95.7845 120.4 95.7845H5.6C3.56207 95.7845 1.91 94.1324 1.91 92.0945V36.7445Z"
        fill="white"
        stroke="#CBD5E1"
        stroke-width="0.82"
      />
      <rect x="46.0391" y="69.4707" width="10" height="14" fill="#680031" />
      <rect x="58.0391" y="57.2305" width="10" height="26.24" fill="#680031" />
      <rect x="70.0391" y="62.9707" width="10" height="20.5" fill="#680031" />
      <path d="M34.5 44.9971L30 36.9971H100.5L94 44.9971H34.5Z" fill="#CBD5E1" />
      <defs>
        <filter
          id="filter0_d_2091_402"
          x="0.615781"
          y="82.0527"
          width="125.143"
          height="23.425"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.2" />
          <feGaussianBlur stdDeviation="3.28" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2091_402" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2091_402" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
export default DashboardTablePlaceholderIcon;
