import FilterEditorCard from "components/filter/FilterEditorCard";
import FilterMenuCheckbox from "components/filter/FilterMenuCheckbox";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ChargingFilters, ChargingNetwork } from "types/charging.types";
import {
  getChargerInfo,
  getChargerNetworks,
  mapChargingNetworksForUrlQuery,
  mapChargingNetworksOnQueryToEnum,
} from "utils/charging.utils";

type Props = {};

function FilterNetworkSelector(props: Props) {
  const { filterQuery, setFilterQuery } = useFilterUrlQuery<ChargingFilters>();

  const networksOnQuery = useMemo(
    () => mapChargingNetworksOnQueryToEnum(filterQuery?.network?.$in ?? []),
    [filterQuery?.network?.$in]
  );
  const [selectedNetworks, setSelectedNetworks] = useState<ChargingNetwork[]>(networksOnQuery);
  const [variationCount, setVariationCount] = useState(0);

  const networkOptions = getChargerNetworks();

  useEffect(() => {
    setSelectedNetworks(networksOnQuery);
    setVariationCount(Math.min(filterQuery?.network?.$in?.length ?? 0, networkOptions.length));
  }, [filterQuery, networkOptions.length, networksOnQuery]);

  const isSelectedNetwork = useCallback(
    (network: ChargingNetwork) => {
      return selectedNetworks.includes(network);
    },
    [selectedNetworks]
  );

  const handleNetworkToggled = useCallback(
    (network: ChargingNetwork) => {
      if (isSelectedNetwork(network)) {
        setSelectedNetworks(selectedNetworks.filter((n) => n !== network));
        return;
      }
      setSelectedNetworks([...selectedNetworks, network]);
    },
    [isSelectedNetwork, selectedNetworks]
  );

  const handleSetNetworksOnQuery = useCallback(
    (networks: ChargingNetwork[]) => {
      setFilterQuery((q) => {
        return {
          network: {
            $in: mapChargingNetworksForUrlQuery(networks),
          },
        };
      });
    },
    [setFilterQuery]
  );

  const handleClearButtonPressed = useCallback(() => {
    setSelectedNetworks([]);
    handleSetNetworksOnQuery([]);
    setVariationCount(0);
  }, [handleSetNetworksOnQuery]);

  const handleApplyButtonPressed = useCallback(() => {
    handleSetNetworksOnQuery(selectedNetworks);
    setVariationCount(selectedNetworks.length);
  }, [handleSetNetworksOnQuery, selectedNetworks]);

  return (
    <FilterEditorCard
      className="my-1"
      title="Network"
      label="Network"
      description="Brand name of charging network on record"
      variationCount={variationCount}
      onApplyPressed={handleApplyButtonPressed}
      onClearPressed={handleClearButtonPressed}
    >
      {networkOptions.map((network, ix) => (
        <FilterMenuCheckbox
          key={ix}
          onToggle={() => handleNetworkToggled(network)}
          isChecked={isSelectedNetwork(network)}
          label={getChargerInfo(network).label}
        />
      ))}
    </FilterEditorCard>
  );
}

export default FilterNetworkSelector;
