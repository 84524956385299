import BlackButton from "components/button/BlackButton";
import OrangeLinkButton from "components/button/OrangeLinkButton";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";

import Col from "components/custom/Col";

import { BlackSubtitleText100 } from "components/text/Text";
import { useState } from "react";
import { OrganizationDetails } from "types/organization.types";
import { getFormattedNumberForUsdByCent } from "utils/format.utils";
import OrganizationDetailsCardBlock from "./OrganizationDetailsCardBlock";
import OrganizationLimitFormModal from "./form/OrganizationLimitFormModal";
import OutlinedButton from "../button/OutlinedButton";
import OrganizationTeslaExporterModal from "./form/OrganizationTeslaExporterModal";

type Props = {
  readonly refetchOrganizationDetails: () => Promise<void>;
  readonly organizationDetails?: OrganizationDetails;
  readonly isLoading: boolean;
};

function OrganizationDetailsLimitCard({ organizationDetails, isLoading, refetchOrganizationDetails }: Props) {
  const history = useHistory();
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const [isTeslaExporterModalOpen, setIsTeslaExporterModalOpen] = useState(false);

  return (
    <>
      <OrganizationLimitFormModal
        organizationDetails={organizationDetails}
        defaultValue={getFormattedNumberForUsdByCent(organizationDetails?.limits?.chargingLimit)}
        isModalOpen={isLimitModalOpen}
        setIsModalOpen={setIsLimitModalOpen}
        refetchOrganizationDetails={refetchOrganizationDetails}
      />

      <OrganizationTeslaExporterModal
        isModalOpen={isTeslaExporterModalOpen}
        setIsModalOpen={setIsTeslaExporterModalOpen}
      />
      <Col className="h-235px  flex-column justify-between ">
        <Card>
          <OrganizationDetailsCardBlock
            isLoading={isLoading}
            leftLabel="# of Vehicles"
            leftTextOverwrite={
              <BlackSubtitleText100>
                {organizationDetails?.vehiclesCount ?? 0}{" "}
                <OrangeLinkButton
                  href={`/organizations/${organizationDetails?.id}/vehicles`}
                  className="ml-1"
                  text="See details"
                />
              </BlackSubtitleText100>
            }
            rightLabel="# of Drivers "
            rightText={organizationDetails?.status}
            rightTextOverwrite={
              <BlackSubtitleText100>
                {organizationDetails?.driversCount ?? 0}{" "}
                <OrangeLinkButton
                  href={`/organizations/${organizationDetails?.id}/drivers`}
                  className="ml-1"
                  text="See details"
                />
              </BlackSubtitleText100>
            }
          />
          <OrganizationDetailsCardBlock
            isLoading={isLoading}
            leftLabel="Charging limit"
            leftTextOverwrite={
              <BlackSubtitleText100>
                {getFormattedNumberForUsdByCent(organizationDetails?.limits?.chargingLimit)}{" "}
                <OrangeLinkButton onClick={() => setIsLimitModalOpen(true)} className="ml-1" text="Edit" />
              </BlackSubtitleText100>
            }
            rightTextOverwrite={
              <BlackButton
                onClick={() => history.push(`/organizations/${organizationDetails?.id}/invoices`)}
                text="Invoices"
              />
            }
            isBorderBottomVisible={false}
          />

          <OrganizationDetailsCardBlock
            isLoading={isLoading}
            leftTextOverwrite={
              <></>
            }
            rightTextOverwrite={
              <OutlinedButton
                onClick={() => setIsTeslaExporterModalOpen(true)}
                text="Export sessions from Tesla FleetAPI"
              />
            }
            isBorderBottomVisible={false}
          />
        </Card>
      </Col>
    </>
  );
}

export default OrganizationDetailsLimitCard;
