import React from "react";

type Props = {};

function HomeIcon({}: Props) {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 18.5V6.5L8 0.5L16 6.5V18.5H10V11.5H6V18.5H0Z" fill="#BE8DFF" />
    </svg>
  );
}

export default HomeIcon;
