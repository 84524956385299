import {
    BillingCycle,
    ChargingInvoiceLineItem, DriverData, Invoice, InvoiceGeneratedAs,
    InvoicePaymentState,
    InvoiceType,
    PlatformInvoiceLineItem, VehicleData
} from "types/invoice.types";
import { getFormattedNumberForEnergy, getFormattedNumberForUsdByCent } from "./format.utils";
import { getFullNameByFirstNameAndLastName } from "./driver.utils";

export const AddManualInvoiceAlertDialogStates = {
    failed: {
        isError: true,
        isOpen: true,
        message: "Invoice couldn't be created!",
    },
    success: {
        isError: false,
        isOpen: true,
        message: "Invoice created successfully!",
    }
}



export const getInvoiceTypeLabel = (invoiceType: InvoiceType) => {
    if (invoiceType === InvoiceType.CHARGING) {
        return 'Charging fee'
    }

    if (invoiceType === InvoiceType.PLATFORM) {
        return 'Platform fee'
    }


    return 'Debit fee'

}


export const getInvoicePaymentStatusLabelAndColor = (paymentState: InvoicePaymentState) => {
    if (paymentState === InvoicePaymentState.PAID) {
        return { color: "#2BA318", label: 'Paid' };
    }

    if (paymentState === InvoicePaymentState.UNPAID) {
        return { color: "#C4003E", label: 'Unpaid' };
    }

    return { color: "#C4003E", label: 'Canceled' };
}









export const getInvoiceDetailsExportDataByType = (lineItems: ChargingInvoiceLineItem[] | PlatformInvoiceLineItem[], isChargingInvoice: boolean) => {

    if (isChargingInvoice) {
        const chargingInvoiceLineItems = lineItems as ChargingInvoiceLineItem[]
        return chargingInvoiceLineItems?.map((i) => {
            return {
                name: i.objectData.__typename,
                vehicle: i.objectData.vehicleName,
                vin: i.objectData.vehicleVIN,
                driver: i.objectData.driverName,
                chargerName: i.objectData.chargerName,
                discountRate: i.discountRate,
                date: i.objectData.startedAt,
                discountAmount: getFormattedNumberForUsdByCent(i.discountAmount),
                grossAmount: getFormattedNumberForUsdByCent(i.grossAmount),
                kwh: getFormattedNumberForEnergy(i.objectData.energy),
                netAmount: getFormattedNumberForUsdByCent(i.netAmount),
                quantity: i.quantity,
                updatedAt: i.updatedAt,
            };

        })

    }

    const platformInvoiceLineItems = lineItems as PlatformInvoiceLineItem[]
    return platformInvoiceLineItems?.map((i) => getPlatformInvoiceTableRowData(i))
}



export const getPlatformInvoiceTableRowData = (lineItem: PlatformInvoiceLineItem) => {

    const isDriver = lineItem.objectData.__typename === 'DRIVER'

    if (isDriver) {
        const lineItemDriverData = lineItem.objectData as DriverData

        return {
            name: getFullNameByFirstNameAndLastName(lineItemDriverData.firstName, lineItemDriverData.lastName),
            type: 'Driver',
            price: getFormattedNumberForUsdByCent(lineItem.netAmount),
        }
    }


    const lineItemVehicleData = lineItem.objectData as VehicleData
    return {
        name: lineItemVehicleData.name,
        type: 'Vehicle',
        price: getFormattedNumberForUsdByCent(lineItem.netAmount),
    }
}



export const getInvoiceDescription = (invoice: Invoice) => {
    if (invoice.billingCycle === BillingCycle.ONE_OFF) {
        return "One Off";
    }

    if (invoice.generatedAs === InvoiceGeneratedAs.SETTLEMENT) {
        return "Settlement";
    }

    return "-";
};
