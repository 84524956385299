import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import UserProfileRegularPage from "./UserProfileRegular";
import { Icon, UserAvatar } from "../../../components/Component";
import { Card } from "reactstrap";
import { Auth } from "aws-amplify";
import { profileService } from "../../../services";
import UserProfileSettingPage from "./UserProfileSetting";

const UserProfileLayout = () => {

  const [user, setUser] = useState();
  const [data, setData] = useState();

  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const loadData = async () => {
    setUser(await Auth.currentAuthenticatedUser())
    try {
      const profileData = await profileService.getProfileInfo();
      setData(profileData?.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    /*    loadData(); */
  }, []);

  const [activeTab, setActiveTab] = useState(1);

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg content-active`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <UserAvatar text={user?.attributes?.name?.charAt(0).toUpperCase()} theme="primary" />
                    <div className="user-info">
                      <span className="lead-text">{user?.attributes?.name}</span>
                      <span className="sub-text">{user?.attributes?.email}</span>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li>
                      <a onClick={(e) => { e.preventDefault(); setActiveTab(1) }} href="#" className={activeTab === 1 ? "active" : ""}>
                        <Icon name="user-fill-c"></Icon>
                        <span>Personal Info</span>
                      </a>
                    </li>
                    <li>
                      <a onClick={(e) => { e.preventDefault(); setActiveTab(2) }} href="#" className={activeTab === 2 ? "active" : ""}>
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Security</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner card-inner-lg">{activeTab === 1 ?
              <UserProfileRegularPage data={data} /> :
              <UserProfileSettingPage updateSm={updateSm} sm={sm} />}
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment >
  );
};

export default UserProfileLayout;
