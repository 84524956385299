import SearchInput from "components/input/SearchInput";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import { useDebounce } from "hooks/timer.hooks";
import { useEffect, useState } from "react";

type Props = {
  placeholder: string;
};

function FilterSearchInput({ placeholder }: Props) {
  const { filterQuery, setFilterQuery, handleSetQueryWithoutResettingPagination } = useFilterUrlQuery<{
    q?: {
      $eq?: string;
    };
  }>();

  const filterSearchValueOnQuery = filterQuery?.q?.$eq ?? "";
  const [filterSearchValue, setFilterSearchValue] = useState(filterSearchValueOnQuery);

  const debouncedSearchValue = useDebounce(filterSearchValue, 500) as string;

  useEffect(() => {
    // Reset input, if filter cleared.
    if (!filterSearchValueOnQuery) {
      setFilterSearchValue(filterSearchValueOnQuery);
    }
  }, [filterSearchValueOnQuery, setFilterQuery]);


  useEffect(() => {
    if (debouncedSearchValue.length === 1) {
      return;
    }


    const isEmptySearch = debouncedSearchValue.length === 0;

    if (isEmptySearch) {
      handleSetQueryWithoutResettingPagination((_) => {
        return {
          q: undefined
        };
      });

      return;
    }
    setFilterQuery((_) => {
      return {
        q: {
          $eq: debouncedSearchValue
        }
      };
    });

//The setFilterQuery callback is triggered on every route change, which includes pagination changes. This triggers this useEffect that runs setFilterQuery leads the resetting of pagination.
// However, We do not need keeping the setFilterQuery up to date in this useEffect, as we are not utilizing the callback parameter.
// So do not add setFilterQuery as dependency!
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  return (
    <SearchInput
      isClearable
      className="mb-2 mb-md-0"
      defaultValue={filterSearchValueOnQuery}
      onChange={(e) => setFilterSearchValue(e.target.value)}
      value={filterSearchValue}
      placeholder={placeholder}
    />
  );
}

export default FilterSearchInput;
