import AlertConfirmDialog from "components/alert/AlertConfirmDialog";
import IosSwitch from "components/input/switch/IosSwitch";
import { useCallback, useState } from "react";
import { updateOrganizationAsync } from "services/organization.services";
import { OrganizationDetails } from "types/organization.types";

type Props = {
  readonly organizationDetails?: OrganizationDetails;
  readonly refetchOrganizationDetails: () => Promise<void>;
};

function OrganizationDetailsStatusEditor({ organizationDetails, refetchOrganizationDetails }: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsDialogOpen = () => setIsDialogOpen((is) => !is);

  const handleChangeStatusAsync = useCallback(async () => {
    if (!organizationDetails) {
      return;
    }
    const { id, limits, active } = organizationDetails;
    try {
      setIsLoading(true);
      await updateOrganizationAsync(id, {
        active: !active,
        credit: limits.chargingLimit,
        settings: organizationDetails.settings.data,
      });

      await refetchOrganizationDetails();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [organizationDetails, refetchOrganizationDetails]);

  return (
    <>
      <AlertConfirmDialog
        title="Are you sure you want to update this customer’s status?"
        isDialogOpen={isDialogOpen}
        toggleIsDialogOpen={toggleIsDialogOpen}
        onDialogConfirmed={handleChangeStatusAsync}
        isLoading={isLoading}
      />
      <IosSwitch isChecked={!!organizationDetails?.active} onSwitchStatusChanged={toggleIsDialogOpen} />
    </>
  );
}

export default OrganizationDetailsStatusEditor;
