import ConnectionStatusBadge from "components/badge/ConnectionStatusBadge";
import Card from "components/card/Card";
import DetailsCardBlock from "components/card/DetailsCard";
import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import { BlackSubtitleText0 } from "components/text/Text";
import { useOrganizationVehicleAsync } from "hooks/query/organization-query.hooks";
import { ErrorBoundary } from "react-error-boundary";
import { getLocalFormattedDateForTables } from "utils/date.utils";
import { getDriverFullName } from "utils/driver.utils";
import OrganizationVehicleDetailsSoftDeleteButton from "../form/OrganizationVehicleDetailsSoftDeleteButton";

type Props = {};

function OrganizationVehicleDetailsCards({}: Props) {
  const { isLoading, vehicle, refetch } = useOrganizationVehicleAsync();


  return (
    <ErrorBoundary FallbackComponent={() => <>An error ocurred..</>}>
      <Row>
        <Col lg="6" className="pr-lg-2 ">
          <Card>
            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="Smartcar State"
              leftTextOverwrite={<ConnectionStatusBadge connectionStatus={vehicle?.smartcarConnectionState} />}
              rightLabel="Tesla State"
              rightTextOverwrite={<ConnectionStatusBadge connectionStatus={vehicle?.teslaConnectionState} />}
            />

            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="Geotab State"
              leftTextOverwrite={<ConnectionStatusBadge connectionStatus={vehicle?.geotabConnectionState} />}
              rightLabel="Geotab Connected At"
              rightText={getLocalFormattedDateForTables(vehicle?.geotabConnectedAt)}
            />

            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="Smartcar Connected At"
              leftText={getLocalFormattedDateForTables(vehicle?.smartcarConnectedAt)}
              rightLabel="Tesla Connected At"
              rightText={getLocalFormattedDateForTables(vehicle?.teslaConnectedAt)}
            />
            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="Vehicle name"
              leftText={vehicle?.name ?? "-"}
              rightLabel="License plate"
              rightText={vehicle?.plateNumber ? vehicle?.plateNumber : "-"}
            />
            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="Make"
              leftText={vehicle?.carBrand ?? "-"}
              rightLabel="Model"
              rightText={vehicle?.carModel ?? "-"}
              isBorderBottomVisible={false}
            />
          </Card>
        </Col>
        <Col lg="6" className="pl-lg-2 mt-4 mt-lg-0">
          <Card>
            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="ID"
              leftTextOverwrite={<ClipboardWithCopyIcon TextComponent={BlackSubtitleText0} text={vehicle?.id ?? "-"} />}
            />
            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="VIN"
              leftTextOverwrite={
                <ClipboardWithCopyIcon TextComponent={BlackSubtitleText0} text={vehicle?.vin ?? "-"} />
              }
              rightLabel="Driver"
              rightText={vehicle?.users[0] ? getDriverFullName(vehicle?.users[0]) : "-"}
            />
            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="Added"
              leftText={getLocalFormattedDateForTables(vehicle?.createdAt)}
              rightLabel="Soft deleted"
              rightText={getLocalFormattedDateForTables(vehicle?.deletedAt)}
              isBorderBottomVisible={false}
            />

            <Row className="mt-3 justify-content-end">
              <OrganizationVehicleDetailsSoftDeleteButton refetch={refetch} isDisabled={!!vehicle?.deletedAt} />
            </Row>

          </Card>
        </Col>
      </Row>
    </ErrorBoundary>
  );
}

export default OrganizationVehicleDetailsCards;
