import { Driver } from "types/driver.types";
import { PayerType } from "types/payment.types";

export const getDriverActivationStatusColorAndLabel = (isActive) => {
  if (isActive) {
    return { color: "#2BA318", label: "Active" };
  }

  return { color: "#C4003E", label: "Inactive" };
};

export const getDriverTypeLabel = (type?: string) => {
  return type === "fixed" ? "Permanent" : "Temporary";
};

export const getDriverStatusLabel = (active?: boolean) => {
  return active ? "Active" : "Inactive";
};

export const getDriverFullName = (driver?: Driver) => {
  const firstName = driver?.firstName ?? "";
  const lastName = driver?.lastName ?? "";

  return `${firstName} ${lastName}`;
};


export const getPayerLabelByType = (payerType: PayerType) => {
  switch (payerType) {
    case PayerType.ORGANIZATION:
      return "Organization";
    case PayerType.DRIVER:
      return "Driver";
    default:
      console.error("Unknown payer type!");
      return "Driver";
  }
};

export const getUserStatusAsBoolean = (status: string) => {
  return status === "Active";
};

export const mapUserStatusAsBoolean = (status: string[]) => {
  return status.map((s) => getUserStatusAsBoolean(s));
};


export const getFullNameByFirstNameAndLastName = (firstName: string = "", lastName: string = "") => {
  return `${firstName} ${lastName}`;
};


export const getDriverPaymentResponsibilityLabelByPayerType = (payerType?: PayerType) => {
  if (!payerType) {
    return "-";
  }
  return getWhoPaysDisplayTextByPayerType(payerType) + " Pays";
};


export const getWhoPaysDisplayTextByPayerType = (payer: PayerType) => {
  switch (payer) {
    case PayerType.DRIVER:
      return "Driver";
    case PayerType.ORGANIZATION:
      return "Organization";
    default:
      return "-";
  }
};