import React, { useEffect } from "react";

import PageHeader from "components/header/PageHeader";
import OrganizationInvoicesTable from "components/organization/OrganizationInvoicesTable";
import { useOrganizationAsync } from "hooks/query/organization-query.hooks";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router";

const OrganizationInvoicesPage = () => {
  const { pathname } = useLocation();
  const { isLoading, organization } = useOrganizationAsync();

  useEffect(() => {
    if (!isLoading) {
      window.scrollTo(0, 0);
    }
  }, [pathname, isLoading]);

  return (
    <React.Fragment>
      <Head title={"Organization Invoices"} />
      <Content>
        <PageHeader isLoading={isLoading} title={"Org. Invoices"} description={organization?.businessName} />
        <ErrorBoundary FallbackComponent={() => <>An error ocurred..</>}>
          <OrganizationInvoicesTable />
        </ErrorBoundary>
      </Content>
    </React.Fragment>
  );
};

export default OrganizationInvoicesPage;
