import React from "react";

type Props = { userName?: string };

function UserAvatar({ userName }: Props) {
  return (
    <div className="user-avatar">
      <span>{userName?.charAt(0).toUpperCase()}</span>
    </div>
  );
}

export default UserAvatar;
