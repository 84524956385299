import { CircularProgress } from "@mui/material";

import React from "react";
import Button from "./Button";

export default function BaseButton({
  isDisabled,
  isLoading,
  className,
  textOverride,
  text,
  textColor,
  onClick,
  height,
  theme,
  ...otherProps
}) {
  const baseHeight = 48;
  const themeValue = theme ?? "primary";
  const textColorValue = textColor ?? "text-white";
  const buttonHeight = height ?? baseHeight;
  const buttonHeightClassName = `h-${buttonHeight}px`;

  return (
    <Button
      disabled={isDisabled}
      color={themeValue}
      className={` rounded-xxl center ${buttonHeightClassName} ${className ?? ""}`}
      onClick={onClick}
      {...otherProps}
    >
      {isLoading ? (
        <CircularProgress size={12} color={"inherit"} />
      ) : (
        textOverride ?? <span className={`font-weight-normal  fs-17px ${textColorValue}`}>{text}</span>
      )}
    </Button>
  );
}
