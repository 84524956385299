import React from "react";

type Props = {
  readonly containerStyle: string;
  readonly text: string;
  readonly textStyle: string;
  readonly dotStyle?: string;
  readonly style?: React.CSSProperties;
  readonly icon?: JSX.Element;
  readonly rightTextEl?: JSX.Element;
};

export default function Badge({ containerStyle, text, textStyle, dotStyle, style, icon, rightTextEl }: Props) {
  return (
    <div className={containerStyle} style={{ paddingBottom: 4, paddingTop: 4, ...style }}>
      {dotStyle && <span className={dotStyle}></span>}
      {icon && icon}
      <p title={text} className={textStyle}>
        {text}
      </p>
      {rightTextEl && rightTextEl}
    </div>
  );
}
