import { useMemo } from "react";
import { VehicleConnectionState } from "types/vehicle.types";
import Badge from "./Badge";

type Props = {
  readonly connectionStatus?: VehicleConnectionState;
};

function ConnectionStatusBadge({ connectionStatus }: Props) {
  const badgeProps = useMemo(() => {
    const COMMON_TEXT_STYLE = "fs-16px font-weight-400 ";
    const COMMON_CONTAINER_STYLE = "rounded-xl center pointer-events-none";

    const connectedBadgeProps = {
      text: "Connected",
      textStyle: COMMON_TEXT_STYLE + "text-green-200 ",
      containerStyle: COMMON_CONTAINER_STYLE,
    };

    switch (connectionStatus) {
      case VehicleConnectionState.CONNECTED:
        return connectedBadgeProps;

      case VehicleConnectionState.DISCONNECTED:
        return {
          text: "Disconnected",
          textStyle: COMMON_TEXT_STYLE + "text-danger",
          containerStyle: COMMON_CONTAINER_STYLE,
        };

      case VehicleConnectionState.NOT_CONNECTED:
        return {
          text: "Not connected",
          textStyle: COMMON_TEXT_STYLE + "text-purple-100",
          containerStyle: COMMON_CONTAINER_STYLE,
        };
      default:
        return connectedBadgeProps;
    }
  }, [connectionStatus]);

  return <Badge {...badgeProps} />;
}

export default ConnectionStatusBadge;
