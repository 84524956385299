import { ChevronLeft } from "@mui/icons-material";
import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import TextButton from "./TextButton";

export default function BackButton() {
  const { pathname } = useLocation();

  const routesWhereTheButtonDoesNotVisible = ["/"];

  const doesNotCurrentRouteShowTheButton = !!routesWhereTheButtonDoesNotVisible.find((r) => {
    return pathname === r;
  });

  const history = useHistory();

  const handleBackClicked = useCallback(() => {
    history.goBack();
  }, [history]);

  if (doesNotCurrentRouteShowTheButton) {
    return <></>;
  }

  return (
    <TextButton
      className="hover-background-white  p-1"
      onClick={handleBackClicked}
      icon={<ChevronLeft className="w-20px h-25px" htmlColor="#1c2b46" />}
      text="Back"
    />
  );
}
