import { ApiResponse } from "types/api.types";
import { AddManualInvoicePayload, Invoice } from "types/invoice.types";
import { generateApiEndpoint } from "utils";
import restApiClient from "./client";

/**
 * POST: '/board/invoice'
 */
export async function addManualInvoiceAsync(payload: AddManualInvoicePayload): Promise<ApiResponse<{}>> {
    const endpoint = generateApiEndpoint(`board/invoice`);
    const response = await restApiClient.post(endpoint, payload);
    return response.data;
}


/**
 * GET: 'board/invoices/{id}'
 */
export async function getInvoiceAsync(invoiceId: string): Promise<ApiResponse<Invoice>> {
    const endpoint = generateApiEndpoint(`board/invoices/${invoiceId}`);
    const response = await restApiClient.get(endpoint);

    return response.data;
}