import React from "react";

type Props = {
  widthValue?: number;
  heightValue?: number;
  fillValue?: string;
  className?: string;
};
export default function RightChevron(props: Props) {
  const width = props.widthValue ?? 12;
  const height = props.heightValue ?? 20;
  const fill = props.fillValue ?? "#0C0C0C";

  return (
    <svg
      width={width}
      height={height}
      fontSize={width}
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.75 1.5L10.25 10L1.75 18.5"
        stroke={fill}
        stroke-width="2.55"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
