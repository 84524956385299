import LoadingSkeleton from "./LoadingSkeleton";

type Props = {
  count?: number;
};

const LoadingSkeletonForPaginatedTable = (props: Props) => {
  const count = props.count ?? 20;
  const headerAndPaginationHeight = 95;
  const rowHeight = 46;
  return (
    <div className="w-100 h-100 p-2 d-flex flex-column rounded-xxl align-items-center">
      <div
        className="w-100 d-flex align-items-center overflow-hidden"
        style={{ height: count * rowHeight + headerAndPaginationHeight }}
      >
        <LoadingSkeleton count={count} height={rowHeight} />
      </div>
    </div>
  );
};

export default LoadingSkeletonForPaginatedTable;
