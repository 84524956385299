import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import Row from "components/custom/Row";
import { BlackBody2Text100 } from "components/text/Text";
import { useHistory, useParams } from "react-router";

import { getLocalFormattedDateForTables, PredefinedMaxDateValue } from "utils/date.utils";
import { getDriverFullName } from "utils/driver.utils";
import { getVehicleDriverSchedulePeriodInfo } from "utils/vehicle-driver-schedule.utils";


import VehicleDriverSchedulePeriodBadge from "../badge/VehicleDriverSchedulePeriodBadge";
import React from "react";

import UnderlinedTextButton from "../button/UnderlinedTextButton";
import DriverDetailsSectionFormBox from "../driver/DriverDetailsSectionFormBox";
import { VehicleDriverSchedule } from "../../types/vehicle-driver-schedule.types";
import Card from "../card/Card";


type Props = {
  schedule: VehicleDriverSchedule;
  isForDriver?: boolean;
  ix: number;
  isBorderBottomVisible?: boolean
};

function DriverVehicleScheduleCard({
                                     schedule,

                                     ix,
                                     isForDriver,
                                     isBorderBottomVisible
                                   }: Props) {

  const history = useHistory();
  const {organizationId} = useParams<{organizationId}>()
  const { isActive, timeDifference, color } = getVehicleDriverSchedulePeriodInfo(schedule.startsAt, schedule.endsAt);
  return (
    <>
      <Card className={"mt-2"}>
        <Row className="justify-between ">
          <Row className="align-items-center">
            <BlackBody2Text100 className="mr-2">Vehicle assign. {ix + 1}</BlackBody2Text100>

            {!isActive && <div className="scheduled-badge mr-2">Scheduled</div>}

          </Row>
        </Row>
        <DriverDetailsSectionFormBox className="mt-3" isBorderHidden label={isForDriver ? "Driver name" : "Vehicle"}>
          {isForDriver ? (
            <UnderlinedTextButton text={getDriverFullName(schedule.user)}
                                  onClick={() => history.push(`/organizations/${organizationId}/drivers/${schedule.userId}`)} />


          ) : (
            <UnderlinedTextButton text={schedule.vehicle.name}
                                  onClick={() => history.push(`/organizations/${organizationId}/vehicles/${schedule.vehicleId}`)} />


          )}
        </DriverDetailsSectionFormBox>
        {!isForDriver && (
          <DriverDetailsSectionFormBox className="mt-3" isBorderHidden label={"VIN"}>
            <ClipboardWithCopyIcon TextComponent={BlackBody2Text100} text={schedule?.vehicle?.vin} />
          </DriverDetailsSectionFormBox>
        )}

        <DriverDetailsSectionFormBox className="mt-3" isBorderHidden label={"Vehicle assignment period"}>
          <VehicleDriverSchedulePeriodBadge color={color} timeDifference={timeDifference} />
        </DriverDetailsSectionFormBox>
        <DriverDetailsSectionFormBox
          className="mt-3"
          isBorderHidden
          label={"Start date"}
          value={getLocalFormattedDateForTables(schedule.startsAt)}
        />
        <DriverDetailsSectionFormBox
          className="mt-3"
          isBorderHidden
          label={"End date (optional)"}
          value={schedule.endsAt === PredefinedMaxDateValue ? "-" : getLocalFormattedDateForTables(schedule.endsAt)}
        />
      </Card>

    </>
  )
    ;
}

export default DriverVehicleScheduleCard;
