import React from "react";
import BlackButton, { BlackButtonWithIconProps } from "./BlackButton";

type Props = BlackButtonWithIconProps;

function AddButton(props: Props) {
  return <BlackButton {...props} text="Add" />;
}

export default AddButton;
