import { useMemo } from "react";

import LoadingSkeletonForPaginatedTable from "components/loading/LoadingSkeletonForPaginatedTable";
import DataTable, { TableColumn } from "react-data-table-component";

import { ChargingInvoiceLineItem } from "types/invoice.types";
import { getFormattedNumberForEnergy } from "utils/charging.utils";
import { getLocalFormattedDateForTables } from "utils/date.utils";
import { getFormattedNumberForUsdByCent } from "utils/format.utils";
import ClientPaginatedTable from "../../../components/table/ClientPaginatedTable";


export type ChargingInvoiceLineItemsTableColumn = TableColumn<ChargingInvoiceLineItem>[];
type Props = {
  readonly lineItems: ChargingInvoiceLineItem[];
  readonly isLoading: boolean;
};

function ChargingInvoiceDetailsTable({ lineItems, isLoading }: Props) {
  const columns = useMemo(
    () => [
      {
        grow: 1.2,
        name: "Vehicle Name",
        selector: (row) => <p title={row.objectData.vehicleName}>{row.objectData.vehicleName ?? "-"}</p>
      },
      {
        grow: 1,
        name: "VIN",
        selector: (row) => row.objectData.vehicleVIN ?? "-"
      },
      {
        grow: 1.2,
        name: "Driver",
        selector: (row) => <p title={row.objectData.driverName}>{row.objectData.driverName ?? "-"}</p>
      },
      {
        grow: 1.5,
        name: "Charger name",
        selector: (row) => <p title={row.objectData?.chargerName}>{row.objectData?.chargerName ?? "-"}</p>
      },
      {
        width: "120px",
        name: "kWh",
        selector: (row) => <>{getFormattedNumberForEnergy(row?.objectData?.energy)} kWh </>
      },
      {
        width: "120px",
        name: "Cost",
        selector: (row) => getFormattedNumberForUsdByCent(row.netAmount) ?? "-"
      },
      {
        width: "170px",
        name: "Date",
        selector: (row) => <>{getLocalFormattedDateForTables(row?.objectData.startedAt)} </>
      }
    ],
    []
  ) as ChargingInvoiceLineItemsTableColumn;

  return (
    <div className="mt-4 w-100 invoices-table">
      <ClientPaginatedTable
        columns={columns}
        data={lineItems}
        progressPending={isLoading}
        progressComponent={
          <div className="w-100 h-100 px-2 d-flex flex-column rounded-xxl align-items-center overflow-hidden">
            <LoadingSkeletonForPaginatedTable count={20} />
          </div>
        }
      />
    </div>
  );
}

export default ChargingInvoiceDetailsTable;
