import { Auth } from "aws-amplify";
import axios from "axios";
import { handleApiErrorResponse } from "../utils";

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(async (config) => {
    const user = await Auth.currentAuthenticatedUser();
    config.headers["Authorization"] = user?.signInUserSession?.idToken?.jwtToken;
    return config;
  },
  error => {
   return Promise.reject(error);
  },
  );


axiosApiInstance.interceptors.response.use(async (config) => {
    return config;
  },
  error => {
    console.error("error", error);
    window.dispatchEvent(new CustomEvent("api-error", { detail: handleApiErrorResponse(error) }));
    return Promise.reject(error);
  },
);


export default axiosApiInstance;
