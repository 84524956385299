import React from "react";

import AddManualInvoiceForm from "components/invoice/AddManualInvoiceForm";
import Content from "layout/content/Content";
import Head from "layout/head/Head";

const AddManualInvoicePage = () => {
  return (
    <React.Fragment>
      <Head title={"Add Manual Invoice"} />
      <Content>
        <AddManualInvoiceForm />
      </Content>
    </React.Fragment>
  );
};

export default AddManualInvoicePage;
