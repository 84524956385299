import AddManualInvoicePage from "pages/invoice/AddManualInvoicePage";
import OrganizationDetailsPage from "pages/organization/OrganizationDetailsPage";
import OrganizationInvoicesPage from "pages/organization/OrganizationInvoicesPage";
import OrganizationDriverDetailsPage from "pages/organization/driver/OrganizationDriverDetailsPage";
import OrganizationDriversPage from "pages/organization/driver/OrganizationDriversPage";
import OrganizationVehicleDetailsPage from "pages/organization/vehicle/OrganizationVehicleDetailsPage";
import OrganizationVehiclesPage from "pages/organization/vehicle/OrganizationVehiclesPage";
import { Suspense, useLayoutEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import "react-loading-skeleton/dist/skeleton.css";
import { Route, Switch } from "react-router-dom";
import Homepage from "../pages/Homepage";
import SignupApplications from "../pages/content/SignupApplications";
import OrganizationsPage from "../pages/organization/OrganizationsPage";
import UserProfileLayout from "../pages/pre-built/user-manage/UserProfileLayout";
import { RedirectAs404 } from "../utils/Utils";
import { useApiErrorHandlerProvider } from "../hooks/alert.hooks";
import OrganizationInvoiceDetailsPage from "../pages/invoice/OrganizationInvoiceDetailsPage";
import ChargingDetailsPage from "../pages/charging/ChargingDetailsPage";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });


  useApiErrorHandlerProvider();

  return (
    <Suspense fallback={<div />}>
      <ErrorBoundary fallback={<></>}>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/profile`} component={UserProfileLayout} />

          {/** Organization */}
          <Route exact path={`${process.env.PUBLIC_URL}/organizations`} component={OrganizationsPage} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/organizations/:organizationId`}
            component={OrganizationDetailsPage}
          />

          {/** Organization > Invoices */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/organizations/:organizationId/invoices`}
            component={OrganizationInvoicesPage}
          />
          <Route exact path={`${process.env.PUBLIC_URL}/organizations/:organizationId/invoices/:invoiceId`}
                 component={OrganizationInvoiceDetailsPage} />

          <Route exact path={`${process.env.PUBLIC_URL}/organizations/:organizationId/charging/:chargingId`}
                 component={ChargingDetailsPage} />


          {/** Organization > Driver */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/organizations/:organizationId/drivers`}
            component={OrganizationDriversPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/organizations/:organizationId/drivers/:driverId`}
            component={OrganizationDriverDetailsPage}
          />

          {/** Organization > Vehicle */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/organizations/:organizationId/vehicles`}
            component={OrganizationVehiclesPage}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/organizations/:organizationId/vehicles/:vehicleId`}
            component={OrganizationVehicleDetailsPage}
          />

          {/** Invoice */}
          <Route exact path={`${process.env.PUBLIC_URL}/invoice/add-manual-invoice`} component={AddManualInvoicePage} />

          <Route exact path={`${process.env.PUBLIC_URL}/signup-applications`} component={SignupApplications} />

          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Homepage} />

          <Route component={RedirectAs404}></Route>
        </Switch>
      </ErrorBoundary>
    </Suspense>
  );
};
export default Pages;
