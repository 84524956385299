import React from "react";
import { SvgProps } from "types/svg.types";

const VehiclePlaceholderIcon = ({ fill, width, height }: SvgProps) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 36;
  const heightValue = height ?? 36;

  return (
    <svg
      width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={`${widthValue}`} height={`${heightValue}`} rx="18" fill="#F3F5F9" />
      <path
        d="M27.7903 16.8439L25.8755 14.7882L24.1768 10.8625C24.031 10.5883 23.8265 10.3623 23.5831 10.206C23.3396 10.0497 23.0655 9.96854 22.787 9.97024H13.2129C12.9344 9.96854 12.6603 10.0497 12.4168 10.206C12.1734 10.3623 11.9689 10.5883 11.8231 10.8625L10.1244 14.7882L8.2096 16.8439C8.13159 16.9275 8.06938 17.0288 8.02686 17.1415C7.98434 17.2542 7.96244 17.3758 7.96252 17.4988V25.1379C7.96252 25.3745 8.04387 25.6015 8.18867 25.7688C8.33347 25.9361 8.52986 26.0301 8.73463 26.0301H11.8231C12.1319 26.0301 12.5952 25.6732 12.5952 25.3163V24.2457H23.4047V25.1379C23.4047 25.4948 23.7136 26.0301 24.0224 26.0301H27.2653C27.4701 26.0301 27.6664 25.9361 27.8112 25.7688C27.956 25.6015 28.0374 25.3745 28.0374 25.1379V17.4988C28.0375 17.3758 28.0156 17.2542 27.9731 17.1415C27.9305 17.0288 27.8683 16.9275 27.7903 16.8439ZM13.3673 11.7547H22.6326L24.1768 15.3235H11.8231L13.3673 11.7547ZM14.1394 19.963C14.1394 20.3199 13.6761 20.6768 13.3673 20.6768H10.1244C9.81559 20.6768 9.50675 20.1415 9.50675 19.7846V17.8217C9.66117 17.2864 9.97001 16.9295 10.4333 17.108L13.5217 17.8217C13.8306 17.8217 14.1394 18.3571 14.1394 18.7139V19.963ZM26.4932 19.7846C26.4932 20.1415 26.1843 20.6768 25.8755 20.6768H22.6326C22.3238 20.6768 21.8605 20.3199 21.8605 19.963V18.7139C21.8605 18.3571 22.1694 17.8217 22.4782 17.8217L25.5666 17.108C26.0299 16.9295 26.3387 17.2864 26.4932 17.8217V19.7846Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};
export default VehiclePlaceholderIcon;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue} `}
 */
