import AlertConfirmDialog from "components/alert/AlertConfirmDialog";
import IosSwitch from "components/input/switch/IosSwitch";
import { useCallback, useState } from "react";
import { updateOrganizationAsync } from "services/organization.services";
import { OrganizationDetails } from "types/organization.types";
import { useAlertManager } from "../../../hooks/alert.hooks";
import { handleApiErrorResponse } from "../../../utils";

type Props = {
  readonly organizationDetails?: OrganizationDetails;
  readonly refetchOrganizationDetails: () => Promise<void>;
};

function OrganizationDetailsMultipleDriverAssignmentsStatusEditor({
                                                                    organizationDetails,
                                                                    refetchOrganizationDetails
                                                                  }: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const toggleIsDialogOpen = () => setIsDialogOpen((is) => !is);

  const handleChangeStatusAsync = useCallback(async () => {
    if (!organizationDetails) {
      return;
    }
    const { id, limits, active } = organizationDetails;
    try {
      setIsLoading(true);
      await updateOrganizationAsync(id, {
        active,
        credit: limits.chargingLimit,
        settings: {
          ...organizationDetails.settings.data,
          allowOverlappingDriverAssignments: !organizationDetails.settings.data.allowOverlappingDriverAssignments
        }
      });

      await refetchOrganizationDetails();
      handleOpenSuccessAlert("Assignment status updated successfully.");
    } catch (error) {
     // handleOpenErrorAlert(handleApiErrorResponse(error));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, organizationDetails, refetchOrganizationDetails]);


  const isChecked = organizationDetails?.settings?.data?.allowOverlappingDriverAssignments;

  return (
    <>
      <AlertConfirmDialog
        title={`Are you sure you want to ${isChecked ? "disable" : "allow"} it?`}
        description={isChecked ? "This customer won't be able to make multiple driver assignments." : "Please make sure that this customer is not using Tesla daily invoicing service for driver pays. If they're using it, please make sure that they won't have multiple assignments for Tesla vehicles."}
        isDialogOpen={isDialogOpen}
        toggleIsDialogOpen={toggleIsDialogOpen}
        onDialogConfirmed={handleChangeStatusAsync}
        isLoading={isLoading}
      />
      <IosSwitch isChecked={!!isChecked}
                 onSwitchStatusChanged={toggleIsDialogOpen} />
    </>
  );
}

export default OrganizationDetailsMultipleDriverAssignmentsStatusEditor;
