// React
import { useMemo } from "react";
import { useHistory, useParams } from "react-router";

// 3rd party
import { useTranslation } from "react-i18next";

// Hooks & Utils
// Types
// Components
import Row from "../../custom/Row";

import FilterableTableCsvLinkButton from "components/table/FilterableTableCsvLinkButton";
import ServerPaginatedTable from "components/table/ServerPaginatedTable";
import { BlackH5Text100 } from "components/text/Text";
import { useOrganizationDriverChargingListAsync } from "hooks/query/organization-query.hooks";
import { useCommonTableColumns } from "hooks/table.hooks";
import { getOrganizationDriverChargingListAsync } from "services/organization.services";
import { Driver } from "types/driver.types";
import { CommonTableColumn } from "types/table.types";
import { getChargingSessionRowForCsv } from "utils/charging.utils";
import ChargingFilterMenu from "../charging/filter/ChargingFilterMenu";

export type DriversTableColumn = CommonTableColumn<Driver>;

type Props = {};

const OrganizationDriverChargingListTable = ({}: Props) => {
  const { organizationId, driverId } = useParams<{ organizationId: string; driverId: string }>();

  const { list, meta, isLoading } = useOrganizationDriverChargingListAsync();


  const history = useHistory();
  /**
   * Hooks
   */

  const { t } = useTranslation("common");

  const commonTableColumns = useCommonTableColumns();

  const columns = useMemo(
    () => [
      commonTableColumns.organizationVehicle,
      commonTableColumns.payStat,
      commonTableColumns.date,
      commonTableColumns.chargerName,
      commonTableColumns.chargingState,
      commonTableColumns.source,
      commonTableColumns.energy,
      commonTableColumns.cost,
      commonTableColumns.duration,
      commonTableColumns.type
    ],
    [commonTableColumns]
  ) as DriversTableColumn[];

  /**
   * Handlers
   */

  /**
   * Effects
   */

  /**
   * Render
   */

  return (
    <div className="mt-4">
      <Row className="w-100 justify-between align-items-center mb-4">
        <BlackH5Text100>Invoices & Charging sessions</BlackH5Text100>

        <FilterableTableCsvLinkButton
          fileName="organization-driver-charging-list"
          csvRowFormatter={getChargingSessionRowForCsv}
          totalPages={meta?.pagination.totalPages ?? 0}
          fetchListAsync={(query) => getOrganizationDriverChargingListAsync(organizationId ?? "", driverId, query)}
        />
      </Row>

      <ChargingFilterMenu />

      <ServerPaginatedTable
        getRoutePath={id => `/organizations/${organizationId}/charging/${id}`}
        paginationTotalRows={meta?.pagination.totalElements}
        columns={columns}
        data={list}
        isLoading={isLoading}
      />
    </div>
  );
};

export default OrganizationDriverChargingListTable;
