import { Route, Switch, withRouter } from "react-router-dom";
import Layout from "./layout/Index";
import Login from "./pages/auth/Login";
import PrivateRoute from "./route/PrivateRoute";
import { RedirectAs404 } from "./utils/Utils";
console.log(process.env.REACT_APP_API_URL);

const App = (props) => {
  return (
    <>
      <Switch>
        {/* Auth Pages */}
        <Route exact path={`${process.env.PUBLIC_URL}/auth-login`} component={Login}></Route>

        {/*Main Routes*/}
        <PrivateRoute exact path="" component={Layout} />
        <Route component={RedirectAs404}></Route>
      </Switch>
    </>
  );
};
export default withRouter(App);
