import { useCallback } from "react";
import { useHistory } from "react-router";
import { getDriverPaymentResponsibilityLabelByPayerType } from "../../utils/driver.utils";


import Card from "../card/Card";
import Row from "../custom/Row";


import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import { BlackBodyText100 } from "../text/Text";
import ChargingSessionsDetailsPageCardBlock from "./ChargingDetailsPageCardBlock";
import VehiclePlaceholderIcon from "../../assets/icons/VehiclePlaceholderIcon";
import { Charging } from "../../types/charging.types";

type Props = {
  charging: Charging
}

export default function ChargingDetailsPageUserCard({ charging }: Props) {
  const { user, vehicle } = charging;

  const history = useHistory();


  const handleViewClicked = useCallback(() => {
    if (!user?.id) {
      return;
    }

    history.push(`/drivers/${user?.id}`);
  }, [user?.id, history]);


  return (
    <Card>
      {vehicle && (
        <>
          <Row className="align-center mb-1">
            <div className="w-48px h-48px mr-1">
              <VehiclePlaceholderIcon />
            </div>
            <BlackBodyText100 className={"m-0"}>{vehicle?.name}</BlackBodyText100>
          </Row>

          <ChargingSessionsDetailsPageCardBlock
            leftLabel="Vehicle"
            leftText={vehicle?.carBrand}
            rightLabel="Model"
            rightText={vehicle?.carModel}
          />
        </>
      )}

      {user && (
        <>
          <ChargingSessionsDetailsPageCardBlock
            leftLabel="Driver name"
            leftTextOverwrite={
              <div onClick={handleViewClicked} className="clickable">
                <BlackBodyText100>
                  {user?.firstName} {user?.lastName}
                </BlackBodyText100>
              </div>
            }
          />
          <ChargingSessionsDetailsPageCardBlock
            leftLabel="Payment Responsibility"
            leftText={getDriverPaymentResponsibilityLabelByPayerType(charging?.payer)}
          />
        </>
      )}

      {vehicle && (
        <ChargingSessionsDetailsPageCardBlock
          leftLabel="License Plate"
          leftText={vehicle?.plateNumber}
          rightLabel={"VIN"}
          rightTextOverwrite={<ClipboardWithCopyIcon TextComponent={BlackBodyText100} text={vehicle?.vin} />}
        />
      )}
    </Card>
  );
}

/**
 * onClick={() => history.push(`/edit-vehicle/${row.id}`)}
 */
