// React
import { useMemo } from "react";
import { useHistory } from "react-router";

// 3rd party
import { useTranslation } from "react-i18next";

// Hooks & Utils
// Types
// Components
import Row from "../../custom/Row";

import Col from "components/custom/Col";
import FilterSearchInput from "components/filter/FilterSearchInput";
import PageHeader from "components/header/PageHeader";
import FilterableTableCsvLinkButton from "components/table/FilterableTableCsvLinkButton";
import ServerPaginatedTable from "components/table/ServerPaginatedTable";
import { useOrganizationAsync, useOrganizationDriversAsync } from "hooks/query/organization-query.hooks";
import { useCommonTableColumns } from "hooks/table.hooks";
import { getOrganizationDriversAsync } from "services/organization.services";
import { Driver } from "types/driver.types";
import { CommonTableColumn } from "types/table.types";
import DriversTableFilterMenu from "./filter/DriversTableFilterMenu";

export type DriversTableColumn = CommonTableColumn<Driver>;

type Props = {};

const OrganizationDriversTable = ({}: Props) => {
  const { organization, isLoading: isOrganizationLoading } = useOrganizationAsync();

  const { list, meta, isLoading } = useOrganizationDriversAsync();

  /**
   * Hooks
   */

  const { t } = useTranslation("common");

  const history = useHistory();

  const commonTableColumns = useCommonTableColumns();

  const columns = useMemo(
    () => [
      commonTableColumns.driver,
      commonTableColumns.driverStatus,
      commonTableColumns.email,
      commonTableColumns.whoPays,
      commonTableColumns.action
    ],
    [commonTableColumns]
  ) as DriversTableColumn[];

  /**
   * Handlers
   */

  /**
   * Effects
   */

  /**
   * Render
   */

  return (
    <div>
      <Col className="mb-4">
        <PageHeader
          isLoading={isOrganizationLoading}
          title={`${organization?.businessName} - Drivers`}
          description={`View fleet organization's drivers and their details`}
        />
      </Col>

      <Row className="justify-between my-2 align-center">
        <FilterSearchInput placeholder="Search driver" />
        <FilterableTableCsvLinkButton
          csvRowFormatter={(d) => d}
          totalPages={meta?.pagination.totalPages ?? 0}
          fileName="organization-drivers"
          fetchListAsync={(query) => getOrganizationDriversAsync(organization?.id ?? "", query)}
        />
      </Row>

      <DriversTableFilterMenu />

      <ServerPaginatedTable
        getRoutePath={id => `/organizations/${organization?.id}/drivers/${id}`}
        paginationTotalRows={meta?.pagination.totalElements}
        columns={columns}
        data={list}
        isLoading={isLoading}
      />
    </div>
  );
};

export default OrganizationDriversTable;
