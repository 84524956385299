import TableNoResultPlaceholder from "./TableNoResultPlaceholder";

type Props = {};

function PaginatedTableFilterPlaceholder({}: Props) {
  return (
    <TableNoResultPlaceholder title="No results found" description="Try different keywords or adjust your filters." />
  );
}

export default PaginatedTableFilterPlaceholder;
