import { useEffect } from "react";
import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import Head from "../../layout/head/Head";
import { useTranslation } from "react-i18next";
import Logo from '../../images/blueboard-fleet-logo.png'

const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation("common");

  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (route === "authenticated") {
      window.location.href = "/";

    }
  }, [route, history, from]);

  return (
    <>
      <Head title={t('auth.signin')} />
      <View className="auth-wrapper">
        <div className="nk-block" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
          <img className="mb-4" src={Logo} alt="Bluedot for Fleets Logo" width={250} />
          <Authenticator hideSignUp={true} loginMechanisms={["email"]}>
          </Authenticator>
        </div>

      </View>
    </>
  );
};

export default Login;
