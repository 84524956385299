import React from "react";

type Props = {};

function AlertFailIcon({}: Props) {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_25959_46242)">
        <rect x="0.329102" y="0.625" width="96.75" height="96.75" rx="48.375" fill="#BF3D62" />
        <path
          d="M45.2605 55.3438L44.4108 26.2722H52.7256L51.8152 55.3438H45.2605ZM48.7199 69.1817C47.2633 69.1817 46.0697 68.7366 45.1391 67.8465C44.2489 66.9563 43.8038 65.8841 43.8038 64.6298C43.8038 63.335 44.2489 62.2425 45.1391 61.3524C46.0697 60.4622 47.2633 60.0171 48.7199 60.0171C50.0956 60.0171 51.2285 60.4622 52.1187 61.3524C53.0493 62.2425 53.5146 63.335 53.5146 64.6298C53.5146 65.8841 53.0493 66.9563 52.1187 67.8465C51.2285 68.7366 50.0956 69.1817 48.7199 69.1817Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_25959_46242">
          <rect x="0.329102" y="0.625" width="96.75" height="96.75" rx="48.375" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AlertFailIcon;
