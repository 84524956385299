import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertSliceState, AlertType } from "types/alert.types";

const initialState: AlertSliceState = {
  isOpen: false,
  message: "",
  type: AlertType.SUCCESS,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<{ message: string; type: AlertType }>) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.isOpen = true;
    },
    closeAlert: (state) => {
      state.isOpen = false;
    },
  },
});

export const { setAlert, closeAlert } = alertSlice.actions;
export default alertSlice.reducer;
