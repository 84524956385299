// React
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

// 3rd party
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

// Hooks & Utils

// Types

// Components
import Row from "../custom/Row";
import SearchInput from "../input/SearchInput";

import AddInvoiceIcon from "assets/icons/AddInvoiceIcon";
import TextButton from "components/button/TextButton";
import LoadingSkeletonForTable from "components/loading/LoadingSkeletonForTable";
import TablePlaceholder from "components/placeholder/TablePlaceholder";
import { useCommonTableColumns } from "hooks/table.hooks";
import { OrganizationBase, OrganizationList } from "types/organization.types";
import { CommonTableColumn } from "types/table.types";
import CsvLinkButton from "../button/CsvLinkButton";
import ClientPaginatedTable from "../table/ClientPaginatedTable";

export type OrganizationsTableColumn = CommonTableColumn<OrganizationBase>;

type Props = {
  organizations: OrganizationList;
  isLoading: boolean;
};

const OrganizationsTable = ({ isLoading, organizations }: Props) => {
  /**
   * Hooks
   */

  const { t } = useTranslation("common");

  const history = useHistory();

  const [filteredData, setFilteredData] = useState(organizations);

  useEffect(() => {
    setFilteredData(organizations);
  }, [organizations]);

  const commonTableColumns = useCommonTableColumns();

  const columns = useMemo(
    () => [
      commonTableColumns.customerName,
      commonTableColumns.driverStatus,
      commonTableColumns.email,
      commonTableColumns.fleetManager,
      commonTableColumns.vehiclesCount,
      commonTableColumns.action,
    ],
    [commonTableColumns]
  ) as OrganizationsTableColumn[];

  /**
   * Handlers
   */

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    if (!query) {
      setFilteredData(organizations);
      return;
    }

    const filtered = organizations.filter((item) => {
      const companyName = item?.businessName?.toLocaleLowerCase();
      const name = item?.name?.toLowerCase();
      const email = item?.email?.toLowerCase();
      return (
        (name && name.includes(query)) ||
        (email && email.includes(query)) ||
        (companyName && companyName.includes(query))
      );
    });

    setFilteredData(filtered);
  };

  /**
   * Effects
   */

  /**
   * Render
   */

  return (
    <div className="mt-4">
      {organizations?.length > 0 && (
        <Row className="justify-between my-2 align-center">
          <SearchInput onChange={handleFilter} placeholder="Search here..." />

          <Row className="align-center">
            <TextButton
              onClick={() => history.push("/invoice/add-manual-invoice")}
              className="hover-background-white p-1 mr-2 btn-disabled"
              icon={<AddInvoiceIcon />}
              text={"Add manual invoice"}
            />
            <CsvLinkButton data={organizations} fileName="driver-details" />
          </Row>
        </Row>
      )}

      <ClientPaginatedTable
        getRoutePath={id=>`/organizations/${id}`}
        columns={columns}
        data={filteredData}
        progressPending={isLoading}
      />
    </div>
  );
};

export default OrganizationsTable;
