import Card from "components/card/Card";
import DetailsCardBlock from "components/card/DetailsCard";
import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackH5Text0, BlackSubtitleText0 } from "components/text/Text";
import { useOrganizationDriverAsync } from "hooks/query/organization-query.hooks";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory, useParams } from "react-router";
import { getWhoPaysDisplayTextByPayerType } from "utils/charging.utils";
import { getLocalFormattedDateForTables } from "utils/date.utils";
import { getDriverFullName, getDriverStatusLabel, getDriverTypeLabel } from "utils/driver.utils";
import DriverDetailsPageAddressCard from "./DriverDetailsPageAddressCard";

type Props = {};

function OrganizationDriverDetailCards({}: Props) {
  const history = useHistory();
  const { organizationId, driverId } = useParams<{ organizationId: string; driverId: string }>();
  const { isLoading, driver } = useOrganizationDriverAsync();

  return (
    <ErrorBoundary FallbackComponent={() => <>An error ocurred..</>}>

      <Row>
        <Col lg="6" className="pr-lg-2 ">
          <Card>
            <Row className="mb-2">
              {isLoading ? <LoadingSkeleton count={1} /> : <BlackH5Text0>{getDriverFullName(driver)} </BlackH5Text0>}
            </Row>
            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="ID"
              leftTextOverwrite={<ClipboardWithCopyIcon TextComponent={BlackSubtitleText0} text={driverId ?? "-"} />}
            />
            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="Status"
              leftText={getDriverStatusLabel(driver?.active)}
              rightLabel="Added"
              rightText={getLocalFormattedDateForTables(driver?.createdAt)}
            />
            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="Email"
              leftTextOverwrite={
                <ClipboardWithCopyIcon TextComponent={BlackSubtitleText0} text={driver?.email ?? "-"} />
              }
              rightLabel="Phone number"
              rightText={driver?.phoneNumber}
            />
            <DetailsCardBlock
              isLoading={isLoading}
              leftLabel="Who pays"
              leftText={getWhoPaysDisplayTextByPayerType(driver?.payer)}
              rightLabel="Card status"
              rightText={"-"}
              isBorderBottomVisible={false}
            />
          </Card>
        </Col>

        <Col md={"6"}>
          <DriverDetailsPageAddressCard driver={driver} isLoading={isLoading} />
        </Col>

      </Row>
    </ErrorBoundary>
  );
}

export default OrganizationDriverDetailCards;
