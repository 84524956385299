import AlertConfirmDialog from "components/alert/AlertConfirmDialog";
import { useCallback, useState } from "react";
import { deleteOrganizationVehicleAsync } from "services/organization.services";
import PrimaryButton from "../../button/PrimaryButton";
import { useParams } from "react-router";
import { useAlertManager } from "../../../hooks/alert.hooks";
import { BlackBodyText100, BlackBodyText200 } from "../../text/Text";

type Props = {
  readonly refetch: () => Promise<void>;
  readonly isDisabled?: boolean
};

function OrganizationVehicleDetailsSoftDeleteButton({ refetch, isDisabled }: Props) {
  const { organizationId, vehicleId } = useParams<{ organizationId: string, vehicleId: string }>();


  const { handleOpenSuccessAlert } = useAlertManager();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsDialogOpen = () => setIsDialogOpen((is) => !is);

  const handleSoftDeleteVehicleAsync = useCallback(async () => {
    if (!organizationId || !vehicleId) {
      return;
    }

    try {
      setIsLoading(true);
      await deleteOrganizationVehicleAsync(organizationId, vehicleId);

      await refetch();
      handleOpenSuccessAlert("Vehicle is successfully soft deleted.");
      setIsDialogOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [handleOpenSuccessAlert, organizationId, refetch, vehicleId]);

  return (
    <>
      <AlertConfirmDialog
        title="Are you sure you want to soft delete this vehicle?"
        descriptionOverride={<div className={'h-max-100 overflow-auto'}>
          <BlackBodyText100 className={"mb-2"}>For a soft-deleted vehicle:</BlackBodyText100>
          <BlackBodyText200>
            &#x2022; Start charging is not possible. <br />
            &#x2022; Dashboard: Vehicle, user, and charging lists do not display it (related charging records should be
            shown with empty vehicle fields). <br />
            &#x2022; Backoffice: It appears as deleted in vehicle lists and is shown in charging, user, and assignment
            lists. <br />

            &#x2022; The same VIN can be added as a new vehicle in the same or different organization. <br />


            &#x2022; Backoffice: It appears as deleted in vehicle lists and is shown in charging, user, and assignment
            lists. <br />

            &#x2022; It is excluded from organization statistics but included in the platform fee invoice for the
            billing cycle when deleted. <br />

            &#x2022; No updates for Smartcar info, Tesla charging records, or Tesla vehicle info.<br />


            &#x2022; Tesla controls won't work.<br />

            &#x2022; Tesla and Geotab sync/connect: The vehicle is not added for the last owner’s organization but is
            added for others.<br />

            &#x2022; Geotab charging records are not updated.
          </BlackBodyText200>
        </div>}
        isDialogOpen={isDialogOpen}
        toggleIsDialogOpen={toggleIsDialogOpen}
        onDialogConfirmed={handleSoftDeleteVehicleAsync}
        isLoading={isLoading}
      />
      <PrimaryButton isDisabled={isDisabled} isLoading={isLoading} text={"Soft Delete"}
                     onClick={() => setIsDialogOpen(true)} />
    </>
  );
}

export default OrganizationVehicleDetailsSoftDeleteButton;
