import { Period } from "./filter.types";
import { PayerType } from "./payment.types";
import { Vehicle } from "./vehicle.types";

export enum Country {
  US = "US",
  CANADA = "CA"
}


export type Address = {
  street?: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: Country;
};


export type Driver = {
  id: string;
  type: string;
  email: string;
  phone_number: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  active: boolean;
  payer: PayerType;
  jobTitle: string;
  createdAt: string;
  updatedAt: string;
  activatedAt: string;
  expiredAt: string;
  limits: Limits;
  address: Address;
  departments: Department[];
  vehicles: Vehicle[];
}

export type Department = {
  id: string;
  name: string;
}

export type Limits = {
  id: string;
  chargingLimit: number;
}

export enum UserStatus {
  Active = "Active",
  Inactive = "Inactive"
}


export enum UserType {
  FIXED = "fixed",
  TEMPORARY = "temporary",
}


export type DriverTableFilters = {
  active?: {
    $in?: boolean[] | string[]
  },
  type?: {
    $eq: keyof typeof UserType
  },
  payer?: {
    $in: PayerType[]
  },
  createdAt?: {
    $within: Period,
    $gte: string,
    $lt: string
  },
  q?: {
    $eq: string
  }
}


export enum DriverTabType {
  INFO,
  ASSIGNMENTS
}