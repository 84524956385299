export function generateApiEndpoint(url) {
  return `${process.env.REACT_APP_API_URL}/${url}`;
}


export const handleApiErrorResponse = (err) => {
  let errorMessage = "";
  console.log('err',err)

  if (err.errors && Array.isArray(err.errors)) {
    for (const error of err.errors) {
      if (error.keyword === "required") {
        errorMessage = error.message;
        break;
      }
    }
  } else {
    errorMessage = err.response?.data?.message ?? 'Something went wrong';
  }

  return errorMessage;
};