import { useCallback, useMemo, useState } from "react";
import { DateSchedulePeriod, DateSchedulePeriodKey } from "../types/date.types";
import {DateTime} from 'luxon'
export const useDateSchedulePeriodSelector = () => {

  const defaultDateSchedulePeriod: DateSchedulePeriod = useMemo(() => {
    return {
      startsAt:DateTime.now().minus({ days: 10 }).toJSDate() as Date,
      endsAt:   DateTime.now().toJSDate() as Date,
    };
  }, []);
  const [dateSchedulePeriodSelector, setDateSchedulePeriodSelector] =
    useState(defaultDateSchedulePeriod);


  const handleSetDateSchedulePeriod = useCallback((key: DateSchedulePeriodKey, value?: Date) => {
    setDateSchedulePeriodSelector((s) => {
      return { ...s, [key]: value };
    });
  }, []);


  const handleResetState = useCallback(() => {
    setDateSchedulePeriodSelector(defaultDateSchedulePeriod);
  }, [defaultDateSchedulePeriod]);

  return {
    handleResetState,
    handleSetDateSchedulePeriod,
    dateSchedulePeriodSelector,
    setDateSchedulePeriodSelector
  };
};