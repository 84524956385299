const menu = [
  {
    icon: "grid",
    text: "homepage",
    permission: [],
    active: false,
    link: "/",
  },
  {
    icon: "user-alt",
    text: "fleetOrganizations",
    active: false,
    link: "/organizations",
  },
  {
    icon: "view-row-fill",
    text: "signupApplications",
    active: false,
    link: "/signup-applications",
  },
  /*   {
      icon: "account-setting",
      text: "driver_support",
      active: false,
      link: "/driver-support",
    }, */
];
export default menu;
