export enum AlertType {
    SUCCESS,
    ERROR,
    WARNING
}

export type AlertSliceState = {
    type: AlertType
    message: string,
    isOpen: boolean
}