import { CircularProgress } from "@mui/material";
import AlertFailIcon from "assets/icons/AlertFailIcon";
import AlertSuccessIcon from "assets/icons/AlertSuccessIcon";
import AlertConfirmDialog from "components/alert/AlertConfirmDialog";
import AddButton from "components/button/AddButton";
import BlackButton from "components/button/BlackButton";
import CancelButton from "components/button/CancelButton";
import TryAgainButton from "components/button/TryAgainButton";
import Card from "components/card/Card";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import ControllerValidationError from "components/form/ControllerValidationError";
import InputWithValidationControllerError from "components/form/InputWithValidationControllerError";
import SelectInput from "components/input/select/SelectInput";
import { BlackBodyText100, BlackCaptionText200, BlackH5Text0, BlackSubtitleText200 } from "components/text/Text";
import { useOrganizationsAsync } from "hooks/query/organization-query.hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Form, FormGroup } from "reactstrap";
import { addManualInvoiceAsync } from "services/invoice.services";
import { getOrganizationDriversAsync } from "services/organization.services";
import { Driver } from "types/driver.types";
import { InputType } from "types/form.types";
import { AddManualInvoicePayload, AudienceType } from "types/invoice.types";
import { getDriverFullName } from "utils/driver.utils";
import { getFormattedCentByUsdCreditString } from "utils/format.utils";
import { AddManualInvoiceAlertDialogStates } from "utils/invoice.utils";
import { getAllPaginatedListByLoopAsync } from "utils/organization.utils";
import AddManualInvoiceFormAudienceTypeSelector from "./AddManualInvoiceFormAudienceTypeSelector";

type Props = {};

type FormValues = {
  description: string;
  amount: string;
};

function AddManualInvoiceForm({}: Props) {
  const { register, handleSubmit, control, errors, trigger } = useForm<FormValues>();

  const [validationErrors, setValidationErrors] = useState({
    organization: "",
    driver: "",
  });

  const [alertDialogState, setAlertDialogState] = useState({
    isError: false,
    isOpen: false,
    message: "Invoice generated successfully!",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState<undefined | { label: string; value: string }>();
  const [selectedDriver, setSelectedDriver] = useState<undefined | { label: string; value: string }>();

  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [isDriversLoading, setIsDriversLoading] = useState(false);

  const [audienceType, setAudienceType] = useState(AudienceType.ORGANIZATION);
  const isAudienceTypeDriver = audienceType === AudienceType.DRIVER;

  const { isLoading, organizations } = useOrganizationsAsync();

  const organizationOptions = useMemo(() => {
    return organizations?.map((o) => {
      return {
        label: `${o.businessName} - ${o.email}`,
        value: o.id,
      };
    });
  }, [organizations]);

  const driverOptions = useMemo(() => {
    return drivers?.map((d) => {
      return {
        label: `${getDriverFullName(d)} - ${d.email}`,
        value: d.id,
      };
    });
  }, [drivers]);

  /**
   *
   * Handlers
   */

  useEffect(() => {
    setValidationErrors((v) => {
      return { driver: selectedDriver ? "" : v.driver, organization: selectedOrganization ? "" : v.organization };
    });
  }, [selectedDriver, selectedOrganization]);

  const handleCloseAlertDialog = () => setAlertDialogState({ ...alertDialogState, isOpen: false });

  const handleGetOrganizationDrivers = useCallback(async (organizationId: string) => {
    setIsDriversLoading(true);
    const allDrivers = await getAllPaginatedListByLoopAsync<Driver>((query) =>
      getOrganizationDriversAsync(organizationId, query)
    );
    setDrivers(allDrivers);
    setIsDriversLoading(false);
  }, []);

  useEffect(() => {
    if (!selectedOrganization || !isAudienceTypeDriver) {
      return;
    }
    handleGetOrganizationDrivers(selectedOrganization.value);
  }, [handleGetOrganizationDrivers, isAudienceTypeDriver, selectedOrganization]);

  const handleValidateSelectorsAndGetAudienceId = useCallback(() => {
    if (isAudienceTypeDriver) {
      if (!selectedDriver) {
        setValidationErrors((sve) => {
          return { ...sve, driver: "Driver is required" };
        });
        return;
      }

      return { audienceId: selectedDriver.value };
    }

    if (!selectedOrganization) {
      setValidationErrors((sve) => {
        return { ...sve, organization: "Organization is required" };
      });
      return;
    }

    return { audienceId: selectedOrganization.value };
  }, [isAudienceTypeDriver, selectedDriver, selectedOrganization]);

  const handleValidatedSubmit: SubmitHandler<FormValues> = useCallback(
    async (validData) => {
      const audienceIdAccessor = handleValidateSelectorsAndGetAudienceId();

      if (!audienceIdAccessor?.audienceId) {
        return;
      }

      trigger();

      try {
        const payload: AddManualInvoicePayload = {
          amount: getFormattedCentByUsdCreditString(validData.amount),
          description: validData.description,
          audienceId: audienceIdAccessor.audienceId,
          audienceType,
        };

        setIsSubmitting(true);

        await addManualInvoiceAsync(payload);
        setAlertDialogState(AddManualInvoiceAlertDialogStates.success);
      } catch (error) {
        console.error(error);
        setAlertDialogState(AddManualInvoiceAlertDialogStates.failed);
      } finally {
        setIsSubmitting(false);
      }
    },
    [audienceType, handleValidateSelectorsAndGetAudienceId, trigger]
  );

  if (isLoading) {
    return (
      <div className="center w-100 h-400px">
        <CircularProgress size={48} color={"primary"} />
      </div>
    );
  }

  return (
    <div className="w-100  center">
      <Card padding="p-4" className={"mb-5 wide-md-fix"}>
        <BlackH5Text0>Add Invoice Manually</BlackH5Text0>
        <BlackBodyText100>
          Use this page to create manual invoices for adjustments in platform fees or charging fees. When you create a
          new invoice for an organization or driver, it will appear under their account labeled as 'Debit Invoice'.{" "}
        </BlackBodyText100>
        <Form className="w-100 mt-2" onSubmit={handleSubmit(handleValidatedSubmit)}>
          <Col className="mt-1">
            <BlackSubtitleText200>Customer Type</BlackSubtitleText200>

            <AddManualInvoiceFormAudienceTypeSelector
              audienceType={AudienceType.ORGANIZATION}
              onChange={setAudienceType}
              selectedAudience={audienceType}
              label="Organization"
            />

            <AddManualInvoiceFormAudienceTypeSelector
              audienceType={AudienceType.DRIVER}
              onChange={setAudienceType}
              selectedAudience={audienceType}
              label="Driver"
            />
          </Col>
          <Col md="12" className="mt-4">
            <FormGroup>
              <BlackSubtitleText200 className="mb-2">Organization</BlackSubtitleText200>
              <SelectInput
                // inputRef={register({ required: "required" })}
                //defaultValue={vehicles.data}
                noOptionsMessage={() => "No organizations"}
                name={"organization"}
                placeholder={"Select organization"}
                options={organizationOptions}
                value={selectedOrganization}
                onChange={setSelectedOrganization}
              />
              <ControllerValidationError error={validationErrors.organization} />
            </FormGroup>
          </Col>
          {isAudienceTypeDriver && (
            <Col md="12" className="mt-4">
              <FormGroup>
                <BlackSubtitleText200 className="mb-2">Driver</BlackSubtitleText200>
                <SelectInput
                  // inputRef={register({ required: "required" })}
                  //defaultValue={vehicles.data}
                  isDisabled={!selectedOrganization || isDriversLoading}
                  noOptionsMessage={() => "No drivers"}
                  name={"drivers"}
                  placeholder={isDriversLoading ? "Drivers loading..." : "Select driver"}
                  options={driverOptions}
                  value={selectedDriver}
                  onChange={setSelectedDriver}
                />
                <ControllerValidationError error={validationErrors.driver} />
              </FormGroup>
            </Col>
          )}
          <Col className="mt-4">
            <BlackSubtitleText200>Item Description</BlackSubtitleText200>
            <BlackCaptionText200>
              Provide a brief description of the item or service for which this invoice is being created. This
              description will appear on the invoice.
            </BlackCaptionText200>
            <FormGroup className="mt-2">
              <InputWithValidationControllerError
                name="description"
                as="input"
                control={control}
                className="form-control"
                placeholder="Enter the item desc."
                errors={errors}
                register={register}
              />
            </FormGroup>

            <FormGroup className="mt-2">
              <InputWithValidationControllerError
                register={register}
                name="amount"
                inputLabel="Amount"
                control={control}
                className="form-control"
                placeholder="Enter the amount"
                errors={errors}
                defaultValue={undefined}
                inputType={InputType.NumberByDollar}
                rules={{
                  required: `Amount is required.`,
                  pattern: {
                    value: /^\$?\s*([1-9]\d{0,2}(,\d{3})*(\.\d+)?)\s*$/,
                    message: "Amount must be greater than 0.",
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Row className="w-100 d-flex justify-content-end align-items-center mt-4 pt-4">
            <div className="ml-3">
              <AddButton isLoading={isSubmitting} />
            </div>
          </Row>

          <AlertConfirmDialog
            icon={alertDialogState.isError ? <AlertFailIcon /> : <AlertSuccessIcon />}
            isDialogOpen={alertDialogState.isOpen}
            title={alertDialogState.message}
            overrideButtons={
              alertDialogState.isError ? (
                <>
                  <CancelButton type="button" onClick={handleCloseAlertDialog} />
                  <TryAgainButton onClick={handleSubmit(handleValidatedSubmit)} isLoading={isSubmitting} />
                </>
              ) : (
                <>
                  <BlackButton onClick={() => (window.location.href = "/organizations")} text="Got it" />
                </>
              )
            }
          />
        </Form>
      </Card>
    </div>
  );
}

export default AddManualInvoiceForm;
