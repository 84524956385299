import { useMemo } from "react";
import { useParams } from "react-router-dom";

import Row from "components/custom/Row";
import FilterSearchInput from "components/filter/FilterSearchInput";
import FilterableTableCsvLinkButton from "components/table/FilterableTableCsvLinkButton";
import ServerPaginatedTable from "components/table/ServerPaginatedTable";
import { BlackH5Text100 } from "components/text/Text";
import { useOrganizationChargingListAsync } from "hooks/query/organization-query.hooks";
import { useCommonTableColumns } from "hooks/table.hooks";
import { TableColumn } from "react-data-table-component";
import { getOrganizationChargingListAsync } from "services/organization.services";
import { Charging } from "types/charging.types";
import { getChargingSessionRowForCsv } from "utils/charging.utils";
import ChargingFilterMenu from "./charging/filter/ChargingFilterMenu";
import { useHistory } from "react-router";

export type OrganizationsChargingListTableColumn = TableColumn<Charging>[];

function OrganizationChargingListTable() {
  const { organizationId } = useParams<{ organizationId: string }>();

  const history = useHistory();

  const { isLoading, list, meta } = useOrganizationChargingListAsync();

  const commonTableColumns = useCommonTableColumns();

  const columns = useMemo(
    () => [
      commonTableColumns.organizationDriver,
      commonTableColumns.organizationVehicle,
      commonTableColumns.chargingState,
      commonTableColumns.source,
      commonTableColumns.chargerName,
      commonTableColumns.type,
      commonTableColumns.energy,
      commonTableColumns.cost,
      commonTableColumns.payStat,
      commonTableColumns.whoPays,
      commonTableColumns.duration,
      commonTableColumns.date,
      commonTableColumns.action
    ],
    [commonTableColumns]
  ) as OrganizationsChargingListTableColumn;

  return (
    <>
      <BlackH5Text100 tag="h4" className={"my-3"}>
        Charging Sessions
      </BlackH5Text100>

      <Row className="justify-between my-2 align-center">
        <FilterSearchInput placeholder="Search charging (min 2 char.)" />

        <FilterableTableCsvLinkButton
          fileName="organization-charging-list"
          csvRowFormatter={getChargingSessionRowForCsv}
          totalPages={meta?.pagination.totalPages ?? 0}
          fetchListAsync={(query) => getOrganizationChargingListAsync(organizationId ?? "", query)}
        />
      </Row>

      <ChargingFilterMenu />

      <ServerPaginatedTable
        getRoutePath={id=> `/organizations/${organizationId}/charging/${id}`}
        paginationTotalRows={meta?.pagination.totalElements}
        columns={columns}
        data={list}
        isLoading={isLoading}
      />
    </>
  );
}

export default OrganizationChargingListTable;
