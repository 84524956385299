import React from "react";
import BlackButton, { BlackButtonWithIconProps } from "./BlackButton";

type Props = BlackButtonWithIconProps;

function TryAgainButton(props: Props) {
  return <BlackButton {...props} text="Try Again" />;
}

export default TryAgainButton;
