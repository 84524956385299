import React from "react";

import Col from "components/custom/Col";
import Row from "components/custom/Row";
import VehicleDetailsDriverAssignment from "./VehicleDetailsDriverAssignment";


import VehicleDetailsVehicleDriverScheduleList from "./VehicleDetailsVehicleDriverScheduleList";

import { getCategorizedSchedules } from "../../utils/vehicle-driver-schedule.utils";
import Card from "../card/Card";
import { BlackBody2Text200, BlackSubtitleText100 } from "../text/Text";
import { useVehicleDriverSchedulesAsync } from "../../hooks/query/vehicle-driver-schedule-query.hooks";
import OldVehicleDriverAssignmentsModal from "components/vehicle-driver-schedule/OldVehicleDriverAssignmentsModal";

type Props = {
  // readonly vehicleHook: VehicleDataAccessorHook;
//  readonly teslaHook: TeslaDataAccessorHook;

}

function VehicleDetailsAssignmentsContent({}: Props): JSX.Element {
  const vehicleDriverSchedulesHook = useVehicleDriverSchedulesAsync();


  const { schedules, isLoading: isSchedulesLoading } = vehicleDriverSchedulesHook;


  const { activeSchedules, plannedSchedules } = getCategorizedSchedules(schedules);


  return (
    <Row>
      <Col lg={4} className="pr-lg-3">

        <BlackSubtitleText100>Current assignment</BlackSubtitleText100>

        <Card className={"mt-4"}>
          <VehicleDetailsDriverAssignment
            vehicleDriverSchedulesHook={vehicleDriverSchedulesHook}
          />
        </Card>


        <OldVehicleDriverAssignmentsModal />


      </Col>
      <Col lg={8} className="pl-lg-3">

        <BlackSubtitleText100 className={"mb-2"}>Current schedule</BlackSubtitleText100>

        <VehicleDetailsVehicleDriverScheduleList isLoading={isSchedulesLoading}

                                                 schedules={activeSchedules} />


        <BlackSubtitleText100 className={"mt-4"}>Scheduled assignments</BlackSubtitleText100>
        <BlackBody2Text200 className={"mb-2"}>The driver will be automatically assigned to the vehicle on the start
          date.</BlackBody2Text200>

        <VehicleDetailsVehicleDriverScheduleList isLoading={isSchedulesLoading}
                                                 schedules={plannedSchedules} />


      </Col>


    </Row>
  );
}

export default VehicleDetailsAssignmentsContent;