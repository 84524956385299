import VehiclesTable from "components/organization/vehicle/OrganizationVehiclesTable";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";

const OrganizationVehiclesPage = () => {
  return (
    <React.Fragment>
      <Head title={"Vehicles"} />
      <Content>
        <ErrorBoundary fallback={<></>}>
          <VehiclesTable />
        </ErrorBoundary>
      </Content>
    </React.Fragment>
  );
};

export default OrganizationVehiclesPage;
