import NoResultPlaceholder from "assets/images/placeholder/NoResultPlaceholder.png";
import { BlackBodyText200, BlackSubtitleText100 } from "components/text/Text";
import React from "react";

type Props = {
  readonly img?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  readonly title?: string;
  readonly description?: string;
};

function TableNoResultPlaceholder(props: Props) {
  const img = props.img ?? <img width={250} src={NoResultPlaceholder} alt="No result placeholder" />;
  const title = props.title ?? "No results found";
  const description = props.description ?? "Try different keywords or adjust your filters.";
  return (
    <div className="w-100 center h-400px flex-column">
      <>{img}</>
      <BlackSubtitleText100 className="mt-4">{title}</BlackSubtitleText100>
      <BlackBodyText200 className="mt-1 px-2 w-max-100 text-center">{description}</BlackBodyText200>
    </div>
  );
}

export default TableNoResultPlaceholder;
