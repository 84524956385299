import React from "react";
import { Link } from "react-router-dom";


export enum TextButtonMode {
  PRIMARY,
  BLUE,
  NEGATIVE
}

export type TextButtonProps = {
  readonly icon?: React.JSX.Element;
  readonly text?: string;
  readonly textOverride?: React.JSX.Element;
  readonly onClick?: () => void;
  readonly className?: string;
  readonly textClassName?: string;
  readonly mode?: TextButtonMode
  readonly href?: string
};

export default function TextButton({
                                     icon,
                                     text,
                                     onClick,
                                     className,
                                     textClassName,
                                     textOverride,
                                     href
                                   }: TextButtonProps) {

  const child = <>      {icon && <div className="mr-1">{icon}</div>}
    {textOverride ?? <span className={`font-weight-normal ${textClassName ?? ""}`}>{text}</span>}</>;


  const classNameValue = `d-inline-flex pointer-events-auto  align-center justify-center rounded-xxl cursor-pointer ${className}`;


  if (href) {
    return <Link to={href} className={classNameValue}>
      {child}
    </Link>;
  }

  return (
    <span
      className={classNameValue}
      onClick={onClick}
    >
        {child}
    </span>
  );
}
