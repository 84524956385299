import { useCallback, useEffect } from "react";
import { closeAlert, setAlert } from "redux/slices/alertSlice";
import { AlertType } from "types/alert.types";
import { useAppDispatch, useAppSelector } from "./redux.hooks";

export const useAlertManager = () => {
  const dispatch = useAppDispatch();

  const handleOpenSuccessAlert = useCallback(
    (message: string) => {
      dispatch(setAlert({ type: AlertType.SUCCESS, message }));
    },
    [dispatch]
  );

  const handleOpenErrorAlert = useCallback(
    (message: string) => {
      dispatch(setAlert({ type: AlertType.WARNING, message }));
    },
    [dispatch]
  );


  const handleCloseAlert = useCallback(
    () => {
      dispatch(closeAlert());
    },
    [dispatch]
  );

  const handleShowAlertByType = useCallback(
    (isSuccess: boolean, message: string) => {
      if (isSuccess) {
        handleOpenSuccessAlert(message);
      } else {
        handleOpenErrorAlert(message);
      }
    },
    [handleOpenErrorAlert, handleOpenSuccessAlert]
  );


  return {
    ...useAppSelector(s => s.alertSlice),
    handleOpenSuccessAlert,
    handleOpenErrorAlert,
    handleCloseAlert,
    handleShowAlertByType
  };
};


export const useApiErrorHandlerProvider = () => {
  const { handleOpenErrorAlert } = useAlertManager();

  useEffect(() => {
    const handleErrorEvent = (event) => {
      console.log('event',event)
      handleOpenErrorAlert(event.detail);
    };

    // Event listener ekleniyor
    window.addEventListener("api-error", handleErrorEvent);

    // Cleanup function
    return () => {
      window.removeEventListener("api-error", handleErrorEvent);
    };
  }, [handleOpenErrorAlert]);

  return null;
};

