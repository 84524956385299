import React from "react";
import LogoSmall from "../../images/logo-small.png";
import xLogo from "../../images/blueboard-fleet-logo.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <>
      <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
        <img width={200} className="logo-light logo-img" src={xLogo} alt="logo" />
        <img style={{ maxHeight: '55px', top: '70%' }} className="logo-dark logo-img" src={xLogo} alt="logo" />
        <img className="logo-small logo-img logo-img-small" src={LogoSmall} alt="logo" />
      </Link>
    </>
  );
};

export default Logo;
