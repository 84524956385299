import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackBodyText200, BlackH5Text100 } from "components/text/Text";
import Col from "../custom/Col";
import Row from "../custom/Row";

type Props = {
  readonly title?: string;
  readonly description?: string;
  readonly rightElement?: JSX.Element;
  readonly isLoading?: boolean;
  readonly titleOverwrite?: JSX.Element;
};

export default function PageHeader({ title, titleOverwrite, description, rightElement, isLoading }: Props) {
  if (isLoading) {
    return (
      <Row className="w-60">
        <LoadingSkeleton height={20} count={2} />
      </Row>
    );
  }
  return (
    <Row className="flex-wrap align-center justify-between my-1">
      <Col>
        {titleOverwrite ?? <BlackH5Text100>{title}</BlackH5Text100>}
        <BlackBodyText200 className="mt-1">{description}</BlackBodyText200>
      </Col>
      {rightElement && <div className="mt-2 mt-md-0">{rightElement}</div>}
    </Row>
  );
}
