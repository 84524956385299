import React from "react";
import Row from "../custom/Row";
import BluedotInAppIcon from "assets/icons/BluedotInAppIcon";
import HomeIcon from "assets/icons/HomeIcon";
import OtherNetworkIcon from "assets//icons/OtherNetworkIcon";
import TeslaIcon from "assets/icons/TeslaIcon";
import HorizontalLine from "components/line/HorizontalLine";
import { BlackBodyText100, BlackBodyText200, BlackSubtitleText0 } from "../text/Text";

type Props = {};

export default function ChargingSessionsSourceInfoTooltipContent({}: Props) {
  return (
    <div className="w-200px p-1">
      <BlackBodyText200 className="mb-1">Source:</BlackBodyText200>
      <Row className="justify-between align-items-center">
        <BlackBodyText100>Home charging</BlackBodyText100>
        <HomeIcon />
      </Row>
      <HorizontalLine />
      <Row className="justify-between align-items-center">
        <BlackBodyText100>Bluedot In-App</BlackBodyText100>
        <BluedotInAppIcon />
      </Row>
      <HorizontalLine />
      <Row className="justify-between align-items-center">
        <BlackBodyText100>Tesla Supercharger</BlackBodyText100>
        <TeslaIcon />
      </Row>
      <HorizontalLine />
      <Row className="justify-between align-items-center">
        <BlackBodyText100>Others</BlackBodyText100>
        <OtherNetworkIcon />
      </Row>
    </div>
  );
}
