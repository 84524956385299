import Col from "components/custom/Col";
import Row from "components/custom/Row";
import SearchInput from "components/input/SearchInput";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
  UncontrolledDropdown
} from "reactstrap";
import { getLocalFormattedDateAsDateShort } from "utils/date.utils";
import { getOrganizationStatusContent } from "utils/organization.utils";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { getOrganizationsByStatusAsync, updateApplication } from "../../services/organization.services";
import { useAlertManager } from "../../hooks/alert.hooks";
import { handleApiErrorResponse } from "../../utils";

const SignupApplications = () => {
  const { handleOpenErrorAlert, handleOpenSuccessAlert } = useAlertManager();
  const { t } = useTranslation("common");
  const [formData, setFormData] = useState();
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name ?? "-"
    },
    {
      name: "Business Name",
      selector: (row) => row.businessName ?? "-"
    },
    {
      name: "Email",
      selector: (row) => row.email ?? "-"
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber ?? "-"
    },
    {
      name: "Employer Identification Number",
      selector: (row) => row.employerIdentificationNumber ?? "-"
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          className={row.status == "PENDING_REVIEW" ? "badge badge-dot text-warning" : " badge badge-dot text-danger"}>
          {getOrganizationStatusContent(row.status).label}
        </span>
      )
    },
    {
      name: "Date",
      selector: (row) => getLocalFormattedDateAsDateShort(row.createdAt)
    },
    {
      name: "Actions",
      cell: (row) => (
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                <Icon name="more-h" />
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setFormData(row);
                        setModal({ approve: true });
                      }}
                    >
                      <Icon name="check" />
                      <span>Approve</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setFormData(row);
                        setModal({ reject: true });
                      }}
                    >
                      <Icon name="cross-circle" />
                      <span>Reject</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      )
    }
  ];
  const [alertData, setAlertData] = useState({
    status: false,
    type: "info",
    icon: "alert-circle",
    message: t("error.unknown")
  });
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [modal, setModal] = useState({});
  const [loading, setLoading] = useState();

  const loadData = async () => {
    try {
      setLoading(true);
      const customers = await getOrganizationsByStatusAsync("PENDING_REVIEW");
      setData(customers.data);
      setFilteredData(customers.data);

    } catch (err) {
      handleOpenErrorAlert(handleApiErrorResponse((err)));
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleApplicationUpdate = async (status) => {
    try {
      const isApproveCommand = status === "approve";
      const successMessage = isApproveCommand ? "Application approved succesfully!" : "Application rejected succesfully!"

      console.log('successMessage',successMessage)
      await updateApplication(formData.id, status);
      handleOpenSuccessAlert(successMessage);
      loadData();
    } catch (err) {
      handleOpenErrorAlert(handleApiErrorResponse((err)));
      console.error(err);
    }finally{
      onFormCancel();
    }
  };

  const onFormCancel = async () => {
    setModal({});
  };

  const handleFilter = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = data.filter((item) => {
      return item.name.toLowerCase().includes(query);
    });
    setFilteredData(filtered);
  };

  return (
    <React.Fragment>
      <Head title={"Signup Applications"} />
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="mt-2">
            <BlockHeadContent>
              <BlockTitle tag="h5">Signup Applications</BlockTitle>
              <BlockDes className="text-soft">
                {t("fleet_management.total_users", { count: data?.length ?? "0" })}
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {alertData.status && (
          <Block className={"mb-2"}>
            <BlockHeadContent>
              <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
                <Icon name={alertData.icon ?? "cross-circle"} />
                {alertData.message ?? t("error.unknown")}
              </UncontrolledAlert>
            </BlockHeadContent>
          </Block>
        )}
        <div>
          <Row className="mb-2">
            <SearchInput onChange={handleFilter} placeholder="Search here..." />
          </Row>
          {loading ? (
            <Skeleton className="mt-3" height={30} count={10} />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </div>
        <Modal
          isOpen={modal.approve || modal.reject}
          toggle={() => onFormCancel()}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalHeader>Confirm Application Decision</ModalHeader>
          <ModalBody>
            This operation cannot be undone, are you sure you want to{" "}
            {modal.approve ? "approve" : modal.reject ? "reject" : ""} this application?
          </ModalBody>
          <ModalFooter>
            <Col size="12">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  <Button
                    onClick={() => {
                      modal.approve
                        ? handleApplicationUpdate("approve")
                        : modal.reject
                          ? handleApplicationUpdate("reject")
                          : void 0;
                    }}
                    color="primary"
                    size="md"
                    type="submit"
                  >
                    Continue
                  </Button>
                </li>
                <li>
                  <a
                    href="#cancel"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onFormCancel();
                    }}
                    className="link link-light"
                  >
                    {t("operations.cancel")}
                  </a>
                </li>
              </ul>
            </Col>
          </ModalFooter>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default SignupApplications;
