import DashboardTablePlaceholderIcon from "assets/icons/DashboardTablePlaceholder";
import { BlackBodyText300 } from "components/text/Text";

export default function TablePlaceholder({ button }: { button?: JSX.Element }) {
  return (
    <div className="center h-100 flex-column h-min-400px p-4 my-4">
      <DashboardTablePlaceholderIcon />
      <BlackBodyText300 className={"my-3"}>No data to display</BlackBodyText300>
      {button && <div className="my-2">{button}</div>}
    </div>
  );
}
