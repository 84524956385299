import { useCallback, useMemo } from "react";

import { CircularProgress } from "@mui/material";
import ServerPaginatedTable from "components/table/ServerPaginatedTable";
import { useOrganizationInvoicesAsync } from "hooks/query/organization-query.hooks";
import { useCommonTableColumns } from "hooks/table.hooks";
import { TableColumn } from "react-data-table-component";
import { Invoice, InvoiceType } from "types/invoice.types";
import { useStripeInvoiceUrlAccessor } from "../../hooks/invoice.hooks";
import { useParams } from "react-router";

export type OrganizationsChargingListTableColumn = TableColumn<Invoice>[];

function OrganizationInvoicesTable() {
  const { handleForwardToStripeAsync, isForwarding } = useStripeInvoiceUrlAccessor();
  const { organizationId } = useParams<{ organizationId: string }>();
  const { list: invoices, meta, isLoading } = useOrganizationInvoicesAsync();

  const commonTableColumns = useCommonTableColumns();

  const columns = useMemo(
    () => [
      commonTableColumns.invoiceType,
      commonTableColumns.invoiceDescription,
      commonTableColumns.date,
      commonTableColumns.dueDate,
      commonTableColumns.invoiceId,
      commonTableColumns.invoiceAmount,
      commonTableColumns.invoicePaymentStatus,
      commonTableColumns.action
    ],
    [commonTableColumns]
  ) as OrganizationsChargingListTableColumn;


  const handleRouting = useCallback(
    (id: string) => {
      if (isLoading) return;

      const invoice = invoices.find((invoice) => invoice.id === id);

      if (!invoice) {
        return;
      }

      const isDebitInvoice = invoice.invoiceType === InvoiceType.DEBIT;
      if (!isDebitInvoice) {
        return `/organizations/${organizationId}/invoices/${id}`;
      }
    },
    [invoices, isLoading, organizationId]
  );


  if (isForwarding) {
    return (
      <div className="center w-100 h-400px">
        <CircularProgress size={48} color={"primary"} />
      </div>
    );
  }

  return (
    <div className="mt-4">
      <ServerPaginatedTable
        onRowClicked={invoice => handleForwardToStripeAsync(invoice?.id)}
        getRoutePath={handleRouting}
        paginationTotalRows={meta?.pagination.totalElements}
        columns={columns}
        data={invoices}
        isLoading={isLoading}
      />
    </div>
  );
}

export default OrganizationInvoicesTable;
