import Box from "@mui/material/Box";
import * as React from "react";
import { StyledTab, StyledTabs } from "../tab/StyledTab";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";


export const vehicleDetailsRoutes = {
  overview: {
    ix: 0,
    path: "overview",
    label: "Overview",
    isTeslaRelated: false
  },
  assignments: {
    ix: 1,
    path: "assignments",
    label: "Assignments",
    isTeslaRelated: false
  }
};

type Props = {};

export default function VehiclePageTab({}: Props) {
  const history = useHistory();
  const { vehicleId,organizationId } = useParams<{ vehicleId: string ,organizationId:string}>();
  const { pathname } = useLocation();
  const routeValues = Object.values(vehicleDetailsRoutes);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const route = routeValues.find(r => r.ix === newValue);

    history.replace(`/organizations/${organizationId}/vehicles/${vehicleId}/${route?.path}`);
  };

  const currentPath = routeValues.find(r => pathname.includes(r.path));

  return (
    <Box className={"mb-4"}>
      <StyledTabs value={currentPath?.ix ?? 0} onChange={handleChange}>
        <StyledTab label="Vehicle Info" />
        <StyledTab label="Vehicle Assigments" />
      </StyledTabs>
    </Box>
  );
}
