import { SvgProps } from "types/svg.types";

export default function XCircleIcon(props: SvgProps) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.99981 17.3346C13.6024 17.3346 17.3336 13.6034 17.3336 9.00079C17.3336 4.39816 13.6024 0.666992 8.99981 0.666992C4.39718 0.666992 0.666016 4.39816 0.666016 9.00079C0.666016 13.6034 4.39718 17.3346 8.99981 17.3346ZM10.8855 9.11906C10.8204 9.05397 10.8204 8.94843 10.8855 8.88334L12.8302 6.93868C12.9929 6.77595 12.9929 6.51212 12.8302 6.34939L11.6516 5.17082C11.4889 5.00809 11.225 5.00809 11.0623 5.17082L9.11765 7.11548C9.05256 7.18057 8.94702 7.18057 8.88193 7.11548L6.93727 5.17082C6.77455 5.00809 6.51071 5.00809 6.34798 5.17082L5.16941 6.34939C5.00668 6.51212 5.00668 6.77595 5.16941 6.93868L7.11407 8.88334C7.17916 8.94843 7.17916 9.05397 7.11407 9.11906L5.16942 11.0637C5.0067 11.2264 5.0067 11.4903 5.16942 11.653L6.348 12.8316C6.51073 12.9943 6.77456 12.9943 6.93729 12.8316L8.88193 10.8869C8.94702 10.8218 9.05256 10.8218 9.11765 10.8869L11.0623 12.8316C11.225 12.9943 11.4889 12.9943 11.6516 12.8316L12.8302 11.653C12.9929 11.4903 12.9929 11.2264 12.8302 11.0637L10.8855 9.11906Z"
        fill={props.fill ?? "#C4003E"}
      />
    </svg>
  );
}
