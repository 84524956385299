import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";

import { dashboardService } from "services";
import Skeleton from "react-loading-skeleton";

const Homepage = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const { t } = useTranslation("common");
  const [data, setData] = useState({
    userCount: 0,
    approvedOrganizationCount: 0,
    charging: {
            "count": 0,
            "totalCost": 0,
            "totalEnergy": 0
      },
  });
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState();
  const [energyGraphData, setEnergyGraphData] = useState();

  const loadData = async () => {
    try {
      setLoading(true);
      const monthlyGraphData = await dashboardService.getDashboardData();
      setData(monthlyGraphData.data)
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <React.Fragment>
      <Head title={t("main_menu.homepage")} />
      <Content>
        <Col className="mb-4">
          <div className="row g-gs">
            <div className="col-sm-4 col-lg-12 col-xxl-4">
              <div className="card">
                <div className="card-inner">
                  <div className="card-title-group align-start mb-2">
                    <div className="card-title">
                      <h6 className="title">Number of Charging Sessions</h6>
                    </div>
                  </div>
                  {loading ? (
                    <Skeleton height={52} count={1} />
                  ) : (
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount"> {data?.charging?.count}</span>
                      </div>
                      <div className="nk-sales-ck"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-12 col-xxl-4">
              <div className="card">
                <div className="card-inner">
                  <div className="card-title-group align-start mb-2">
                    <div className="card-title">
                      <h6 className="title">Total Revenue</h6>
                    </div>
                  </div>
                  {loading ? (
                    <Skeleton height={52} count={1} />
                  ) : (
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">{(Number(data?.charging?.totalCost) / 100)?.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        })}</span>
                      </div>
                      <div className="nk-sales-ck"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-12 col-xxl-4">
              <div className="card">
                <div className="card-inner">
                  <div className="card-title-group align-start mb-2">
                    <div className="card-title">
                      <h6 className="title">kWh Delivered</h6>
                    </div>
                  </div>
                  {loading ? (
                    <Skeleton height={52} count={1} />
                  ) : (
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">{data?.charging?.totalEnergy?.toLocaleString('en-US')}</span>
                      </div>
                      <div className="nk-sales-ck"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <div className="row g-gs px-2 mb-3">
          {/*           <div className="col-lg-8">
            <PreviewAltCard>
             <ChargingCostGraph loading={loading} totalCostSum={data?.totalCostSum && (data?.totalCostSum).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} graphData={graphData} />
            </PreviewAltCard>
          </div> */}
        </div>
        <div className="row g-gs px-2 mx-3">
          {/*           <div className="col-lg-8">
            <PreviewAltCard>
              <TotalEnergyGraph loading={loading} totalEnergySum={data?.totalEnergyDelivered && (data?.totalEnergyDelivered).toLocaleString('en-US')} graphData={energyGraphData} />
            </PreviewAltCard>
          </div> */}
          <div className="col-sm-4 col-lg-4 col-xxl-4 mb-3">
            <div className="card">
              <div className="card-inner">
                <div className="card-title-group align-start mb-2">
                  <div className="card-title">
                    <h6 className="title">Approved Organizations</h6>
                  </div>
                </div>
                {loading ? (
                  <Skeleton height={52} count={1} />
                ) : (
                  <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                    <div className="nk-sale-data">
                      <span className="amount">{data?.approvedOrganizationCount?.toLocaleString('en-US')}</span>
                    </div>
                    <div className="nk-sales-ck"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-lg-4 col-xxl-4 mb-3">
            <div className="card">
              <div className="card-inner">
                <div className="card-title-group align-start mb-2">
                  <div className="card-title">
                    <h6 className="title">Pending Organizations</h6>
                  </div>
                </div>
                {loading ? (
                  <Skeleton height={52} count={1} />
                ) : (
                  <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                    <div className="nk-sale-data">
                      <span className="amount">{data?.pendingOrganizationCount?.toLocaleString('en-US')}</span>
                    </div>
                    <div className="nk-sales-ck"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-lg-4 col-xxl-4">
            <div className="card">
              <div className="card-inner">
                <div className="card-title-group align-start mb-2">
                  <div className="card-title">
                    <h6 className="title">Total Drivers</h6>
                  </div>
                </div>
                {loading ? (
                  <Skeleton height={52} count={1} />
                ) : (
                  <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                    <div className="nk-sale-data">
                      <span className="amount">{data?.userCount?.toLocaleString('en-US')}</span>
                    </div>
                    <div className="nk-sales-ck"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-lg-4 col-xxl-4 mb-3">
          <div className="card">
            <div className="card-inner">
              <div className="card-title-group align-start mb-2">
                <div className="card-title">
                  <h6 className="title">Total Vehicles</h6>
                </div>
              </div>
              {loading ? (
                <Skeleton height={52} count={1} />
              ) : (
                <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                  <div className="nk-sale-data">
                    <span className="amount">{data?.vehicleCount?.toLocaleString('en-US')}</span>
                  </div>
                  <div className="nk-sales-ck"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*         <PreviewAltCard className={"mt-4"}>
          <ChargingSessions />
        </PreviewAltCard> */}
      </Content>
    </React.Fragment>
  );
};

export default Homepage;
