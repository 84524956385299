import React from "react";
import { VehicleDriverSchedule } from "types/vehicle-driver-schedule.types";

import VehicleDriverScheduleCard from "../vehicle-driver-schedule/VehicleDriverScheduleCard";
import LoadingSkeleton from "../loading/LoadingSkeleton";

type Props = {
  schedules: VehicleDriverSchedule[];
  isLoading: boolean
};

function VehicleDetailsVehicleDriverScheduleList({ schedules, isLoading }: Props) {
  if (isLoading) {
    return <LoadingSkeleton count={4} height={45} />;
  }

  return (
    <div>

      {schedules.length === 0 ? (
        "-"
      ) : (
        <div className="h-max-475px  overflow-auto">
          {schedules.map((schedule, ix) => (
            <VehicleDriverScheduleCard
              ix={ix}
              schedule={schedule}
              isForDriver
            />
          ))}
        </div>
      )}

    </div>
  );
}

export default VehicleDetailsVehicleDriverScheduleList;
