import FilterMenuCheckbox from "components/filter/FilterMenuCheckbox";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import { useCallback, useEffect, useState } from "react";

import FilterEditorCard from "components/filter/FilterEditorCard";

import { DriverTableFilters } from "types/driver.types";
import { PayerType } from "types/payment.types";
import { getPayerLabelByType } from "utils/driver.utils";

type Props = {};

function FilterDriverPayerStatusSelector(props: Props) {
  const { filterQuery, setFilterQuery } = useFilterUrlQuery<DriverTableFilters>();
  const [selectedPayers, setSelectedPayers] = useState<PayerType[]>([]);
  const [variationCount, setVariationCount] = useState(0);

  useEffect(() => {
    setSelectedPayers(filterQuery?.payer?.$in ?? []);
    setVariationCount(Math.min(filterQuery?.payer?.$in?.length ?? 0, 2));
  }, [filterQuery]);

  const isSelectedPayer = useCallback(
    (payer: PayerType) => {
      return selectedPayers.includes(payer);
    },
    [selectedPayers]
  );

  const handlePayerTypesToggled = useCallback(
    (payer: PayerType) => {
      if (isSelectedPayer(payer)) {
        setSelectedPayers(selectedPayers.filter((t) => t !== payer));
        return;
      }
      setSelectedPayers([...selectedPayers, payer]);
    },
    [isSelectedPayer, selectedPayers]
  );

  const handleSetPayerTypeOnQuery = useCallback(
    (selectedPayers: PayerType[], isUndefined: boolean) => {
      setFilterQuery((q) => {
        return {
          payer: isUndefined
            ? undefined
            : {
              $in: selectedPayers,
            },
        };
      });
    },
    [setFilterQuery]
  );

  const handleClearButtonPressed = useCallback(() => {
    setSelectedPayers([]);
    handleSetPayerTypeOnQuery([], true);
    setVariationCount(0);
  }, [handleSetPayerTypeOnQuery]);

  const handleApplyButtonPressed = useCallback(() => {
    handleSetPayerTypeOnQuery(selectedPayers, selectedPayers.length === 0);
  }, [handleSetPayerTypeOnQuery, selectedPayers]);

  return (
    <FilterEditorCard
      className="my-1"
      containerClassName="ml-0 mx-md-1"
      title="Who pays"
      label="Who pays"
      description="Payment responsibility of EV charging"
      variationCount={variationCount}
      onApplyPressed={handleApplyButtonPressed}
      onClearPressed={handleClearButtonPressed}
    >
      {[PayerType.ORGANIZATION, PayerType.DRIVER].map((payer, ix) => (
        <FilterMenuCheckbox
          key={ix}
          onToggle={() => handlePayerTypesToggled(payer)}
          isChecked={isSelectedPayer(payer)}
          label={getPayerLabelByType(payer)}
        />
      ))}
    </FilterEditorCard>
  );
}

export default FilterDriverPayerStatusSelector;
