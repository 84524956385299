import { Vehicle, VehicleConnectionState } from "types/vehicle.types";

export const getVehicleSummariesAsObjectForCsv = (vehicle?: Vehicle) => {
  return {
    vehicleName: vehicle?.name,
    vehicleMake: vehicle?.carBrand,
    vehicleModel: vehicle?.carModel,
    vehicleVin: vehicle?.vin
  };
};


export function getVehicleSummaryName(vehicle?: Vehicle) {
  if (!vehicle) {
    return "";
  }

  return `${vehicle.name}-${vehicle.carBrand}-${vehicle.carModel}-${vehicle.vin}`;
}


export const getVehicleConnectionStatus = (vehicle?: Vehicle) => {
  const smartcarConnectionState = vehicle?.smartcarConnectionState;
  const teslaConnectionState = vehicle?.teslaConnectionState;
  const geotabConnectionState = vehicle?.geotabConnectionState;

  const isManualAddedCar =
    smartcarConnectionState === VehicleConnectionState.NOT_CONNECTED &&
    teslaConnectionState === VehicleConnectionState.NOT_CONNECTED &&
    geotabConnectionState === VehicleConnectionState.NOT_CONNECTED;

  const isTeslaConnected = teslaConnectionState === VehicleConnectionState.CONNECTED;
  const isSmartcarConnected = smartcarConnectionState === VehicleConnectionState.CONNECTED;
  const isGeotabConnected = geotabConnectionState === VehicleConnectionState.CONNECTED;

  const isGeotabDisconnected = geotabConnectionState === VehicleConnectionState.DISCONNECTED;

  const isSmartCarDisconnected = smartcarConnectionState === VehicleConnectionState.DISCONNECTED;

  let connectionStatus = vehicle ? VehicleConnectionState.CONNECTED : VehicleConnectionState.NOT_FOUND;

  if (isManualAddedCar) {
    connectionStatus = VehicleConnectionState.NOT_CONNECTED;
  } else if (isSmartCarDisconnected && !isTeslaConnected) {
    connectionStatus = VehicleConnectionState.DISCONNECTED;
  } else if (isGeotabDisconnected && !isTeslaConnected) {
    connectionStatus = VehicleConnectionState.DISCONNECTED;
  }

  return {
    connectionStatus,
    isSmartCarDisconnected,
    isManualAddedCar,
    isTeslaConnected,
    isSmartcarConnected,
    isGeotabConnected
  };
};


export const canVehicleBeSoftDeleted = (vehicle?: Vehicle) => {
  const { isSmartcarConnected } = getVehicleConnectionStatus(vehicle);

  if (isSmartcarConnected || vehicle?.deletedAt) {
    return false;
  }

  return true;
};


export const getVehiclesConnectionStatusColorAndLabel = (connectionStatus: VehicleConnectionState) => {
  if (connectionStatus === VehicleConnectionState.CONNECTED) {
    return {
      label: "Connected", color: "#2BA318"
    };
  }

  if (connectionStatus === VehicleConnectionState.DISCONNECTED) {
    return {
      label: "Disconnected", color: "#C4003E"
    };
  }

  return {
    label: "Not connected", color: "#C4003E"
  };
};

export const getVehiclesConnectionStatusColorAndLabelByVehicle = (vehicle: Vehicle) => {
  const { connectionStatus } = getVehicleConnectionStatus(vehicle);
  return getVehiclesConnectionStatusColorAndLabel(connectionStatus);
};


export const getVehicleForCsv = (vehicle: Vehicle) => {

};


export const getVehicleConnections = () => {
  return [VehicleConnectionState.CONNECTED, VehicleConnectionState.NOT_CONNECTED];
};


export const getVehicleConnectionOption = (connectionStatus: VehicleConnectionState) => {
  switch (connectionStatus) {
    case VehicleConnectionState.CONNECTED:
      return {
        label: "Connected",
        description: "Vehicles currently connected to the system",
        value: VehicleConnectionState.CONNECTED,
        textClass: "text-green-200"
      };
    case VehicleConnectionState.DISCONNECTED:
      return {
        label: "Disconnected",
        description: "Vehicles that were added via Smartcar but are now disconnected",
        value: VehicleConnectionState.CONNECTED,
        textClass: "text-cherry-100"
      };
    case VehicleConnectionState.NOT_CONNECTED:
      return {
        label: "Not connected",
        description: "Vehicles manually added by using their VIN",
        value: VehicleConnectionState.CONNECTED,
        textClass: "text-purple-200"
      };

    default:
      return {
        label: "Not connected",
        description: "Vehicles manually added by using their VIN",
        value: VehicleConnectionState.CONNECTED,
        textClass: "text-purple-200"
      };
  }
};