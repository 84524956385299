import React from "react";
import { SvgProps } from "types/svg.types";

const SearchIcon = ({ fill, width, height }: SvgProps) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 25;
  const heightValue = height ?? 28;

  return (
    <svg
      width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 28 28`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1466_25149)">
        <path
          d="M12.8333 22.1667C17.988 22.1667 22.1667 17.988 22.1667 12.8333C22.1667 7.67868 17.988 3.5 12.8333 3.5C7.67868 3.5 3.5 7.67868 3.5 12.8333C3.5 17.988 7.67868 22.1667 12.8333 22.1667Z"
          stroke="#6F7981"
          strokeWidth="2.84961"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.241 24.4993L22.166 19.4243"
          stroke="#6F7981"
          strokeWidth="2.84961"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1466_25149">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SearchIcon;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue} `}
 */
