import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { setOrganizations } from "redux/slices/organization-slice";
import {
  exportOrganizationChargingListAsync,
  getOrganizationAsync,
  getOrganizationChargingAsync,
  getOrganizationChargingListAsync,
  getOrganizationDriverAsync,
  getOrganizationDriverChargingListAsync,
  getOrganizationDriversAsync,
  getOrganizationInvoiceAsync,
  getOrganizationInvoicesAsync,
  getOrganizationsByStatusAsync,
  getOrganizationVehicleAsync,
  getOrganizationVehicleChargingListAsync,
  getOrganizationVehiclesAsync
} from "services/organization.services";
import { Charging } from "types/charging.types";
import { Driver } from "types/driver.types";
import { Invoice } from "types/invoice.types";
import { OrganizationDetails, OrganizationStatus } from "types/organization.types";
import { Vehicle } from "types/vehicle.types";
import { useListByUrlQueryAsync } from "./list-query.hooks";
import qs from "qs";
import { useAlertManager } from "../alert.hooks";
import { handleApiErrorResponse } from "../../utils";
import { getAllPaginatedListByLoopAsync } from "../../utils/organization.utils";


export const useOrganizations = () => {
  return { ...useAppSelector(state => state.organizationSlice) };
};


export const useOrganizationsAsync = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();

  const getOrganizationsByStatusAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);

        dispatch(setOrganizations((await getOrganizationsByStatusAsync(OrganizationStatus.APPROVED)).data));

      } catch (error) {
        console.error(error);

      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );


  useEffect(() => {
    getOrganizationsByStatusAsyncCallback();
  }, [getOrganizationsByStatusAsyncCallback]);


  return {
    ...useAppSelector(state => state.organizationSlice),
    isLoading,
    refetch: getOrganizationsByStatusAsyncCallback
  };
};


export const useOrganizationAsync = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [isLoading, setIsLoading] = useState(true);

  const [organization, setOrganization] = useState<OrganizationDetails | undefined>();


  const getOrganizationAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);
        setOrganization((await getOrganizationAsync(organizationId)).data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [organizationId]
  );


  useEffect(() => {
    getOrganizationAsyncCallback();
  }, [getOrganizationAsyncCallback]);


  return { organization, isLoading, refetch: getOrganizationAsyncCallback };
};


export const useOrganizationVehicleAsync = () => {
  const { organizationId, vehicleId } = useParams<{ organizationId: string, vehicleId: string }>();
  const [isLoading, setIsLoading] = useState(true);

  const [vehicle, setVehicle] = useState<Vehicle | undefined>();


  const getOrganizationVehicleAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);
        setVehicle((await getOrganizationVehicleAsync(organizationId, vehicleId)).data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [organizationId, vehicleId]
  );


  useEffect(() => {
    getOrganizationVehicleAsyncCallback();
  }, [getOrganizationVehicleAsyncCallback]);


  return { vehicle, isLoading, refetch: getOrganizationVehicleAsyncCallback };
};


export const useOrganizationDriverAsync = () => {
  const { organizationId, driverId } = useParams<{ organizationId: string, driverId: string }>();
  const [isLoading, setIsLoading] = useState(true);

  const [driver, setDriver] = useState<Driver | undefined>();


  const getOrganizationDriverAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);
        setDriver((await getOrganizationDriverAsync(organizationId, driverId)).data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [driverId, organizationId]
  );


  useEffect(() => {
    getOrganizationDriverAsyncCallback();
  }, [getOrganizationDriverAsyncCallback]);


  return { driver, isLoading, refetch: getOrganizationDriverAsyncCallback };
};


export const useOrganizationChargingAsync = () => {
  const { organizationId, chargingId } = useParams<{ organizationId: string, chargingId: string }>();
  const [isLoading, setIsLoading] = useState(true);

  const [charging, setCharging] = useState<Charging | undefined>();


  const getOrganizationChargingAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);
        setCharging((await getOrganizationChargingAsync(organizationId, chargingId)).data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [chargingId, organizationId]
  );


  useEffect(() => {
    getOrganizationChargingAsyncCallback();
  }, [getOrganizationChargingAsyncCallback]);


  return { charging, isLoading, refetch: getOrganizationChargingAsyncCallback };
};


export const useOrganizationChargingListAsync = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const getOrganizationChargingListAsyncCallback = useCallback((query?: string) => {
      return getOrganizationChargingListAsync(organizationId, query);
    },
    [organizationId]
  );

  return {
    ...useListByUrlQueryAsync<Charging>(getOrganizationChargingListAsyncCallback)
  };
};


export const useOrganizationInvoicesAsync = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const getOrganizationInvoicesAsyncCallback = useCallback((query?: string) => {
      return getOrganizationInvoicesAsync(organizationId, query);
    },
    [organizationId]
  );
  return {
    ...useListByUrlQueryAsync<Invoice>(getOrganizationInvoicesAsyncCallback)
  };
};


export const useOrganizationDriversAsync = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const getOrganizationDriversAsyncCallback = useCallback((query?: string) => {
      return getOrganizationDriversAsync(organizationId, query);
    },
    [organizationId]
  );
  return {
    ...useListByUrlQueryAsync<Driver>(getOrganizationDriversAsyncCallback)
  };
};


export const useOrganizationDriverChargingListAsync = () => {
  const { organizationId, driverId } = useParams<{ organizationId: string, driverId: string }>();
  const getOrganizationDriverChargingListAsyncCallback = useCallback((query?: string) => {
      return getOrganizationDriverChargingListAsync(organizationId, driverId, query);
    },
    [driverId, organizationId]
  );
  return {
    ...useListByUrlQueryAsync<Charging>(getOrganizationDriverChargingListAsyncCallback)
  };
};


export const useOrganizationVehiclesAsync = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const getOrganizationVehiclesAsyncCallback = useCallback((query?: string) => {
      return getOrganizationVehiclesAsync(organizationId, query);
    },
    [organizationId]
  );
  return {
    ...useListByUrlQueryAsync<Vehicle>(getOrganizationVehiclesAsyncCallback)
  };
};


export const useOrganizationVehicleChargingListAsync = () => {
  const { organizationId, vehicleId } = useParams<{ organizationId: string, vehicleId: string }>();
  const getOrganizationVehicleChargingListAsyncCallback = useCallback((query?: string) => {
      return getOrganizationVehicleChargingListAsync(organizationId, vehicleId, query);
    },
    [organizationId, vehicleId]
  );
  return {
    ...useListByUrlQueryAsync<Charging>(getOrganizationVehicleChargingListAsyncCallback)
  };
};


export const useOrganizationInvoiceAsync = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [invoice, setInvoice] = useState<Invoice | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  const handleGetInvoiceAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);
        setInvoice((await getOrganizationInvoiceAsync(invoiceId)).data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [invoiceId]
  );


  useEffect(() => {
    handleGetInvoiceAsyncCallback();
  }, [handleGetInvoiceAsyncCallback]);


  return { isLoading, invoice };
};


export const useTeslaExporterAsync = () => {
  const { handleOpenErrorAlert, handleOpenSuccessAlert } = useAlertManager();

  const [isLoading, setIsLoading] = useState(false);

  const { organizationId } = useParams<{
    organizationId?: string
  }>();

  const getTeslaChargingListAsyncCallback = useCallback(
    async (startTime: string, endTime: string) => {
      if (!organizationId) {
        return;
      }
      try {
        const query = qs.stringify({
          startTime,
          endTime,
          "externalSource": "tesla"
        }, {
          skipNulls: true,
          addQueryPrefix: true
        });
        setIsLoading(true);
        return (await exportOrganizationChargingListAsync(organizationId, query)).data;

      } catch (err) {
        console.error(err);
        handleOpenErrorAlert(handleApiErrorResponse(err));
      } finally {
        setIsLoading(false);
      }
    },
    [handleOpenErrorAlert, organizationId]
  );


  return { isLoading, getTeslaChargingListAsyncCallback };
};


export const useAllOrganizationDriversAsync = () => {
  const [allDrivers, setAllDrivers] = useState<Driver[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { organizationId } = useParams<{
    organizationId?: string
  }>();

  const getAllDriversAsyncCallback = useCallback(
    async () => {
      try {

        if (!organizationId) {
          return;
        }
        setIsLoading(true);
        const response = await getAllPaginatedListByLoopAsync<Driver>((q) => getOrganizationDriversAsync(organizationId, q));
        setAllDrivers(response);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [organizationId]
  );


  useEffect(() => {
    getAllDriversAsyncCallback();
  }, [getAllDriversAsyncCallback]);


  return { allDrivers, isLoading };
};


export const useAllOrganizationVehiclesAsync = () => {
  const [allVehicles, setAllVehicles] = useState<Vehicle[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { organizationId } = useParams<{
    organizationId?: string
  }>();
  const getAllVehiclesAsyncCallback = useCallback(async () => {
    try {
      if (!organizationId) {
        return;
      }
      setIsLoading(true);
      const response = await getAllPaginatedListByLoopAsync<Vehicle>((q) => getOrganizationVehiclesAsync(organizationId, q));
      setAllVehicles(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [organizationId]);

  useEffect(() => {
    getAllVehiclesAsyncCallback();
  }, [getAllVehiclesAsyncCallback]);

  return { allVehicles, isLoading };
};
