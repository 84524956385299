import CopyIcon from "assets/icons/CopyIcon";
import Row from "components/custom/Row";
import { TextProps } from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";

type Props = {
  TextComponent: React.FC<TextProps>;
  textComponentClassName?: string;
  text?: string;
};

function ClipboardWithCopyIcon({ text, TextComponent, textComponentClassName }: Props) {
  const { handleOpenSuccessAlert } = useAlertManager();
  return (
    <div className={"priority-link"}>
      <CopyToClipboard text={text} onCopy={() => handleOpenSuccessAlert("Copied to clipboard!")}>
        <Row className="w-100 clickable align-items-center ">
          <TextComponent
            className={`d-flex flex-1 mr-1 word-wrap-normal w-max-100 text-truncate ${textComponentClassName ?? ""}`}
          >
            {text}
          </TextComponent>

          <CopyIcon />
        </Row>
      </CopyToClipboard>
    </div>
  );
}

export default ClipboardWithCopyIcon;
