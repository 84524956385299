import { BlackSubtitleText200 } from "components/text/Text";
import React, { useMemo } from "react";
import { Controller, FieldError, Validate } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { FormGroup } from "reactstrap";
import { FormRegister, InputType } from "types/form.types";

import ControllerValidationError from "./ControllerValidationError";

type InputWithValidationControllerErrorProps = {
  register: FormRegister;
  errors?: { [x: string]: FieldError };
  defaultValue?: string | number | readonly string[] | undefined;
  placeholder?: string | undefined;
  type?: React.HTMLInputTypeAttribute | undefined;
  name: string;
  errorMessage?: string | undefined;
  registerRef?: React.LegacyRef<HTMLInputElement> | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onValidate?: Validate | Record<string, Validate> | undefined;
  className?: string;
  inputLabel?: string;
  inputType?: InputType;
  [key: string]: any;
};

export default function InputWithValidationControllerError({
  register,
  errors,
  placeholder,
  type,
  errorMessage,
  inputLabel,
  registerRef,
  inputType = InputType.Text,
  ...props
}: InputWithValidationControllerErrorProps) {
  const defaultType = "text";

  let input = useMemo(() => {
    switch (inputType) {
      case InputType.NumberByDollar:
        return (
          <Controller
            control={props?.control}
            as={NumericFormat}
            decimalScale={2} // decimalScale prop to limit the decimal points up to 2
            allowNegative={false} // allowNegative prop to disallow negative values
            thousandSeparator
            className="form-control"
            rules={{ required: errorMessage ?? `${inputLabel} is required.` }}
            placeholder={placeholder}
            prefix="$"
            {...props}
          />
        );
      case InputType.Percent:
        return (
          <Controller
            control={props?.control}
            as={NumericFormat}
            allowNegative={false} // allowNegative prop to disallow negative values
            className="form-control"
            placeholder={placeholder}
            rules={{
              required: `${inputLabel} is required.`,
              pattern: {
                value: /^(100|([0-9]{1,2}))%$/,
                message: "Value must be between 0 and 100.",
              },
            }}
            min={0}
            max={100}
            suffix="%"
            {...props}
          />
        );

      case InputType.NetPaymentTerm:
        return (
          <Controller
            control={props?.control}
            as={NumericFormat}
            allowNegative={false} // allowNegative prop to disallow negative values
            className="form-control"
            placeholder={placeholder}
            rules={{
              required: `${inputLabel} is required.`,
              valueAsNumber: true,
              max: { value: 60, message: "Value must be between 0 and 60." },
              min: { value: 0, message: "Value must be between 0 and 60." },
            }}
            min={0}
            max={60}
            {...props}
          />
        );

      default:
        return (
          <input
            className="form-control "
            type={type ?? defaultType}
            placeholder={placeholder}
            ref={
              registerRef ??
              register({
                required: errorMessage ?? `${props.name} is required.`,
              })
            }
            {...props}
          />
        );
    }
  }, [errorMessage, inputType, placeholder, props, register, registerRef, type]);

  return (
    <FormGroup>
      {inputLabel && <BlackSubtitleText200 className="mb-2">{inputLabel}</BlackSubtitleText200>}
      {input}

      <ControllerValidationError error={errors && errors[props.name]?.message} />
    </FormGroup>
  );
}
