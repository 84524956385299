import CancelButton from "components/button/CancelButton";
import SaveButton from "components/button/SaveButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import InputWithValidationControllerError from "components/form/InputWithValidationControllerError";
import React, { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Form, FormGroup, Modal, ModalBody } from "reactstrap";
import { updateOrganizationAsync } from "services/organization.services";
import { InputType } from "types/form.types";
import { OrganizationDetails } from "types/organization.types";
import { getFormattedCentByUsdCreditString, getFormattedNumberForUsdByCent } from "utils/format.utils";
import { useAlertManager } from "../../../hooks/alert.hooks";
import { handleApiErrorResponse } from "utils/api.js";


type Props = {
  readonly isModalOpen: boolean;
  readonly setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly defaultValue?: string;
  readonly organizationDetails?: OrganizationDetails;
  readonly refetchOrganizationDetails: () => Promise<void>;
};

type FormValues = {
  readonly limit?: string;
};

function OrganizationLimitFormModal({
                                      isModalOpen,
                                      setIsModalOpen,
                                      defaultValue,
                                      organizationDetails,
                                      refetchOrganizationDetails
                                    }: Props) {
  const { handleOpenErrorAlert, handleOpenSuccessAlert } = useAlertManager();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, control, errors } = useForm<FormValues>();

  const handleValidatedSubmit: SubmitHandler<FormValues> = useCallback(
    async (validData) => {
      if (!organizationDetails) {
        return;
      }
      const { id, active } = organizationDetails;
      try {
        setIsSubmitting(true);
        await updateOrganizationAsync(id, {
          credit: getFormattedCentByUsdCreditString(validData?.limit),
          active,
          settings: organizationDetails.settings.data
        });
        await refetchOrganizationDetails();

        handleOpenSuccessAlert('Limit is updated successfully.');
        setIsModalOpen(false);
      } catch (error) {
        console.log(error,'error')
        handleOpenErrorAlert(handleApiErrorResponse(error));
      } finally {
        setIsSubmitting(false);
      }
    },
    [handleOpenErrorAlert, handleOpenSuccessAlert, organizationDetails, refetchOrganizationDetails, setIsModalOpen]
  );

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      className="wide-xs modal-dialog-centered  overflow-hidden  "
    >
      <ModalBody className="w-md-100 h-md-75  h-lg-50  h-max-100 p-3 max-w-90  overflow-hidden position-relative">
        <Form
          className="w-100 h-100 "
          onReset={() => setIsModalOpen(false)}
          onSubmit={handleSubmit(handleValidatedSubmit)}
        >
          <Col className="mt-2">
            <FormGroup>
              <InputWithValidationControllerError
                register={register}
                inputLabel="Edit Limit"
                control={control}
                className="form-control"
                errors={errors}
                name="limit"
                placeholder="Type charging spending limit (optional)"
                defaultValue={getFormattedNumberForUsdByCent(organizationDetails?.limits.chargingLimit)}
                inputType={InputType.NumberByDollar}
              />
            </FormGroup>
          </Col>
          <Row className="w-100 justify-end align-center mt-3">
            <CancelButton />
            <SaveButton isDisabled={isSubmitting} isLoading={isSubmitting} />
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default OrganizationLimitFormModal;
