import React from 'react'
import { useState } from 'react';
import {
    Modal,
    ModalBody,
    UncontrolledAlert,
    Form,
    FormGroup,
    Col,
    Row,
    Button,

} from 'reactstrap';
import { useTranslation } from 'react-i18next'
import { Icon } from '../../../components/Component';
import Cookies from 'js-cookie';

export const ChangePasswordModal = ({ modalStatus, setModalStatus }) => {
    const { t } = useTranslation("common");
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const [alertData, setAlertData] = useState({
        status: false,
        type: "info",
        icon: "alert-circle",
        message: t("error.unknown"),
    });
    const [formData, setFormData] = useState({
        current_password: "",
        password: "",
        password_confirmation: ""
    });
    const [passState, setPassState] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        const { password_confirmation, ...others } = formData;
        if (formData.password !== password_confirmation) {
            setAlertData({
                status: true,
                type: "danger",
                icon: "cross-circle",
                message: t("auth.reset_password.match_error"),
            });
        } else {
            try {
                /*                 await userService.changePassword(others); */
                setAlertData({
                    status: true,
                    type: "success",
                    icon: "check",
                    message: t('security_settings.modal.success_message'),
                });
                Cookies.remove('access_token');
                Cookies.remove('refresh_token');
                Cookies.remove('user');
                Cookies.remove('application');
                setTimeout(async () => {
                    window.history.pushState({}, '', "/auth-login");
                    window.location.reload();
                }, 3000);
            } catch (err) {
                setAlertData({
                    status: true,
                    type: "danger",
                    icon: "cross-circle",
                    message: err.response.data.message
                });
            }
        }
    }

    const onFormCancel = () => {
        setModalStatus(false);
        setFormData({
            current_password: "",
            password: "",
            password_confirmation: ""
        });
        setAlertData({
            status: false,
            type: "info",
            icon: "alert-circle",
            message: t("error.unknown"),
        });
    }

    return (
        <Modal isOpen={modalStatus} toggle={() => setModalStatus(false)} className="modal-dialog-centered" size="lg">
            <ModalBody>
                <a
                    href="#cancel"
                    onClick={(ev) => {
                        ev.preventDefault();
                        onFormCancel();
                    }}
                    className="close">
                    <Icon name="cross-sm"></Icon>
                </a>
                <div className="p-2">
                    <h5 className="title">Change Password</h5>
                    {alertData.status ? (
                        <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
                            <Icon name={alertData.icon ?? "cross-circle"} />
                            {alertData.message ?? t("error.unknown")}
                        </UncontrolledAlert>
                    ) : null}
                    <div className="mt-4">
                        <Form className="gy-4" onSubmit={onSubmit}>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Current Password</label>
                                        <div className="form-control-wrap">
                                            <a
                                                href="#password"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setPassState(!passState);
                                                }}
                                                className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                                            >
                                                <Icon name="eye" className="passcode-icon icon-show"></Icon>

                                                <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                            </a>
                                            <input
                                                type={passState ? "text" : "password"}
                                                tabIndex={1}
                                                required
                                                placeholder="Current Password"
                                                onChange={(e) => setFormData(current => ({ ...current, current_password: e.target.value }))}
                                                className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`} />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">New Password</label>
                                        <input
                                            type={passState ? "text" : "password"}
                                            tabIndex={2}
                                            required
                                            placeholder="Enter your new password"
                                            onChange={(e) => setFormData(current => ({ ...current, password: e.target.value }))}
                                            className="form-control" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Confirm password</label>
                                        <input
                                            type={passState ? "text" : "password"}
                                            tabIndex={3}
                                            required
                                            placeholder="Confirm your new password"
                                            onChange={(e) => setFormData(current => ({ ...current, password_confirmation: e.target.value }))}
                                            className="form-control" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button color="primary" size="md" type="submit">
                                                {t("operations.save")}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    onFormCancel();
                                                }}
                                                className="link link-light">
                                                {t("operations.cancel")}
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}
