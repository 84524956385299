import { Driver } from "types/driver.types";
import DetailsCardBlock from "../../card/DetailsCard";
import Row from "../../custom/Row";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import { BlackH5Text0 } from "../../text/Text";
import Card from "../../card/Card";

type Props = {
  isLoading: boolean;
  driver?: Driver;
};
export default function DriverDetailsPageAddressCard({ isLoading, driver }: Props) {
  const streetInfo = driver?.address?.street + " " + driver?.address?.street2;

  return (
    <>
      <Card>
        <Row className="mb-2">
          {isLoading ? <LoadingSkeleton count={1} /> : <BlackH5Text0>Address</BlackH5Text0>}
        </Row>
        <>
          <DetailsCardBlock isLoading={isLoading} leftText={"Address"} rightText={streetInfo} />
          <DetailsCardBlock isLoading={isLoading} leftText={"State"} rightText={driver?.address?.state} />
          <DetailsCardBlock isLoading={isLoading} leftText={"City"} rightText={driver?.address?.city} />
          <DetailsCardBlock isLoading={isLoading} leftText={"Post Code"} rightText={driver?.address?.postalCode}
                            isBorderBottomVisible={false} />
        </>
      </Card>
    </>
  );
}
