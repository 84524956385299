import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationList, OrganizationSliceState } from 'types/organization.types';


const initialState: OrganizationSliceState = {
    organizations: []
};

export const organizationSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setOrganizations: (state, action: PayloadAction<OrganizationList>) => {
            state.organizations = action.payload;
        },
    },
});

export const { setOrganizations } = organizationSlice.actions;
export default organizationSlice.reducer;