import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";

export type BlackButtonWithIconProps = {
  readonly onClick?: () => void;
  readonly text?: string;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

function BlackButton(props: BlackButtonWithIconProps) {
  return <ButtonWithIcon theme="black" {...props} />;
}

export default BlackButton;
