import Col from "components/custom/Col";
import Row from "components/custom/Row";
import Checkbox from "components/input/checkbox/Checkbox";
import { BlackBody2Text100, BlackOverlineText200 } from "components/text/Text";
import { ChargingNetwork } from "types/charging.types";

type Props = {
  readonly label: string;
  readonly description?: string;
  readonly isChecked?: boolean;
  readonly onToggle: (network: ChargingNetwork) => void;
  readonly textClassName?: string;
};

function FilterMenuCheckbox({ label, isChecked, onToggle, textClassName, description }: Props) {
  return (
    <Row className="align-items-center clickable py-1" onClick={onToggle}>
      <Checkbox isChecked={isChecked} />
      <Col className="ml-1">
        <BlackBody2Text100 className={` ${textClassName ?? ""}`}>{label}</BlackBody2Text100>
        {description && <BlackOverlineText200>{description}</BlackOverlineText200>}
      </Col>
    </Row>
  );
}

export default FilterMenuCheckbox;
