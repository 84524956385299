import React, { useEffect } from "react";

import PageHeader from "components/header/PageHeader";
import OrganizationsTable from "components/organization/OrganizationsTable";
import { useOrganizationsAsync } from "hooks/query/organization-query.hooks";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router";

const OrganizationsPage = () => {
  const { pathname } = useLocation();
  const { isLoading, organizations } = useOrganizationsAsync();

  useEffect(() => {
    if (!isLoading) {
      window.scrollTo(0, 0);
    }
  }, [pathname, isLoading]);

  return (
    <React.Fragment>
      <Head title={"Organizations"} />
      <Content>
        <PageHeader
          title={"Fleet Organizations"}
          description={`View and manage ${organizations.length} approved fleet organizations.`}
        />
        <ErrorBoundary FallbackComponent={() => <>An error ocurred..</>}>
          <OrganizationsTable isLoading={isLoading} organizations={organizations} />
        </ErrorBoundary>
      </Content>
    </React.Fragment>
  );
};

export default OrganizationsPage;
