import React from "react";
import Content from "../../layout/content/Content";
import Row from "../../components/custom/Row";
import Col from "../../components/custom/Col";

import { useTranslation } from "react-i18next";
import Head from "../../layout/head/Head";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import Card from "components/card/Card";
import { useOrganizationChargingAsync } from "../../hooks/query/organization-query.hooks";
import PageHeader from "../../components/header/PageHeader";
import ChargingDetailsPageSessionCard from "../../components/charging/ChargingDetailsPageSessionCard";
import ChargingDetailsPageUserCard from "../../components/charging/ChargingDetailsPageUserCard";
import ReactJson from "react-json-view";
import { BlackBodyText100 } from "../../components/text/Text";
import ChargingSessionsDetailsPageCardBlock from "../../components/charging/ChargingDetailsPageCardBlock";
import { ErrorBoundary } from "react-error-boundary";
import ChargerLocationMap from "../../components/charging/ChargerLocationMap";
import ClipboardWithCopyIcon from "../../components/clipboard/ClipboardWithCopyIcon";

export default function ChargingDetailsPage() {
  const { t } = useTranslation("common");

  const { isLoading, charging } = useOrganizationChargingAsync();

  const charger = charging?.charger;

  if (!charging && !isLoading) {
    return (
      <Content className="center">
        <h3 className="font-weight-normal">Charging session details with this id could not be found.</h3>
      </Content>
    );
  }


  return (
    <React.Fragment>
      <Head title={"Charging details"} />
      <Content>
        <PageHeader title={"Charging session details"} description={"Monitor and analyze EV charging data"}
                    rightElement={<ClipboardWithCopyIcon TextComponent={BlackBodyText100} text={charging?.id} />}
        />
        <Row className="mt-3">
          <Col md="6" className="pr-md-2 mb-2">
            {isLoading ? (
              <Card>
                <LoadingSkeleton count={12} height={35} />{" "}
              </Card>
            ) : (
              <> {charging && <ChargingDetailsPageSessionCard charging={charging} />}

                {
                  charging?.metadata && <Card className={"mt-3"}>
                    <BlackBodyText100 className={"mb-2"}>Charging Metadata</BlackBodyText100>

                    <div className={"h-max-400px h-min-200px overflow-auto"}>
                      <ReactJson name={"metadata"} collapseStringsAfterLength={100} src={charging.metadata}
                                 theme="monokai" />
                    </div>

                  </Card>
                }
              </>
            )}
          </Col>
          <Col md="6" className="pl-md-2">
            {isLoading ? (
              <Card>
                <LoadingSkeleton count={8} height={35} />{" "}
              </Card>
            ) : (
              <> {charging && <ChargingDetailsPageUserCard charging={charging} />}

                {charger && <Card className={"mt-3"}>
                  <ChargingSessionsDetailsPageCardBlock leftLabel="Charger Location" leftText={charger.address} />

                  <ErrorBoundary fallback={<>Google maps error</>}><ChargerLocationMap
                    charger={charger} /></ErrorBoundary>
                </Card>}

                {charging?.activityLogs &&
                  <Card className={"mt-3"}>
                    <BlackBodyText100 className={"mb-2"}>Activity Logs</BlackBodyText100>

                    <div className={"h-max-400px h-min-200px overflow-auto"}>
                      <ReactJson name={"Activity logs"} collapseStringsAfterLength={100} src={charging.activityLogs}
                                 theme="bright" />
                    </div>

                  </Card>
                }

              </>
            )}
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
}
