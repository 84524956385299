import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { profileService } from "../services";
import Logo from '../images/logo.svg'

const PrivateRoute = ({ exact, component: Component, ...rest }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => setAuthenticated(true))
      .catch(() => setAuthenticated(false))
      .finally(() => setLoading(false));
  }, []);


  if (loading) {
    return (
      <div className="nk-block" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
        <img className="loading-animation" src={Logo} alt="Bluedot for Fleets Logo" width={400} />
      </div>
    );
  }

  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) =>
        (authenticated) ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/auth-login" />
        )
      }
    />
  );
};

export default PrivateRoute;
