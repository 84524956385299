import InfoIcon from "assets/icons/InfoIcon";
import RightChevron from "assets/icons/RightChevron";
import { Semaphore } from "async-mutex";
import ConnectionStatusBadge from "components/badge/ConnectionStatusBadge";
import ChargingSessionsSourceInfoTooltipContent from "components/charging/ChargingSessionsSourceInfoTooltipContent";
import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import { BlackBody2Text200 } from "components/text/Text";
import { CardTooltip } from "components/tooltip/Tooltips";
import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router";
import { PaginatedApiResponse } from "types/api.types";
import { Driver } from "types/driver.types";
import { Query } from "types/filter.types";
import { flattenMapToSortedArray } from "utils/array.utils";
import {
  convertSecondsToMinutesAndHours,
  getChargerInfo,
  getChargerTypeIcon,
  getChargingPayStatDisplayContent,
  getChargingPayStatDisplayText,
  getWhoPaysDisplayTextByPayerType
} from "utils/charging.utils";
import { getLocalFormattedDateAsDateShort } from "utils/date.utils";
import { getDriverActivationStatusColorAndLabel } from "utils/driver.utils";
import {
  getFormattedCostByNetwork,
  getFormattedNumberForEnergy,
  getFormattedNumberForUsdByCent
} from "utils/format.utils";
import { getInvoiceDescription, getInvoiceTypeLabel } from "utils/invoice.utils";
import { getOrganizationStatusContent } from "utils/organization.utils";
import Row from "../components/custom/Row";
import { useUrlQuery } from "./router.hooks";
import PaidStatusBadge from "../components/badge/PaidStatusBadge";
import ChargingStateBadge from "../components/badge/ChargingStateBadge";

export function useCommonTableColumns() {
  const history = useHistory();
  const commonColumns = useMemo(() => {
    return {
      invoiceType: {
        grow: 1,
        name: "Invoice type",
        cell: (row) => (
          <>{getInvoiceTypeLabel(row.invoiceType)}</>
        )
      },
      invoiceDescription: {
        grow: 1,
        name: "Description",
        cell: (row) => (
          <>{getInvoiceDescription(row)}</>
        )
      },
      invoiceId: {
        style: {
          pointerEvents: "all",
          zIndex: 99
        },
        grow: 2.5,
        name: "Invoice id",
        selector: (row) => <ClipboardWithCopyIcon TextComponent={BlackBody2Text200} text={row?.id ?? "-"} />
      },
      invoicePaymentStatus: {
        grow: 0.3,
        name: "Status",
        selector: (row) => <PaidStatusBadge status={row.paymentState} />
      },
      invoiceAmount: {
        grow: 0.6,
        name: "Amount",
        selector: (row) => <p>{getFormattedNumberForUsdByCent(row.netAmount)}</p> ?? "-"
      },
      vehicle: {
        grow: 1,
        name: "Vehicle name",
        selector: (row) => <p title={row?.name} className={"text-truncate pointer-events-none"}>{row?.name}</p> ?? "-"
      },
      organizationVehicle: {
        width: "150px",
        name: "Vehicle name",
        selector: (row) => <p title={row?.vehicle?.name}
                              className={"text-truncate pointer-events-none"}>{row?.vehicle?.name}</p> ?? "-"
      },
      vehicleBrand: {
        grow: 1,
        name: "Vehicle brand",
        selector: (row) => <p title={row?.carBrand}>{row?.carBrand}</p> ?? "-"
      },
      vehicleModel: {
        grow: 1,
        name: "Vehicle model",
        selector: (row) => <p title={row?.carModel}>{row?.carModel}</p> ?? "-"
      },
      driver: {
        width: "150px",
        name: "Driver Name",
        selector: (row) => {
          const driverName = `${row?.firstName ?? "-"}  ${row?.lastName ?? ""} `;

          return driverName;
        }
      },
      organizationDriver: {
        width: "150px",
        name: "Driver Name",
        selector: (row) => {
          const driverName = `${row?.user?.firstName ?? "-"}  ${row?.user?.lastName ?? ""} `;

          return <p title={driverName} className={"text-truncate pointer-events-none"}>{driverName ?? "-"}</p>;
        }
      },
      organizationStatus: {
        grow: 0.5,
        name: "Status",
        selector: (row) => (
          <span
            style={{
              color: getOrganizationStatusContent(row?.status).color,
              pointerEvents: "none"
            }}
          >
          {getOrganizationStatusContent(row?.status).label}
        </span>
        )
      },
      driverStatus: {
        grow: 0.5,
        name: "Status",
        selector: (row: Driver) => (
          <span
            style={{
              color: getDriverActivationStatusColorAndLabel(row?.active).color,
              pointerEvents: "none"
            }}
          >
          {getDriverActivationStatusColorAndLabel(row?.active).label}
        </span>
        )
      },
      chargingState: {
        grow: 1,
        name: "State",
        selector: (row) => (
          <ChargingStateBadge state={row.state} />
        )
      },
      vehicleSmartcarStatus: {
        grow: 1,
        name: "Smartcar status",
        selector: (row) => {
          return <ConnectionStatusBadge connectionStatus={row?.smartcarConnectionState} />;
        }
      },
      vehicleTeslaStatus: {
        grow: 1,
        name: "Tesla status",
        selector: (row) => {
          return <ConnectionStatusBadge connectionStatus={row?.teslaConnectionState} />;
        }
      },
      vehicleGeotabStatus: {
        grow: 1,
        name: "Geotab status",
        selector: (row) => {
          return <ConnectionStatusBadge connectionStatus={row?.geotabConnectionState} />;
        }
      },
      vehicleDeletedAt: {
        grow: 0.8,
        name: "Deleted at",
        selector: (row) => <>{getLocalFormattedDateAsDateShort(row?.deletedAt) ?? "-"} </>
      },
      source: {
        width: "110px",
        name: (
          <Row>
            <span className="mr-1">Source</span>
            <CardTooltip title={<ChargingSessionsSourceInfoTooltipContent />} placement="top">
              <div>
                <InfoIcon color="#CBD5E1" />
              </div>
            </CardTooltip>
          </Row>
        ),
        selector: (row) => <div className="w-65px center">{getChargerInfo(row.network)?.logo}</div>
      },
      chargerName: {
        width: "200px",
        name: "Charger Name",
        selector: (row) => row.chargerName
      },
      email: {
        grow: 1,
        name: "Email",
        selector: (row) => row.email
      },
      fleetManager: {
        grow: 1,
        name: "Fleet manager",
        selector: (row) => row.name
      },
      vehiclesCount: {
        grow: 1,
        name: "# of Vehicles",
        selector: (row) => row.vehiclesCount ?? "-"
      },
      customerName: {
        width: "200px",
        name: "Customer Name",
        selector: (row) => row.businessName
      },
      type: {
        width: "100px",
        name: "Type",
        cell: (row) =>
          row?.type ? (
            <p>
              {getChargerTypeIcon(row.type)} <span className="ml-1"> {row.type} </span>
            </p>
          ) : (
            "-"
          )
      },
      energy: {
        width: "120px",
        name: "kWh",
        selector: (row) => <>{getFormattedNumberForEnergy(row?.energyDelivered)} kWh </>
      },
      cost: {
        width: "120px",
        name: "Cost",
        selector: (row) => <>{getFormattedCostByNetwork(row?.cost, row.network)} </>
      },
      payStat: {
        grow: 1,
        minWidth: "120px",
        name: "PayStat",
        selector: (row) => (
          <p className={getChargingPayStatDisplayContent(row.paymentState).textClass}>
            {getChargingPayStatDisplayText(row?.paymentState)}
          </p>
        )
      },

      whoPays: {
        width: "200px",
        name: "Who Pays",
        selector: (row) => <>{getWhoPaysDisplayTextByPayerType(row?.payer)} </>
      },
      duration: {
        name: "Duration",
        selector: (row) => <>{convertSecondsToMinutesAndHours(row?.duration)} </>
      },

      dueDate: {
        width: "150px",
        name: "Due date",
        cell: (row) => <> {getLocalFormattedDateAsDateShort(row.dueDate)}</>
      },
      date: {
        width: "150px",
        name: "Date",
        selector: (row) => <>{getLocalFormattedDateAsDateShort(row?.createdAt)} </>
      },
      action: {
        center: true,
        right: true,
        width: "70px",
        name: "",
        cell: (row) => (
          <Row className="w-100 justify-end pr-2">
            <RightChevron widthValue={8} heightValue={10} />
          </Row>
        )
      }
    }
  }, []);

  return commonColumns;
};

const semaphore = new Semaphore(2);

export const useExportTableWithSemaphore = (
  fetchListAsync: (page: number, size: number) => Promise<PaginatedApiResponse<any>>,
  csvRowFormatter: (c: any) => any,
  totalPages: number,
  rowSizePerPage: number
) => {
  const excelRef = useRef<CSVLink>();
  const [allListForCsv, setAllListForCsv] = useState<any[]>([]);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [fetchedPageCount, setFetchedPageCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (totalPages) {
      const percent = (fetchedPageCount / totalPages) * 100;
      setProgress(percent);
    }
  }, [fetchedPageCount, totalPages]);

  const fetchListAsyncWithSemaphore = async (page: number, fetchedMapList: Map<number, any[]>) => {
    const [_, release] = await semaphore.acquire();
    try {
      const response = await fetchListAsync(page, rowSizePerPage);
      setFetchedPageCount((c) => c + 1);

      const newEntries = response?.data ?? [];
      fetchedMapList.set(page, newEntries);
    } finally {
      release();
    }
  };

  const handleExportTableWithSemaphore = useCallback(async () => {
    if (isLoading) {
      return;
    }

    const downloadCsvFile = () => excelRef?.current?.link?.click();

    setFetchedPageCount(0);
    setErrorMessage(undefined);

    // Download cached data
    if (allListForCsv.length > 0) {
      downloadCsvFile();
      return;
    }

    setIsLoading(true);

    const fetchedMapList = new Map<number, any[]>();
    const promises: Promise<void>[] = [];

    try {
      for (let page = 0; page <= totalPages; page++) {
        promises.push(fetchListAsyncWithSemaphore(page, fetchedMapList));
      }

      await Promise.all(promises);
    } catch (error: any) {
      console.error(error);
      // Break the process
      return setErrorMessage(error?.message ?? "Sorry, we couldn't complete your action right now. Please try again.");
    } finally {
      setIsLoading(false);
    }

    const listsForCsv = flattenMapToSortedArray<any>(fetchedMapList).map((c) => {
      return csvRowFormatter(c);
    });

    setAllListForCsv(listsForCsv);
    downloadCsvFile();
  }, [isLoading, allListForCsv.length, totalPages, rowSizePerPage, excelRef]);

  return {
    progressText: errorMessage ?? progress.toFixed(0) + "%",
    allListForCsv,
    isLoading,
    handleExportTableWithSemaphore,
    excelRef
  };
};

export const useExportFilterableTableWithSemaphore = (
  fetchListAsync: (query: string) => Promise<PaginatedApiResponse<any>>,
  csvRowFormatter: (c: any) => any,
  totalPages: number,
  rowSizePerPage: number
) => {
  const { query: parsedQuery } = useUrlQuery<Query<any>>();

  const excelRef = useRef<CSVLink>();
  const [allListForCsv, setAllListForCsv] = useState<any[]>([]);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [fetchedPageCount, setFetchedPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (totalPages) {
      const percent = (fetchedPageCount / totalPages) * 100;
      setProgress(percent);
    }
  }, [fetchedPageCount, totalPages]);

  useEffect(() => {
    // Reset the cache since we have a new query
    setAllListForCsv([]);
  }, [parsedQuery]);

  const fetchListAsyncWithSemaphore = useCallback(
    async (page: number, fetchedMapList: Map<number, any[]>) => {
      const [_, release] = await semaphore.acquire();
      try {
        const queryString = qs.stringify(
          {
            ...parsedQuery,
            pagination: {
              page,
              size: parsedQuery.pagination?.size
            }
          },
          {
            skipNulls: true,
            addQueryPrefix: true
          }
        );
        const response = await fetchListAsync(queryString);
        setFetchedPageCount((c) => c + 1);

        const newEntries = response?.data ?? [];
        fetchedMapList.set(page, newEntries);
      } finally {
        release();
      }
    },
    [fetchListAsync, parsedQuery]
  );

  const handleExportTableWithSemaphore = useCallback(async () => {
    if (isLoading) {
      return;
    }

    const downloadCsvFile = () => excelRef?.current?.link?.click();

    setFetchedPageCount(0);
    setErrorMessage(undefined);

    // Download cached data
    if (allListForCsv.length > 0) {
      downloadCsvFile();
      return;
    }

    setIsLoading(true);

    const fetchedMapList = new Map<number, any[]>();
    const promises: Promise<void>[] = [];

    try {
      for (let page = 0; page <= totalPages; page++) {
        promises.push(fetchListAsyncWithSemaphore(page, fetchedMapList));
      }

      await Promise.all(promises);
    } catch (error: any) {
      console.error(error);
      // Break the process
      return setErrorMessage(error?.message ?? "Sorry, we couldn't complete your action right now. Please try again.");
    } finally {
      setIsLoading(false);
    }

    const listsForCsv = flattenMapToSortedArray<any>(fetchedMapList).map((c) => {
      return csvRowFormatter(c);
    });

    setAllListForCsv(listsForCsv);
    downloadCsvFile();
  }, [isLoading, allListForCsv.length, totalPages, fetchListAsyncWithSemaphore, csvRowFormatter]);

  return {
    progressText: errorMessage ?? progress.toFixed(0) + "%",
    allListForCsv,
    isLoading,
    handleExportTableWithSemaphore,
    excelRef
  };
};

export const useTableFilter = (list: any[] = [], filterKeys: string[]) => {
  const [filteredList, setFilteredList] = useState(list);

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    if (!query) {
      setFilteredList(list);
      return;
    }

    const filtered = list.filter((item) => {
      let conditionCounter = 0;

      filterKeys.forEach((k) => {
        if (item[k]?.toLowerCase()?.includes(query)) {
          ++conditionCounter;
        }
      });

      return conditionCounter > 0;
    });

    setFilteredList(filtered);
  };

  return { handleFilter, filteredList };
};


/**
 * useTableRowHtmlElementReplacementWithRef - A custom hook that simulates `<a>` tag behavior over table row `<div>` elements.
 *
 * This hook selects all `div` elements with `role="row"` and the class `rdt_TableRow`, and adds an invisible `<a>` tag overlay.
 * The `href` attribute of the new `<a>` tags is dynamically generated using the provided `getRoutePath` function.
 * The hook also adds a click event listener to each row to navigate using React Router's `history.push`.
 *
 * @param {function} [getRoutePath] - Function to generate the route path. Takes an `id` as an argument and returns a string path.
 * @param {React.RefObject<HTMLDivElement>} tableRef - A ref object to the table container element.
 *
 * @example
 * const getRoutePath = (id) => `details/${id}`;
 * useTableRowHtmlElementReplacementWithRef(getRoutePath, tableRef);
 */

export const useTableRowHtmlElementReplacementWithRef = (
  getRoutePath?: (id: string) => string | undefined,
  tableRef?: React.RefObject<HTMLDivElement>
) => {
  const history = useHistory();

  useEffect(() => {
    if (!tableRef?.current) {
      console.log("No table ref available");
      return;
    }

    if (!getRoutePath) {
      console.warn("No getRoutePath function provided. Table rows will not be updated.");
      return;
    }

    const rows = tableRef.current.querySelectorAll("div[role='row'].rdt_TableRow");

    rows.forEach((row) => {
      const rowId = row.getAttribute("id");
      const className = row.getAttribute("class");

      if (!rowId || !className) {
        console.warn("Row is missing a className or rowId. Skipping row update.", { row });
        return;
      }

      const extractedId = rowId.replace(/^row-/, "");
      const routePath = getRoutePath(extractedId);

      if (!routePath) {
        console.warn("Route path is missing. Skipping row update.", { row });
        return;
      }

      row.setAttribute("role", "link");
      row.setAttribute("tabindex", "0");


      const anchor = document.createElement("a");
      anchor.setAttribute("href", routePath);
      anchor.setAttribute("target", "_blank");
      anchor.style.position = "absolute";
      anchor.style.top = "0";
      anchor.style.left = "0";
      anchor.style.width = "100%";
      anchor.style.height = "100%";
      anchor.style.zIndex = "1";
      anchor.style.opacity = "0";
      anchor.style.cursor = "pointer";
      anchor.style.pointerEvents = "auto";

      const divRow = row as HTMLDivElement;
      divRow.style.position = "relative";

      // Attach click event to the row
      // Attach the click event to the anchor
      anchor.addEventListener("click", (e) => {
        e.preventDefault();

        history.push(routePath);
      });

      row.appendChild(anchor);
    });
  }, [getRoutePath, history, tableRef]);
};
