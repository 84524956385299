import React from "react";
import { RegisterOptions } from "react-hook-form";

export type FormRegister = (options: RegisterOptions) => React.LegacyRef<HTMLInputElement> | undefined;


export enum InputType {
    NumberByDollar,
    Percent,
    NetPaymentTerm,
    Text
}