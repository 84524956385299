import { Period } from "./filter.types";
import { PayerType } from "./payment.types";
import { Vehicle } from "./vehicle.types";

export type TotalEnergyAndCostByPeriodAndNetwork = {
  period: string
  network: string
  totalEnergy: number
  totalCost: number
}


export enum ChargingState {
  INITIAL = "INITIAL",
  START_WAITING = "START_WAITING",
  ACTIVE = "ACTIVE",
  STOP_WAITING = "STOP_WAITING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export type Charger = {
  id: string
  chargerId: string
  connectorId: string
  name: string
  chargerName: string
  connectorName: string
  address: string
  latitude: number
  longitude: number
  provider: string
  connectorType: string
  currentType: string
  kw: number
}


export type BaseCharging = {
  id: string
  user: UserForCharging
  vehicle: Vehicle
  cost: number
  energyDelivered: string
  network: string
}


export enum ChargingPaymentState {
  UNPAID = "UNPAID",
  PAID = "PAID",
  INAPPLICABLE = "INAPPLICABLE"
}


export type Charging = BaseCharging & {
  activityLogs?: JSON[]
  metadata: JSON;
  charger?: Charger
  organizationId: string
  vehicleId: string
  userId: string
  connectorId: string
  chargerName: string
  state: ChargingState
  type: string
  payer: PayerType
  duration: number
  maxSessionDuration: number
  startDate: number
  paymentState: ChargingPaymentState
  invoiceId: string,
  chargingInvoice?: {
    invoiceId?: string
  }
  source: string
  chargingType: string
  createdAt: string
  updatedAt: string
  version: number
}


export type ChargingForCsv = Charging | {
  cost: string
  user: string
  vehicleName: string
  vehicleMake: string
  vehicleVin: string
  vehicleModel: string
}

// Not same with User model 
export type UserForCharging = {
  id: string
  type: string
  email: string
  phone_number: string
  firstName: string
  lastName: string
  active: boolean
  payer: PayerType
  jobTitle: string
  createdAt: string | Date
  updatedAt: string | Date
  activatedAt: any
  expiredAt: Date
}


export enum ConnectionStatus {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  NOT_CONNECTED = "NOT_CONNECTED"
}

export enum ChargerType {
  AC = "AC",
  DC = "DC",
}

export enum ChargingNetwork {
  CHARGE_POINT = "charge_point",
  ELECTRIFY_AMERICA = "electrify_america",
  EVGO = "evgo",
  HOME = "home",
  OTHER = "other",
  TESLA = "tesla",
}

export type ChargingFilters = {
  network?: {
    $in?: (keyof typeof ChargingNetwork)[]
    $nin?: (keyof typeof ChargingNetwork)[]
  },
  vehicle?: {
    $in: string[]
  },
  user?: {
    $in: string[]
  },
  payer?: {
    $in: PayerType[]
  },
  paymentState?: {
    $in: ChargingPaymentState[]
  },
  createdAt?: {
    $within?: Period,
    $gte?: string | Date | null,
    $lt?: string | Date | null
  }
  q?: {
    $eq?: string
  }
}
