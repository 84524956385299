import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../components/Component";
import { useTranslation } from 'react-i18next'
import {
  Col,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { profileService } from "../../../services";
import { Auth } from "aws-amplify";
import { useLocation } from "react-router";


const UserProfileRegularPage = ({ sm, updateSm, data }) => {
  const { t } = useTranslation("common");
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const redirect = query.get("redirect");
  const [modal, setModal] = useState({ update: false });
  const [alertData, setAlertData] = useState({
    status: false,
    type: "info",
    icon: "alert-circle",
    message: t("error.unknown"),
  });

  const onFormCancel = () => {
    setModal({ update: false });
  };

  const onUpdateSubmit = async (data) => {
    try {
      await profileService.updateProfileInfo(data);
      setAlertData({
        status: true,
        type: "success",
        icon: "done",
        message: "Profile info successfully updated",
      });
      onFormCancel();
      if (redirect) {
        await Auth.signOut();
        window.location.href = "/";
      }
    } catch (err) {
      onFormCancel();
      setAlertData({
        status: true,
        type: "danger",
        icon: "alert-circle",
        message: "An error occurred, your profile information could not be updated.",
      });
      if (redirect) {
        await Auth.signOut();
        window.location.href = "/";
      }
      console.error(err);
    }
  };

  const { register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title={t('profile.profile')}></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">{t('profile.title')}</BlockTitle>
            <BlockDes>
              <p>{t('profile.desc')}</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {alertData.status && (
        <Block>
          <BlockHeadContent>
            <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
              <Icon name={alertData.icon ?? "cross-circle"} />
              {alertData.message ?? t("error.unknown")}
            </UncontrolledAlert>
          </BlockHeadContent>
        </Block>
      )}

      <Block>
        {redirect &&
          <div className="alert alert-fill alert-icon alert-primary mb-2" role="alert">
            After you update your information, you will be redirected to the login page. Once your information is reviewed and approved, you can log in again. </div>
        }
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">{t('profile.basics')}</h6>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('profile.full_name')}</span>
              <span className="data-value">{data?.name ?? "-"}</span>
            </div>
            <div className="data-col data-col-end">
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('general.email')}</span>
              <span className="data-value">{data?.email ?? "-"}</span>
            </div>
            <div className="data-col data-col-end">
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('profile.phone_number')}</span>
              <span className="data-value">{data?.phoneNumber ?? "-"}</span>
            </div>
            <div className="data-col data-col-end">
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('profile.business_name')}</span>
              <span className="data-value">{data?.businessName ?? "-"}</span>
            </div>
            <div className="data-col data-col-end">
              <span onClick={() => setModal({ update: true })} style={{ cursor: 'pointer' }} className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('profile.employer_id')}</span>
              <span className="data-value">{data?.employerIdentificationNumber ?? "-"}</span>
            </div>
            <div className="data-col data-col-end">
              <span onClick={() => setModal({ update: true })} style={{ cursor: 'pointer' }} className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
        </div>
        {/*     <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">{t('profile.preferences')}</h6>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('general.language')}</span>
              <span className="data-value">{localStorage.getItem('lang') === "tr" ? "Türkçe" : "English (United States)"}</span>
            </div>
            <div className="data-col data-col-end">
              <LanguageSelector />
            </div>
          </div>
        </div> */}
        <Modal isOpen={modal.update} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">{t('profile.update_profile')}</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onUpdateSubmit)}>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t('profile.business_name')}</label>
                      <input
                        className="form-control"
                        type="text"
                        name="businessName"
                        defaultValue={data?.businessName}
                        placeholder={t('profile.business_name')}
                        ref={register({ required: t('general.required') })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t('profile.employer_id')}</label>
                      <input
                        className="form-control"
                        type="number"
                        name="employerIdentificationNumber"
                        defaultValue={data?.employerIdentificationNumber}
                        placeholder={t('profile.employer_id')}
                        ref={register({ required: t('general.required') })}
                      />
                    </FormGroup>
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          {t('operations.save')}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light">
                          {t('operations.cancel')}
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Block>

    </React.Fragment>
  );
};
export default UserProfileRegularPage;
