import React from "react";
import Row from "../custom/Row";
import LoadingSkeleton from "../loading/LoadingSkeleton";

import { BlackSubtitleText0, BlackCaptionText200 } from "../text/Text";

export type DetailsCardBlockProps = {
  leftLabel?: string;
  leftText?: string;
  leftTextOverwrite?: JSX.Element;
  leftAfterText?: string;
  leftBeforeText?: string;
  rightLabel?: string;
  rightText?: string;
  rightTextOverwrite?: JSX.Element;
  rightBeforeText?: string;
  isBorderTopVisible?: boolean;
  isBorderBottomVisible?: boolean;
  leftAfterLabel?: JSX.Element | string;
  rightAfterLabel?: JSX.Element | string;
  paddingClass?: string;
  isLoading?: boolean;
};

export default function DetailsCardBlock({
  leftLabel,
  leftAfterLabel,
  leftText,
  leftTextOverwrite,
  leftAfterText,
  leftBeforeText,
  rightLabel,
  rightAfterLabel,
  rightTextOverwrite,
  rightText,
  rightBeforeText,
  isBorderTopVisible,
  isBorderBottomVisible = true,
  paddingClass,
  isLoading,
}: DetailsCardBlockProps) {
  let containerClass = paddingClass ?? "pt-2 pb-1";
  if (isBorderBottomVisible) {
    containerClass += " border-bottom ";
  }
  if (isBorderTopVisible) {
    containerClass += " border-top ";
  }

  if (isLoading) {
    return <LoadingSkeleton count={2} height={30} />;
  }

  const isSharedBlock = !!rightLabel && !!leftLabel && leftText && leftText.length > 20;

  return (
    <Row className={`justify-between flex-nowrap text-left  ${containerClass} `}>
      <div className={`d-flex justify-center flex-column flex-nowrap  ${isSharedBlock && "w-max-50-calc"}`}>
        <Row>
          {leftLabel && <BlackCaptionText200>{leftLabel}</BlackCaptionText200>}
          {leftAfterLabel && leftAfterLabel}
        </Row>

        <Row className="justify-content-start align-center h-25px flex-nowrap">
          {leftBeforeText && <div className="mr-1">{leftBeforeText}</div>}
          {leftTextOverwrite ??
            (leftText && <BlackSubtitleText0 className={"mr-2 word-wrap-normal"}>{leftText}</BlackSubtitleText0>)}
          {leftAfterText && <div className="ml-1">{leftAfterText}</div>}
        </Row>
      </div>
      <div className={`d-flex justify-center flex-column ${isSharedBlock && "w-max-50-calc"}`}>
        <Row className="justify-end flex-nowrap w-100">
          {rightLabel && <BlackCaptionText200>{rightLabel}</BlackCaptionText200>}
          {rightAfterLabel && rightAfterLabel}
        </Row>
        <Row className="justify-end align-center h-25px flex-nowrap w-100">
          {rightBeforeText && rightBeforeText}
          {rightTextOverwrite ??
            (rightText && <BlackSubtitleText0 className={"word-wrap-normal"}>{rightText}</BlackSubtitleText0>)}
        </Row>
      </div>
    </Row>
  );
}
