import TextButton from "components/button/TextButton";
import { CSVLink } from "react-csv";

import { useExportFilterableTableWithSemaphore } from "hooks/table.hooks";
import { PaginatedApiResponse } from "types/api.types";

import ExportIcon from "assets/icons/ExportIcon";
import { useUrlPagination } from "hooks/pagination.hooks";
import { DateTime } from "luxon";

type Props = {
  readonly totalPages: number;
  readonly fetchListAsync: (query: string) => Promise<PaginatedApiResponse<any>>;
  readonly csvRowFormatter: (c: any) => any;
  readonly fileName: string;
};

function FilterableTableCsvLinkButton({
  fetchListAsync,
  csvRowFormatter,
  totalPages,

  fileName,
}: Props) {
  const { tablePageRowSize } = useUrlPagination();
  const { allListForCsv, isLoading, excelRef, progressText, handleExportTableWithSemaphore } =
    useExportFilterableTableWithSemaphore(fetchListAsync, csvRowFormatter, totalPages, tablePageRowSize);

  return (
    <>
      <div className="d-flex flex-1 justify-content-end ">
        <TextButton
          onClick={() => handleExportTableWithSemaphore()}
          className={`hover-background-white p-1  ${isLoading && "cursor-not-allowed"}`}
          icon={<ExportIcon />}
          text={isLoading ? progressText : "Export"}
        />
      </div>
      <CSVLink
        ref={excelRef}
        data={allListForCsv}
        asyncOnClick={true}
        filename={`${DateTime.now().toLocaleString(DateTime.DATE_FULL)}-${fileName}.csv`}
      />
    </>
  );
}

export default FilterableTableCsvLinkButton;
